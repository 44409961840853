import { Box, Switch, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import {
  DATA_GRID_COLUMN_WIDTH,
  DATA_GRID_COLUMN_WIDTH_3,
  DATA_GRID_COLUMN_WIDTH_5,
} from 'src/constants/Layout';
import {
  DIALOG_TITLE_REMOVE_ACCESS,
  UPDATE_USER_ACCESS,
  dialogMessageGrantAccess,
  dialogMessageRemoveAccess,
} from 'src/constants/dialogMetaInfo';
import {
  fetchOrganizationSpaceData,
  updateSpaceHanaAccessById,
} from 'src/services/spaces';

import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { UpdatedSpaceResponse } from 'src/types/spacesTypes';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import { NOT_AUTHORIZED_MESSAGE, UNHANDLED_ERROR } from 'src/constants/errors';
import { observer } from 'mobx-react-lite';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import { User } from 'src/types/user';
import TablePaginationActions from 'src/constants/TablePaginationActions';
import {
  PAGE_SIZE_OPTIONS,
  WORDS_PER_ROW_CELL,
  truncateString,
} from 'src/constants/utils';
import { ROLES } from 'src/constants/Role';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';

const Spaces = observer(() => {
  const { notify } = useAlertStore();
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const [spaces, setSpaces] = useState<UpdatedSpaceResponse[]>([]);
  const [loadingForDataGrid, setLoadingForDataGrid] = useState<boolean>(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [updateSpaceId, setUpdateSpaceId] = useState<string>('');

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const {
    updateDialogMetaData,
    updateDialogueState,
    dialogType,
    dialogueState,
  } = useDialogStore();

  const handleSpaceHanaAccess = async () => {
    try {
      updateDialogueState('LOADING');

      const currentIsActive = spaces.filter(
        (user) => user.id === updateSpaceId,
      )[0]['isActive'];

      const newIsActive = !currentIsActive;

      const { success: updateActiveStatusSuccess } =
        await updateSpaceHanaAccessById(updateSpaceId, newIsActive);

      if (updateActiveStatusSuccess) {
        setSpaces((prevSpace) =>
          prevSpace.map((space) =>
            space.id === updateSpaceId
              ? { ...space, isActive: newIsActive }
              : space,
          ),
        );

        notify(UPDATE_USER_ACCESS);
      } else {
        notify(UNHANDLED_ERROR);
      }
    } catch (error: any) {
      notify(UNHANDLED_ERROR);
    }

    updateDialogueState('CLOSED');
    setUpdateSpaceId('');
  };

  const openDialogForUpdateHanaAccess = (userDetails: User, userId: string) => {
    setUpdateSpaceId(userId);

    let dialogtitle: string;
    let dialogDescription: string;

    if (userDetails.isActive) {
      dialogtitle = DIALOG_TITLE_REMOVE_ACCESS;
      dialogDescription = dialogMessageRemoveAccess(userDetails.displayName);
    } else {
      dialogtitle = 'Grant Access';
      dialogDescription = dialogMessageGrantAccess(userDetails.displayName);
    }

    updateDialogMetaData(
      dialogtitle,
      dialogDescription,
      DialogFor.UPDATE_HANA_ACCESS,
      [],
    );

    updateDialogueState('OPEN');
  };

  const spaceColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: DATA_GRID_COLUMN_WIDTH_5,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Typography
            component="p"
            variant="body1"
            sx={{ maxWidth: DATA_GRID_COLUMN_WIDTH, margin: '16px 0' }}
          >
            {truncateString(row?.displayName, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: DATA_GRID_COLUMN_WIDTH,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Typography component="p" variant="body1" sx={{ maxWidth: 450 }}>
          {truncateString(row.description, WORDS_PER_ROW_CELL)}
        </Typography>
      ),
    },

    {
      field: 'createdAt',
      headerName: 'Created On',
      width: DATA_GRID_COLUMN_WIDTH_3,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Typography component="p" variant="body1">
            {new Date(row.spaceCreatedAt).toLocaleDateString()}
          </Typography>
        );
      },
    },
    {
      field: 'isActive',
      headerName: 'Active',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Switch
          checked={params.row.isActive}
          onChange={() =>
            openDialogForUpdateHanaAccess(params.row, params.row.id)
          }
          id="space-hana-access"
        />
      ),
    },
  ];

  const fetchAndSetSpaces = useCallback(async () => {
    if (
      !userDetails ||
      (userDetails.role !== ROLES.SUPER_ADMIN &&
        userDetails.role !== ROLES.ADMIN &&
        userDetails.role !== ROLES.INDIVIDUAL_USER_ADMIN)
    )
      return;
    const { organizationId } = userDetails;

    setLoadingForDataGrid(true);

    try {
      const {
        success: getSpacesSuccess,
        content: spaces,
        message: getSpacesErrorMessage,
      } = await fetchOrganizationSpaceData(
        organizationId.toString(),
        paginationModel.page,
        paginationModel.pageSize,
      );

      if (!getSpacesSuccess && getSpacesErrorMessage) {
        notify(UNHANDLED_ERROR);

        setLoadingForDataGrid(false);

        setSpaces([]);

        return;
      }

      if (spaces && spaces.data) {
        const updatedContent = spaces.data.map((space) => ({
          id: space._id,
          ...space,
        }));

        setRowCountState(spaces.totalCount);
        setSpaces(updatedContent);
      }
    } catch (error) {
      notify(UNHANDLED_ERROR);
      setSpaces([]);
    } finally {
      setLoadingForDataGrid(false);
    }
  }, [paginationModel.page, paginationModel.pageSize, notify, userDetails]);

  useEffect(() => {
    fetchAndSetSpaces();
  }, [fetchAndSetSpaces]);

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.UPDATE_HANA_ACCESS:
        handleSpaceHanaAccess();
        break;

      default:
    }
  }, [dialogueState]);

  if (!userDetails) {
    return <ErrorBoundary message={UNHANDLED_ERROR} />;
  }

  if (
    userDetails.role !== ROLES.SUPER_ADMIN &&
    userDetails.role !== ROLES.ADMIN &&
    userDetails.role !== ROLES.INDIVIDUAL_USER_ADMIN
  ) {
    return (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="p" variant="h6">
          {NOT_AUTHORIZED_MESSAGE}
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="main">
      <ModuleHeader title="Spaces" />
      <Box>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight
          paginationMode="server"
          rows={spaces}
          columns={spaceColumns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          disableColumnMenu
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          loading={loadingForDataGrid}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paginationModel.page - 1,
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
          rowCount={rowCountState}
          onPaginationModelChange={(newPaginationModel) => {
            setPaginationModel((oldPaginationModel) => ({
              ...oldPaginationModel,
              page: newPaginationModel.page + 1,
              pageSize: newPaginationModel.pageSize,
            }));
          }}
        />
      </Box>
    </Box>
  );
});

export default Spaces;
