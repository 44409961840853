import { makeAutoObservable } from 'mobx';
import {
  OrganizationApiResponseWithPopulatedPlan,
  OrganizationFormFields,
} from 'src/types/organization';
import createStoreContext from '../createStoreContext';
import {
  getOrganizationDetailsByOrgId,
  updateOrganizationDetails,
} from 'src/services/organization';

export const [OrganizationProvider, useOrganizationStore] = createStoreContext(
  class OrganizationStore {
    _organizationDetails: OrganizationApiResponseWithPopulatedPlan | null =
      null;

    constructor() {
      makeAutoObservable(this);
    }

    get organizationDetails() {
      return this._organizationDetails;
    }

    set organizationDetails(value) {
      this._organizationDetails = value;
    }

    fetchOrganization = async (orgId: string) => {
      const { success, content } = await getOrganizationDetailsByOrgId(orgId);

      if (success && content) {
        this._organizationDetails = content;

        return content;
      } else {
        return null;
      }
    };

    updateOrganization = async (
      userId: string,
      data: OrganizationFormFields,
    ) => {
      const { success, content } = await updateOrganizationDetails(
        userId,
        data,
      );

      if (success && content) {
        this._organizationDetails = content;

        return content;
      } else {
        return null;
      }
    };
  },
);

export type GlobalStore = ReturnType<typeof useOrganizationStore>;
