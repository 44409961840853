import { API_ROUTES_FOR_AXIOS } from 'src/constants/ApiRoutes';
import { axiosInstance } from 'src/constants/axiosInstance';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { ApiResponse, PaginatedResponse } from 'src/types/commonResponseTypes';
import { failureResponse } from 'src/utils/responseUtils';
import {
  CreateReminder,
  ReminderDataType,
  ReminderResponse,
} from 'src/types/reminderType';

export const getRemindersByOrgId = async (
  orgId: string,
  page: number,
  limit: number,
  mongoUserId?: string,
): Promise<ApiResponse<PaginatedResponse<ReminderResponse[]>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getRemindersByOrgId'](
      orgId,
      page,
      limit,
      mongoUserId,
    );

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const getReminderById = async (
  reminderId: string,
): Promise<ApiResponse<ReminderResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getReminderById'](reminderId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const createReminder = async (body: ReminderDataType) => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['createReminder']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const deleteReminderById = async (
  reminderId: string,
): Promise<ApiResponse<ReminderResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['deleteReminderById'](reminderId);
    const { data } = await axiosInstance.delete(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const updateReminderById = async (
  reminderId: string,
  body: CreateReminder,
): Promise<ApiResponse<ReminderResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateReminderById'](reminderId);

    const { data } = await axiosInstance.patch(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
