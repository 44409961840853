import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { failureResponse } from '../utils/responseUtils';
import { UNHANDLED_ERROR } from '../constants/errors';
import { ReportGroupApiResponse } from '../types/reportGroup';
import { ApiResponse, PaginatedResponse } from '../types/commonResponseTypes';
import {
  NameAvailabilityCheckProp,
  CreateGroupProp,
} from '../types/reportGroup';
import { axiosInstance } from '../constants/axiosInstance';

export const checkNameAvailability = async (
  body: NameAvailabilityCheckProp,
  orgId: string,
): Promise<ApiResponse<boolean>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['groupNameAvailabilityCheck'](orgId);
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const createGroup = async (
  body: CreateGroupProp,
  orgId: string,
): Promise<ApiResponse<ReportGroupApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['createReportGroup'](orgId);

    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const getAllReportGroups = async (
  orgId: string,
  page: number,
  limit: number,
): Promise<ApiResponse<PaginatedResponse<ReportGroupApiResponse[]>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getReportGroupsByOrgId'](
      orgId,
      page,
      limit,
    );

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const deleteReportGroupById = async (
  groupId: string,
): Promise<ApiResponse<ReportGroupApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['deleteReportGroupById'](groupId);

    const { data } = await axiosInstance.delete(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const updateGroupByGroupId = async (
  groupId: string,
  body: CreateGroupProp,
  orgId: string,
  mongoUserId: string,
): Promise<ApiResponse<ReportGroupApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateReportGroupsByOrgIdAndUserId'](
      orgId,
      mongoUserId,
      groupId,
    );

    const { data } = await axiosInstance.patch(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const getReportGroupDetailsById = async (
  groupId: string,
): Promise<ApiResponse<ReportGroupApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getReportGroupDetailsById'](groupId);

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
