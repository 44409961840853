export async function openLinkWithRetry(link: string) {
  let tries = 0;
  let delayDuration = 1000;
  const maxTries = 5;

  while (tries < maxTries) {
    const newWindow = window.open(link, '_blank');

    if (newWindow) {
      break;
    } else {
      tries++;

      if (tries < maxTries) {
        await new Promise((resolve) => setTimeout(resolve, delayDuration)); // Wait for the delay duration
        delayDuration *= 2; // Exponential backoff
      }
    }
  }
}
