import React, { useEffect, useState } from 'react';
import hanaImage from '../../assets/hana-hero.webp';
import styles from './registration.module.scss';
import RegistrationForm from './RegistrationForm';
import { HANABI_WEBSITE } from 'src/constants/Urls';
import { DEVELOPER_NAME } from 'src/constants/Developer';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import {
  NOT_AUTHORIZED_MESSAGE,
  UNHANDLED_ERROR,
  USER_DETAILS_NOT_FOUND_ERROR,
} from 'src/constants/errors';
import { ROLES } from 'src/constants/Role';
import { Box, Link, Typography } from '@mui/material';
import {
  checkOnboardingComplete,
  checkDnsVerification,
} from 'src/services/organization';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import { useAlertStore } from '../../stores/AlertStore/AlertStore';
import { PATHS } from 'src/constants/navigateRoutes';
import { useNavigate } from 'react-router-dom';
import CustomLoading from 'src/components/custom-loading';
import styleVariables from '../../scss/_variables.scss';
import { ONBOARDING_ALREADY_COMPLETED } from 'src/constants/Onboarding';

const OnBoardRegistration: React.FC = observer(() => {
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { organizationDetails } = useOrganizationStore();
  const { notify } = useAlertStore();
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isDnsVerified, setIsDnsVerified] = useState(false);

  const checkOnboardingCompletionStatusAndAuthorize = async () => {
    if (!userDetails || !organizationDetails) {
      return;
    }

    setIsPageLoading(true);

    if (
      userDetails.role !== ROLES.ADMIN &&
      userDetails.role !== ROLES.SUPER_ADMIN &&
      userDetails.role !== ROLES.INDIVIDUAL_USER_ADMIN
    ) {
      const {
        success: checkDnsVerificationSuccess,
        content: dnsVerificationStatus,
      } = await checkDnsVerification(organizationDetails._id.toString());

      if (!checkDnsVerificationSuccess || !dnsVerificationStatus) {
        notify(UNHANDLED_ERROR);
        setIsPageLoading(false);

        return;
      }

      setIsDnsVerified(dnsVerificationStatus.isDnsVerified);
      setIsPageLoading(false);

      return;
    }

    const {
      success: checkOnboardingCompleteSuccess,
      content: checkOnboardingCompleteApiResponse,
    } = await checkOnboardingComplete(organizationDetails._id.toString());

    if (
      !checkOnboardingCompleteSuccess ||
      !checkOnboardingCompleteApiResponse
    ) {
      notify(UNHANDLED_ERROR);
      setIsPageLoading(false);

      return;
    }

    if (checkOnboardingCompleteApiResponse.isOnboardingCompleted) {
      notify(ONBOARDING_ALREADY_COMPLETED);
      navigate(PATHS.overview);
    }

    setIsPageLoading(false);
  };

  useEffect(() => {
    checkOnboardingCompletionStatusAndAuthorize();
  }, []);

  if (isPageLoading) {
    return (
      <Box
        sx={{
          height: '100vh',
          overflow: 'hidden',
          background: styleVariables.midnightIndigo,
        }}
      >
        <CustomLoading />
      </Box>
    );
  }

  if (!userDetails) {
    return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;
  }

  if (
    userDetails.role !== ROLES.SUPER_ADMIN &&
    userDetails.role !== ROLES.ADMIN &&
    userDetails.role !== ROLES.INDIVIDUAL_USER_ADMIN
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="p" variant="h6">
          {isDnsVerified ? (
            NOT_AUTHORIZED_MESSAGE
          ) : (
            <>
              Please complete the DNS verification or request manual
              verification to gain admin access.
              <Link href={`${PATHS.overview}?dns-verify=true`}>
                Click here to verify
              </Link>
            </>
          )}
        </Typography>
      </Box>
    );
  }

  return (
    <div className={styles['registration-container']}>
      <div className={styles['registration-inputs']}>
        <div className={styles['registration-heading-div']}>
          <h2 className={styles['registration-h2']}>Sign up</h2>
          <h3 className={styles['registration-h3']}>
            {userDetails.role === ROLES.INDIVIDUAL_USER_ADMIN
              ? 'Individual User Details'
              : 'Organization Details'}
          </h3>
        </div>

        <div
          className={`hanabi-scrollbar ${styles['registration-form-container']}`}
        >
          <RegistrationForm />
        </div>
        <p className={styles['about-hana-tech']}>
          Today Solve Private Limited. A product of{' '}
          <a href={HANABI_WEBSITE} referrerPolicy="no-referrer" target="_blank">
            {DEVELOPER_NAME}
          </a>
        </p>
      </div>
      <div>
        <img
          src={hanaImage}
          alt="registration-hana-img"
          className={styles['registration-container-hana-img']}
        />
      </div>
    </div>
  );
});

export default OnBoardRegistration;
