import { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Link,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  findDnsVerificationDetailsByOrgIdAndMongoUserId,
  createDnsVerificationCode,
  verifyDnsByOrgIdAndUserId,
} from 'src/services/dns-verification';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import { observer } from 'mobx-react-lite';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  UNHANDLED_ERROR,
  USER_DETAILS_NOT_FOUND_ERROR,
} from 'src/constants/errors';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import ErrorBoundary from '../error-boundary/ErrorBoundry';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import CustomLoading from '../custom-loading';
import { ROLES } from 'src/constants/Role';
import {
  COPY_NOTIFICATION_MESSAGE,
  DNS_VERIFICATION_ALREADY_VERIFIED,
  DNS_VERIFICATION_FAILURE,
  DNS_VERIFICATION_SUCCESS,
  DNS_VERIFICATION_TITLE,
  DNS_VERIFICATION_INSTRUCTIONS,
  MANUAL_DNS_VERIFY_EMAIL_BODY,
  MANUAL_DNS_VERIFY_EMAIL_SUBJECT,
} from 'src/constants/notification';
import styles from 'src/scss/_variables.scss';
import { SUPPORT_EMAIL } from 'src/constants/Developer';
import { CustomListItems } from '../custom-list-items/Custom_ListItems_MUI_Component';

interface DnsVerificationProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const DnsVerification: React.FC<DnsVerificationProps> = observer(
  ({ isOpen, setIsOpen }) => {
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const { organizationDetails } = useOrganizationStore();
    const { notify } = useAlertStore();
    const { getUserDetails, fetchUserDetails, logout } = useAuthStore();
    const userDetails = getUserDetails();
    const navigate = useNavigate();
    const location = useLocation();

    const fetchDnsVerificationDetails = async () => {
      if (!organizationDetails || !userDetails) {
        notify(UNHANDLED_ERROR);

        return;
      }

      setLoading(true);
      const {
        success: findDnsVerificationDetailsSuccess,
        content: dnsVerificationDetails,
      } = await findDnsVerificationDetailsByOrgIdAndMongoUserId(
        organizationDetails._id.toString(),
        userDetails._id.toString(),
      );

      if (findDnsVerificationDetailsSuccess && dnsVerificationDetails) {
        const { txtValue } = dnsVerificationDetails;

        setToken(txtValue);
      }

      setLoading(false);
    };

    useEffect(() => {
      fetchDnsVerificationDetails();
    }, []);

    const handleGenerateToken = async () => {
      if (!organizationDetails || !userDetails) {
        notify(UNHANDLED_ERROR);

        return;
      }

      setLoading(true);
      const {
        success: createDnsVerificationCodeSuccess,
        content: dnsVerificationCode,
      } = await createDnsVerificationCode({
        organizationId: organizationDetails._id.toString(),
        userId: userDetails._id.toString(),
      });

      if (createDnsVerificationCodeSuccess && dnsVerificationCode) {
        const { txtValue } = dnsVerificationCode;

        setToken(txtValue);
        setLoading(false);

        return;
      }

      notify(UNHANDLED_ERROR);
      setLoading(false);
    };

    const handleVerifyDns = async () => {
      if (!organizationDetails || !userDetails) {
        notify(UNHANDLED_ERROR);

        return;
      }

      setLoading(true);

      const { success: verifyDnsSuccess, content: verifyDnsApiResponse } =
        await verifyDnsByOrgIdAndUserId({
          organizationId: organizationDetails._id.toString(),
          userId: userDetails._id.toString(),
        });

      if (verifyDnsSuccess && verifyDnsApiResponse) {
        const { isDnsVerified } = verifyDnsApiResponse;

        if (isDnsVerified) {
          const userDetails = await fetchUserDetails();

          if (!userDetails) {
            logout();
            navigate(PATHS.unAuthorized);

            return;
          }

          const { role } = userDetails;

          if (role === ROLES.ADMIN) {
            notify(DNS_VERIFICATION_SUCCESS);
            navigate(PATHS.onBoardRegistration);
          } else {
            notify(DNS_VERIFICATION_ALREADY_VERIFIED);
            navigate(PATHS.overview);
          }
        } else {
          notify(DNS_VERIFICATION_FAILURE);
        }

        setIsVerified(isDnsVerified);
        setLoading(false);

        return;
      }

      notify(UNHANDLED_ERROR);
      setLoading(false);
    };

    const handleCopyToken = () => {
      navigator.clipboard.writeText(token);
      notify(COPY_NOTIFICATION_MESSAGE);
    };

    if (!userDetails) {
      return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;
    }

    const encodedSubject = encodeURIComponent(MANUAL_DNS_VERIFY_EMAIL_SUBJECT);
    const encodedBody = encodeURIComponent(MANUAL_DNS_VERIFY_EMAIL_BODY);

    return (
      <Dialog open={isOpen}>
        {loading && (
          <DialogContent
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '50%',
              left: '50%',
              zIndex: 1,
              opacity: 0.5,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CustomLoading />
          </DialogContent>
        )}

        <CloseIcon
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={() => {
            setIsOpen(false);
            const url = new URL(
              window.location.origin + location.pathname + location.search,
            );

            url.searchParams.delete('dns-verify');
            navigate(url.pathname + url.search);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            borderRadius: '8px',
            gap: 2,
            maxWidth: '400px',
            margin: 'auto',
            backgroundColor: styles.midnightIndigo,
          }}
        >
          <Typography variant="h6">{DNS_VERIFICATION_TITLE}</Typography>
          <CustomListItems
            listItemsData={DNS_VERIFICATION_INSTRUCTIONS}
            listItemStyle={{
              listStyleType: 'disc',
              padding: 0,
              marginLeft: '12px',
            }}
          />

          {token && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TextField
                label="Token"
                value={token}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <IconButton onClick={handleCopyToken}>
                      <ContentCopyIcon />
                    </IconButton>
                  ),
                }}
                fullWidth
                variant="outlined"
              />
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateToken}
            disabled={Boolean(token)}
            fullWidth
          >
            Generate Token
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleVerifyDns}
            disabled={!token || isVerified}
            fullWidth
          >
            Verify
          </Button>
          <Typography variant="body2" sx={{ marginTop: '8px' }}>
            Having issues?&nbsp;
            <Link
              href={`https://mail.google.com/mail/u/${userDetails.email}/?view=cm&fs=1&tf=1&to=${SUPPORT_EMAIL}&su=${encodedSubject}&body=${encodedBody}`}
              target="_blank"
            >
              Request Manual Verify
            </Link>
          </Typography>
        </Box>
      </Dialog>
    );
  },
);

export default DnsVerification;
