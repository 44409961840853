import * as Yup from 'yup';
import { OnboardingOrganizationRegisterFormFields } from 'src/types/registrationFormTypes';
import { URL_REGEX } from 'src/constants/Regex';
import { ABOUT_CHARACTER_LIMIT } from 'src/constants/Onboarding';

export const OnboardingOrganizationValidationSchema: Yup.ObjectSchema<OnboardingOrganizationRegisterFormFields> =
  Yup.object().shape({
    displayName: Yup.string().required('Display name is required'),
    website: Yup.string()
      .matches(URL_REGEX, 'Enter a valid url')
      .required('Website is required'),
    country: Yup.string().required('Country is required'),
    industryType: Yup.string().required('Industry Type is required'),
    industrySize: Yup.string().required('Industry Size is required'),
    about: Yup.string()
      .required('About is required')
      .max(ABOUT_CHARACTER_LIMIT),
    location: Yup.string().required('Location is required'),
  });
