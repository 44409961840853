import styles from './ErrorPage.module.scss';

export default function ErrorPage() {
  return (
    <div className={styles['error-page']}>
      <h1>Oops!</h1>
      <p>
        <i>This page doesn’t exist</i>
      </p>
    </div>
  );
}
