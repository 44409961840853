import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';

interface ErrorBoundaryProps {
  icon?: React.ReactNode;
  message: string;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ icon, message }) => {
  return (
    <Box>
      {icon ? icon : <ErrorIcon />}

      <Typography variant="h2" component="h2">
        {message}
      </Typography>
    </Box>
  );
};

export default ErrorBoundary;
