import { SUPPORT_EMAIL } from './Developer';
import { MAX_TIME_FOR_EDITING_OR_DELETING_REPORT_MESSAGE } from './reportMessage';

export const UNHANDLED_ERROR = `Something went wrong`;
export const SNAKE_CASE_VALIDATION_ERROR = `Snake case consists of lowercase letters with "_" only allowed.`;

export const GROUP_NAME_ALREADY_TAKEN_ERROR = `The group name is already taken. Please choose a different name and try again.`;

export const REPORT_MESSAGE_EDIT_MAX_TIME_LIMIT_ERROR = `I'm sorry, you can only edit message within ${MAX_TIME_FOR_EDITING_OR_DELETING_REPORT_MESSAGE} minutes of creation.`;

export const ORGANIZATION_ID_NOT_FOUND_ERROR = `Organization not found.`;

export const ERROR_WHILE_SECURITY_INTERACTION = `Something went wrong while updating security interaction.`;

export const ERROR_FETCHING_ORGANIZATION_SECURITY_DATA = `Something went wrong while getting security data.`;

export const WARNING_UPDATING_ORGANIZATION_COUNTRY = `You cannot change the country once set due to billing limitations. Please create a new organization if you wish to change the country.`;

export const WARNING_UPDATING_INDIVIDUAL_USER_COUNTRY = `You cannot change the country once set due to billing limitations. For any queries, please reach out to ${SUPPORT_EMAIL}.`;

export const USER_DETAILS_NOT_FOUND_ERROR = `User details not found.`;

export const MEMORY_NOT_FOUND_BY_ID =
  'Something went wrong while trying to get your specific memory to edit.';

export const REMINDER_NOT_FOUND_BY_ID =
  'Something went wrong while trying to get your specific reminder to edit.';

export const INVALID_SENDGRID_TEMPLATE_ID_ERROR =
  'Invalid SendGrid template ID.';

export const NO_NEW_USERS_FOUND_FOR_SCHEDULING_MAIL =
  'No new users found for scheduling mails.';

export const DUPLICATION_OF_SCHEDULING_MAIL =
  'Emails already scheduled for the template id in this campaign.';

export const SCHEDULING_MAIL_IN_PAST =
  'Email schedule date cannot be in the past.';

export const NOT_AUTHORIZED_MESSAGE = `Not authorized, please contact your admin or email ${SUPPORT_EMAIL}.`;

export const NOT_AUTHORIZED_TOAST_MESSAGE = 'You need admin access.';
