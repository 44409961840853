import { Card, Typography } from '@mui/material';
import { DEVELOPER_NAME } from 'src/constants/Developer';
import {
  HANABI_PRIVACY_POLICY,
  HANABI_TERMS_AND_CONDITIONS,
  HANABI_WEBSITE,
} from 'src/constants/Urls';

export const Footer = () => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: { xs: '8px', lg: '16px' },
        padding: { xs: '16px', lg: '8px 16px' },
        borderRadius: 0,
        alignItems: 'center',
        textAlign: { xs: 'center', lg: 'left' },
      }}
    >
      <Typography
        sx={{ flex: { xs: 3, sm: 'none' } }}
        component={'p'}
        variant="body2"
      >
        A product of{' '}
        <a
          id="hanabi_website_url"
          href={HANABI_WEBSITE}
          referrerPolicy="no-referrer"
          target="_blank"
        >
          {DEVELOPER_NAME}
        </a>
      </Typography>
      <Typography
        sx={{ flex: { xs: 1, sm: 'none' } }}
        component={'p'}
        variant="body2"
      >
        <a
          id="privacy_policy_url"
          href={HANABI_PRIVACY_POLICY}
          referrerPolicy="no-referrer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </Typography>
      <Typography
        sx={{ flex: { xs: 1, sm: 'none' } }}
        component={'p'}
        variant="body2"
      >
        <a
          id="terms_condition_url"
          href={HANABI_TERMS_AND_CONDITIONS}
          referrerPolicy="no-referrer"
          target="_blank"
        >
          Terms of Service
        </a>
      </Typography>
    </Card>
  );
};
