import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, RouteObject, Routes } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import theme from './utils/theme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthStoreProvider } from './stores/AuthStore/AuthStore';
import { AlertStoreProvider } from './stores/AlertStore/AlertStore';
import { DialogStoreProvider } from './stores/DialogStore/DialogStore';
import CustomDialog from './components/custom-dialog/CustomDialog';
import { ROUTES } from './constants/UiRoutes';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { OrganizationProvider } from './stores/OrganizationStore/OrganizationStore';
import CustomNotification from './components/custom-notification/CustomNotification';
import { PaymentStoreProvider } from './stores/PaymentStore/PaymentStore';
import PaymentFlowLoader from './pages/lemonsqueezy-payment/paymentFlowLoader';

const AppWithRouter = () => {
  const oAuthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
  const gtmId = process.env.REACT_APP_GTM_ID;

  if (!oAuthClientId) {
    throw new Error(
      'REACT_APP_OAUTH_CLIENT_ID is missing or empty. Please set the environment variable.',
    );
  }

  if (!gtmId) {
    throw new Error(
      'REACT_APP_GTM_ID is missing or empty. Please set the environment variable.',
    );
  }

  const renderRoutes = (routes: RouteObject[]) => {
    return routes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element}>
        {route.children ? renderRoutes(route.children) : null}
      </Route>
    ));
  };

  return (
    <GoogleOAuthProvider clientId={oAuthClientId}>
      <AuthStoreProvider>
        <OrganizationProvider>
          <AlertStoreProvider>
            <DialogStoreProvider>
              <PaymentStoreProvider>
                <ThemeProvider theme={theme}>
                  <BrowserRouter>
                    <Routes>{renderRoutes(ROUTES)}</Routes>
                  </BrowserRouter>
                  <CustomDialog />
                  <CustomNotification />
                  <PaymentFlowLoader />
                </ThemeProvider>
              </PaymentStoreProvider>
            </DialogStoreProvider>
          </AlertStoreProvider>
        </OrganizationProvider>
      </AuthStoreProvider>
    </GoogleOAuthProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<AppWithRouter />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
