const dateTimeDisplayFormatOptions: Intl.DateTimeFormatOptions | undefined = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true,
};

export function checkInputDateBeyondMaxTime(
  isoDateString: string,
  maxTime: number, // Time (in minutes)
): boolean {
  const currentDate = new Date();
  const inputDate = new Date(isoDateString);

  inputDate.setMinutes(inputDate.getMinutes() + maxTime);

  return currentDate > inputDate;
}

export function getMonthRangeByCurrentDate(isoDateString: Date) {
  const date = new Date(isoDateString);
  const currentMonthInitialIsoDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
  );
  const currentMonthEndIsoDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999,
  );

  return { currentMonthInitialIsoDate, currentMonthEndIsoDate };
}

export const getEndOfDay = (isoDateString: Date): Date => {
  const endOfDay = new Date(isoDateString);

  endOfDay.setHours(23, 59, 59, 999);

  return endOfDay;
};

export const formatDateTime = (dateString: string): string => {
  // dd-mon-yr time
  return new Intl.DateTimeFormat('en-GB', dateTimeDisplayFormatOptions).format(
    new Date(dateString),
  );
};
