import { CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { BarChart } from '@mui/x-charts/BarChart';
import { Range } from 'react-date-range';
import { BarChartTypes, InteractionCountByDay } from 'src/types/overview';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import styleVariable from '../../scss/_variables.scss';

export interface UsageChartProps {
  handleOpenDateRangePicker: (type: keyof typeof BarChartTypes) => void;
  selectedRange: Range[];
  usageChartType: keyof typeof BarChartTypes;
  chartLabel: string;
  interactionBarChartLoading: boolean;
  interactionDataSet: InteractionCountByDay[];
}

const BAR_CHART_CONTAINER_HEIGHT = '50vh';

const UsageChart = observer(
  ({
    usageChartType,
    chartLabel,
    handleOpenDateRangePicker,
    interactionBarChartLoading,
    selectedRange,
    interactionDataSet,
  }: UsageChartProps) => {
    const { getUserDetails } = useAuthStore();
    const userDetails = getUserDetails();

    if (!userDetails) return null;

    if (
      !selectedRange ||
      !selectedRange[0].startDate ||
      !selectedRange[0].endDate
    )
      return null;

    if (interactionBarChartLoading) {
      return (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: BAR_CHART_CONTAINER_HEIGHT,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: BAR_CHART_CONTAINER_HEIGHT,
        }}
      >
        <Box sx={{ marginTop: '32px' }}>
          <TextField
            name="Start Date"
            label="Start Date"
            value={`${new Date(selectedRange[0].startDate).toDateString()}`}
            variant="outlined"
            focused={false}
            sx={{
              marginRight: { xs: '0', lg: '16px' },
              marginBottom: '16px',
              width: { xs: '100%', lg: 'auto' },
            }}
            size="small"
            InputProps={{ readOnly: true }}
            onClick={() => {
              handleOpenDateRangePicker(usageChartType);
            }}
          />

          <TextField
            name="End Date"
            label="End Date"
            value={`${new Date(selectedRange[0].endDate).toDateString()}`}
            variant="outlined"
            focused={false}
            size="small"
            sx={{
              width: { xs: '100%', lg: 'auto' },
              marginBottom: '16px',
            }}
            InputProps={{ readOnly: true }}
            onClick={() => {
              handleOpenDateRangePicker(usageChartType);
            }}
          />
        </Box>
        <BarChart
          dataset={interactionDataSet}
          series={[
            {
              dataKey: 'count',
              label: chartLabel,
              id: 'user-interaction-count',
              color: styleVariable.hanabiThemeColorDarkRed,
            },
          ]}
          xAxis={[
            {
              dataKey: 'label',
              scaleType: 'band',
            },
          ]}
        />
      </Box>
    );
  },
);

export default UsageChart;
