import React from 'react';
import { Typography, Box, SxProps, Theme } from '@mui/material';
import { PricingPlanFeatureApiResponse } from 'src/types/plan';
import { CustomListItems } from '../custom-list-items/Custom_ListItems_MUI_Component';

export interface PricingFeaturesProps {
  features: PricingPlanFeatureApiResponse[];
  DescriptionIcon: React.ReactNode;
  overallWrapperStyle?: SxProps<Theme>;
  descriptionStyle?: SxProps<Theme>;
  highlightListItemStyle?: SxProps<Theme>;
}

const PricingFeatures: React.FC<PricingFeaturesProps> = ({
  features,
  DescriptionIcon,
  overallWrapperStyle,
  highlightListItemStyle,
  descriptionStyle,
}) => {
  return (
    <Box sx={{ ...overallWrapperStyle }}>
      {features?.map(({ description, highlights }, index) => (
        <Box key={index} sx={{ paddingY: '8px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {DescriptionIcon}
            <Box sx={{ ...descriptionStyle }}>
              <Typography variant="body2" component="div" fontSize={'16px'}>
                {description}
              </Typography>
            </Box>
          </Box>
          {highlights && (
            <CustomListItems
              listItemsData={highlights?.map((highlight) => ({
                description: highlight,
              }))}
              listItemStyle={{
                ...highlightListItemStyle,
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default PricingFeatures;
