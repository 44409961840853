import { Box } from '@mui/material';
import CustomProgressBar from 'src/components/custom-progress-bar/CustomProgressBar';
import styleVariables from 'src/scss/_variables.scss';
import {
  LEMONSQUEEZY_PAYMENT_LOADER_TEXT,
  MUI_DRAWER_OVERRIDING_Z_INDEX,
} from 'src/constants/lemonsqueezy';
import { observer } from 'mobx-react-lite';
import { usePaymentStore } from 'src/stores/PaymentStore/PaymentStore';

const PaymentFlowLoader = observer(() => {
  const { isPaymentFlowStarted } = usePaymentStore();

  if (!isPaymentFlowStarted) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100vw',
        height: '100vh',
        zIndex: MUI_DRAWER_OVERRIDING_Z_INDEX,
        backgroundColor: styleVariables.darkNavyBlue,
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CustomProgressBar
          title={LEMONSQUEEZY_PAYMENT_LOADER_TEXT}
          progressBarWrapperStyle={{
            padding: '20px 40px',
            backgroundColor: styleVariables.hanabiThemeColorDarkRed,
            borderRadius: '8px',
            border: '4px solid',
            borderColor: styleVariables.colorWhite,
          }}
        />
      </Box>
    </Box>
  );
});

export default PaymentFlowLoader;
