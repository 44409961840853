import {
  List,
  ListItem,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';

export interface ListItemData {
  heading?: string;
  description: string;
}

interface CustomListItemsProps {
  listItemsData: ListItemData[];
  listItemStyle?: SxProps<Theme>;
}

export const CustomListItems: React.FC<CustomListItemsProps> = ({
  listItemsData,
  listItemStyle,
}) => {
  return (
    <List>
      {listItemsData?.map(({ description, heading }, index) => (
        <ListItem
          key={index}
          sx={{
            display: 'list-item',
            ...listItemStyle,
          }}
        >
          <ListItemText
            primary={
              heading ? (
                <Typography
                  variant="subtitle1"
                  component="span"
                  fontWeight="bold"
                >
                  {heading}&nbsp;
                </Typography>
              ) : null
            }
            secondary={
              <Typography variant="body2" component="span">
                {description}
              </Typography>
            }
            secondaryTypographyProps={{ component: 'div' }}
          />
        </ListItem>
      ))}
    </List>
  );
};
