import { UNHANDLED_ERROR } from 'src/constants/errors';
import { API_ROUTES_FOR_AXIOS, BACKEND_BASE_URL } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { ApiResponse } from 'src/types/commonResponseTypes';
import {
  CreateCheckoutPayload,
  LEMONSQUEEZY_PLAN_INTERVALS,
  LemonsqueezySubscriptionStatus,
  UpdateLemonsqueezySubscriptionPayload,
} from 'src/types/lemonsqueezyTypes';

export const createCheckoutInLemonsqueezy = async (
  payload: CreateCheckoutPayload,
): Promise<ApiResponse<string>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['createCheckoutInLemonsqueezy']();
    const { data } = await axiosInstance.post(apiUrl, payload);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const checkLemonsqueezyExistingSubscriptionByOrgIdFromDb = async (
  orgId: string,
): Promise<ApiResponse<boolean>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS[
        'checkLemonsqueezyExistingSubscriptionByOrgIdFromDb'
      ](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const connectLemonsqueezyEventSourceByOrgId = (
  orgId: string,
): EventSource => {
  const apiUrl =
    API_ROUTES_FOR_AXIOS['connectLemonsqueezyEventSourceByOrgId'](orgId);
  const eventSource = new EventSource(`${BACKEND_BASE_URL}${apiUrl}`, {
    withCredentials: true,
  });

  return eventSource;
};

export const getLemonsqueezySubscriptionIntervalByOrgId = async (
  orgId: string,
): Promise<ApiResponse<keyof typeof LEMONSQUEEZY_PLAN_INTERVALS | null>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS['getLemonsqueezySubscriptionIntervalByOrgId'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const updateSubscriptionByOrgIdInLemonsqueezy = async (
  orgId: string,
  payload: UpdateLemonsqueezySubscriptionPayload,
): Promise<ApiResponse<string>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS['updateSubscriptionByOrgIdInLemonsqueezy'](orgId);
    const { data } = await axiosInstance.put(apiUrl, payload);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const cancelSubscriptionByOrgIdInLemonsqueezy = async (
  orgId: string,
): Promise<ApiResponse<string>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS['cancelSubscriptionByOrgIdInLemonsqueezy'](orgId);
    const { data } = await axiosInstance.delete(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const resumeSubscriptionByOrgIdInLemonsqueezy = async (
  orgId: string,
): Promise<ApiResponse<string>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS['resumeSubscriptionByOrgIdInLemonsqueezy'](orgId);
    const { data } = await axiosInstance.put(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getSubscriptionStatusByOrgIdInLemonsqueezy = async (
  orgId: string,
): Promise<ApiResponse<LemonsqueezySubscriptionStatus>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS['getSubscriptionStatusByOrgIdInLemonsqueezy'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};
