import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../stores/AuthStore/AuthStore';
import styleVariables from '../../scss/_variables.scss';
import { getCurrentVersion } from 'src/utils/version';
import styles from './CustomAppBar.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import { BOT_NAME } from 'src/constants/Bot';
import NotificationMenu, {
  NotificationItem,
} from '../notification-menu/NotificationMenu';
import { ROLES } from 'src/constants/Role';
import ErrorBoundary from '../error-boundary/ErrorBoundry';
import {
  UNHANDLED_ERROR,
  USER_DETAILS_NOT_FOUND_ERROR,
} from 'src/constants/errors';
import { useEffect, useState } from 'react';
import {
  checkDnsVerification,
  checkOnboardingComplete,
} from 'src/services/organization';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  DNS_VERIFY_NOTIFICATION,
  ONBOARDING_NOTIFICATION,
} from 'src/constants/notification';
import { DOCUMENTATION } from 'src/constants/Urls';

export interface CustomAppBarProps {
  setIsSideNavBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomAppBar = observer(({ setIsSideNavBarOpen }: CustomAppBarProps) => {
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { organizationDetails } = useOrganizationStore();
  const { notify } = useAlertStore();
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [searchParams] = useSearchParams();

  const fetchNotifications = async () => {
    if (!organizationDetails || !userDetails) {
      notify(UNHANDLED_ERROR);

      return;
    }

    let isDnsVerifiedForOrganization = false;

    if (
      userDetails.role === ROLES.USER ||
      userDetails.role === ROLES.ADMIN ||
      userDetails.role === ROLES.SUPER_ADMIN
    ) {
      const {
        success: checkDnsVerificationSuccess,
        content: checkDnsVerificationApiResponse,
      } = await checkDnsVerification(organizationDetails._id.toString());

      if (!checkDnsVerificationSuccess || !checkDnsVerificationApiResponse) {
        notify(UNHANDLED_ERROR);

        return;
      }

      isDnsVerifiedForOrganization =
        checkDnsVerificationApiResponse.isDnsVerified;

      if (
        !checkDnsVerificationApiResponse.isDnsVerified &&
        userDetails.role === ROLES.USER
      ) {
        setNotifications([DNS_VERIFY_NOTIFICATION]);

        return;
      } else {
        setNotifications([]);
      }
    }

    if (
      (isDnsVerifiedForOrganization &&
        (userDetails.role === ROLES.ADMIN ||
          userDetails.role === ROLES.SUPER_ADMIN)) ||
      userDetails.role === ROLES.INDIVIDUAL_USER_ADMIN
    ) {
      const {
        success: checkOnboardingCompleteSuccess,
        content: checkOnboardingCompleteApiResponse,
      } = await checkOnboardingComplete(organizationDetails._id.toString());

      if (
        !checkOnboardingCompleteSuccess ||
        !checkOnboardingCompleteApiResponse
      ) {
        notify(UNHANDLED_ERROR);

        return;
      }

      if (!checkOnboardingCompleteApiResponse.isOnboardingCompleted) {
        setNotifications([ONBOARDING_NOTIFICATION]);
      } else {
        setNotifications([]);
      }
    }
  };

  useEffect(() => {
    fetchNotifications();
    // Required to show/hide notifications after updates. For example, if DNS verification status changes, we need to refresh notifications.
  }, [searchParams]);

  if (!userDetails) {
    return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;
  }

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          background: styleVariables.midnightIndigo,
        }}
      >
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsSideNavBarOpen(true)}
            sx={{ display: { sx: 'block', md: 'none' }, padding: 0, margin: 0 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'baseline' }}>
            <div className={styles['app-bar-info-text']}>
              <Typography
                sx={{ fontSize: { xs: '16px', lg: '24px' } }}
                variant="h5"
                component="h1"
              >
                {BOT_NAME} Control Dashboard
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '12px', lg: '16px' },
                  lineHeight: '24px',
                  color: styleVariables.mutedGray,
                  textTransform: 'capitalize',
                }}
                variant="h6"
              >
                {process.env.REACT_APP_ENVIRONMENT ?? ''} Version:{' '}
                {getCurrentVersion()}
              </Typography>
            </div>
            <Typography
              sx={{ fontSize: { xs: '16px', lg: '20px', fontWeight: 700 } }}
              component={'p'}
              variant="body2"
            >
              <a
                id="docs"
                href={DOCUMENTATION}
                referrerPolicy="no-referrer"
                target="_blank"
              >
                Docs
              </a>
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <NotificationMenu
            notifications={notifications}
            userRole={userDetails.role}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default CustomAppBar;
