import { UNHANDLED_ERROR } from 'src/constants/errors';
import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { MemoryApiResponse, CreateMemory } from 'src/types/memory';
import { ApiResponse, PaginatedResponse } from 'src/types/commonResponseTypes';

export const createMemory = async (
  body: CreateMemory,
): Promise<ApiResponse<MemoryApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['createMemory']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const getMemoryListByOrgId = async (
  orgId: string,
  page: number,
  limit: number,
  searchQuery: string | null,
): Promise<ApiResponse<PaginatedResponse<MemoryApiResponse[]>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS.getMemoryListByOrgId(
      orgId,
      page,
      limit,
      searchQuery,
    );
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getMemoryById = async (
  memoryId: string,
): Promise<ApiResponse<MemoryApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getMemoryById'](memoryId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const deleteMemoryById = async (
  memoryId: string,
): Promise<ApiResponse<MemoryApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['deleteMemoryById'](memoryId);
    const { data } = await axiosInstance.delete(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const updateMemoryById = async (
  groupId: string,
  body: CreateMemory,
): Promise<ApiResponse<MemoryApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateMemoryById'](groupId);
    const { data } = await axiosInstance.patch(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
