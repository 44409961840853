import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import {
  UNHANDLED_ERROR,
  USER_DETAILS_NOT_FOUND_ERROR,
} from 'src/constants/errors';
import {
  createUserConsentPolicy,
  getLatestPoliciesNotApprovedByMongoUserId,
} from 'src/services/policy';
import { useAuthStore } from '../../stores/AuthStore/AuthStore';
import { useEffect, useState } from 'react';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import { POLICY_TYPES, PolicyApiResponse } from 'src/types/policy';
import { CustomCheckBoxWithText } from 'src/components/custom-checkbox-field/Custom_CheckBox_MUI_Component';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomLoading from 'src/components/custom-loading';
import styles from 'src/scss/_variables.scss';

export interface PolicyApprovalFormFields {
  agreeToPrivacyPolicy: boolean | undefined;
  agreeToTermsOfService: boolean | undefined;
}

export interface PolicyApprovalDynamicValidationSchema {
  agreeToPrivacyPolicy: Yup.BooleanSchema<boolean | undefined>;
  agreeToTermsOfService: Yup.BooleanSchema<boolean | undefined>;
}

const PolicyApproval = observer(() => {
  const navigate = useNavigate();
  const { getUserDetails } = useAuthStore();
  const { notify } = useAlertStore();
  const userDetails = getUserDetails();
  const [nonApprovedLatestPolicy, setNonApprovedLatestPolicy] = useState<
    PolicyApiResponse[]
  >([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({}),
  );
  const [isPageLoading, setIsPageLoading] = useState(true);

  const fetchNonApprovedLatestPolicy = async () => {
    if (!userDetails) {
      notify(UNHANDLED_ERROR);

      return;
    }

    setIsPageLoading(true);
    const {
      success: getLatestPoliciesNotApprovedByUserSuccess,
      content: latestPoliciesNotApprovedByUser,
    } = await getLatestPoliciesNotApprovedByMongoUserId(
      userDetails._id.toString(),
    );

    if (
      !getLatestPoliciesNotApprovedByUserSuccess ||
      !latestPoliciesNotApprovedByUser
    ) {
      notify(UNHANDLED_ERROR);
      setIsPageLoading(false);

      return;
    }

    if (latestPoliciesNotApprovedByUser.length <= 0) {
      navigate(PATHS.overview);
      setIsPageLoading(false);

      return;
    }

    const dynamicInitialValues = latestPoliciesNotApprovedByUser.reduce(
      (policyApprovalFormFields: Partial<PolicyApprovalFormFields>, policy) => {
        if (policy.type === 'PRIVACY_POLICY') {
          policyApprovalFormFields.agreeToPrivacyPolicy = false;
        } else if (policy.type === 'TERMS_OF_SERVICE') {
          policyApprovalFormFields.agreeToTermsOfService = false;
        }

        return policyApprovalFormFields;
      },
      {},
    );

    const dynamicValidationSchema = Yup.object().shape(
      latestPoliciesNotApprovedByUser.reduce(
        (
          policyApprovalValidationSchema: Partial<PolicyApprovalDynamicValidationSchema>,
          policy,
        ) => {
          if (policy.type === 'PRIVACY_POLICY') {
            policyApprovalValidationSchema.agreeToPrivacyPolicy =
              Yup.boolean().oneOf(
                [true],
                'You must agree to the privacy policy',
              );
          } else if (policy.type === 'TERMS_OF_SERVICE') {
            policyApprovalValidationSchema.agreeToTermsOfService =
              Yup.boolean().oneOf(
                [true],
                'You must agree to the terms of service',
              );
          }

          return policyApprovalValidationSchema;
        },
        {},
      ),
    );

    setInitialValues(dynamicInitialValues);
    setValidationSchema(dynamicValidationSchema);
    setNonApprovedLatestPolicy(latestPoliciesNotApprovedByUser);
    setIsPageLoading(false);
  };

  useEffect(() => {
    fetchNonApprovedLatestPolicy();
  }, []);

  if (!userDetails) {
    return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;
  }

  const handleSubmitOnboardingRegisterForm = async () => {
    setIsFormSubmitted(true);

    if (!nonApprovedLatestPolicy || nonApprovedLatestPolicy.length === 0) {
      notify(UNHANDLED_ERROR);
      setIsFormSubmitted(false);

      return;
    }

    const userConsentPolicies = nonApprovedLatestPolicy.map(
      ({ _id, version }) => ({
        policyId: _id.toString(),
        organizationId: userDetails.organizationId.toString(),
        userId: userDetails._id.toString(),
        policyVersion: version,
      }),
    );

    const {
      success: createUserConsentPolicySuccess,
      content: userConsentPolicy,
    } = await createUserConsentPolicy({
      policies: userConsentPolicies,
    });

    if (!createUserConsentPolicySuccess || !userConsentPolicy) {
      notify(UNHANDLED_ERROR);
      setIsFormSubmitted(false);

      return;
    }

    setIsFormSubmitted(false);
    navigate(PATHS.home);
  };

  if (isPageLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <CustomLoading />
      </Box>
    );
  }

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth
      sx={{ backgroundColor: styles.darkNavyBlue }}
    >
      <DialogContent sx={{ backgroundColor: styles.midnightIndigo }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitOnboardingRegisterForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <Typography
                variant="body1"
                fontSize={'16px'}
                textAlign="center"
                padding={'4px'}
              >
                Terms and Policies
              </Typography>
              <Box display="flex" flexDirection="column" gap={1}>
                {nonApprovedLatestPolicy
                  ?.filter((policyDetails) =>
                    Object.keys(POLICY_TYPES).includes(policyDetails.type),
                  )
                  .map(({ policyLink, type }, index) => (
                    <CustomCheckBoxWithText
                      key={index}
                      name={
                        type === POLICY_TYPES.PRIVACY_POLICY
                          ? 'agreeToPrivacyPolicy'
                          : 'agreeToTermsOfService'
                      }
                      text="I have agreed to the "
                      href={policyLink}
                      hrefText={
                        type === POLICY_TYPES.PRIVACY_POLICY
                          ? 'privacy policy'
                          : 'terms of service'
                      }
                      targetPage={policyLink ? '_blank' : '_parent'}
                      labelPlacement="end"
                    />
                  ))}
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {isFormSubmitted && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '50%',
              left: '50%',
              opacity: 0.5,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CustomLoading />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default PolicyApproval;
