import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@mui/material';
import { getAllCountries } from 'countries-and-timezones';
import { CustomTextField } from 'src/components/custom-text-field/Custom_Textfield_MUI_Component';
import { CustomSelect } from 'src/components/custom-select-field/Custom_Select_MUI_Component';
import { industrySizes, industryTypes } from 'src/constants/industryStaticData'; // static data
import { OnboardingOrganizationValidationSchema } from '../organization-profile-update/organization-registration-validation';
import { individualUserRegistrationValidationSchema } from '../organization-profile-update/individual-user-registration-validation';
import {
  OnboardingOrganizationRegisterFormFields,
  IndividualUserOnboardingRegisterFormFields,
} from 'src/types/registrationFormTypes';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { useAlertStore } from '../../stores/AlertStore/AlertStore';
import {
  WARNING_UPDATING_ORGANIZATION_COUNTRY,
  UNHANDLED_ERROR,
} from 'src/constants/errors';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import CustomLoading from 'src/components/custom-loading';
import styleVariables from 'src/scss/_variables.scss';
import {
  ABOUT_PLACEHOLDER_FOR_INDIVIDUAL_USER,
  ABOUT_PLACEHOLDER_FOR_ORGANIZATION,
} from 'src/constants/Onboarding';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import * as Yup from 'yup';
import { ROLES } from 'src/constants/Role';

const countries = getAllCountries();

const initialValuesForOrganizationOnboardingForm: OnboardingOrganizationRegisterFormFields =
  {
    displayName: '',
    website: '',
    country: '',
    industryType: '',
    industrySize: '',
    about: '',
    location: '',
  };

const initialValuesForIndividualUserOnboardingForm: IndividualUserOnboardingRegisterFormFields =
  {
    country: '',
    about: '',
    location: '',
  };
const RegistrationForm: React.FC = observer(() => {
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { notify } = useAlertStore();
  const navigate = useNavigate();
  const { updateOrganization } = useOrganizationStore();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<string[]>([]);
  const [initialValues, setInitialValues] = useState<
    | OnboardingOrganizationRegisterFormFields
    | IndividualUserOnboardingRegisterFormFields
    | null
  >(null);
  const [validationSchema, setValidationSchema] = useState<Yup.ObjectSchema<
    | IndividualUserOnboardingRegisterFormFields
    | OnboardingOrganizationRegisterFormFields
  > | null>(null);
  const individualUserSteps = ['Your Details'];
  const organizationSteps = ['Organization Details', 'Organization Details'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (!userDetails) return null;

  useEffect(() => {
    if (!userDetails) return;

    switch (userDetails.role) {
      case ROLES.INDIVIDUAL_USER_ADMIN:
        setInitialValues(initialValuesForIndividualUserOnboardingForm);
        setSteps(individualUserSteps);
        setValidationSchema(individualUserRegistrationValidationSchema);
        break;
      case ROLES.SUPER_ADMIN:
      case ROLES.ADMIN:
        setInitialValues(initialValuesForOrganizationOnboardingForm);
        setSteps(organizationSteps);
        setValidationSchema(OnboardingOrganizationValidationSchema);
        break;
      default:
        break;
    }
  }, [userDetails]);

  const handleSubmitOnboardingRegisterForm = async (
    values:
      | IndividualUserOnboardingRegisterFormFields
      | OnboardingOrganizationRegisterFormFields,
  ) => {
    setIsFormSubmitted(true);

    const updateSuccess = await updateOrganization(
      userDetails.organizationId.toString(),
      values,
    );

    if (!updateSuccess) {
      notify(UNHANDLED_ERROR);
      setIsFormSubmitted(false);

      return;
    }

    setIsFormSubmitted(false);
    navigate(PATHS.home);
  };

  if (!initialValues) {
    return <CustomLoading />;
  }

  const organizationRegistrationStepperComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <CustomTextField
              name="displayName"
              label="Organization Display name"
              textColor={styleVariables.colorBlack}
            />
            <CustomTextField
              name="website"
              label="Website"
              textColor={styleVariables.colorBlack}
            />{' '}
            <CustomSelect
              name="country"
              label="Country"
              options={Object.values(countries).map((country) => ({
                id: country.id,
                name: country.name,
              }))}
              textColor={styleVariables.colorBlack}
              alertMessage={WARNING_UPDATING_ORGANIZATION_COUNTRY}
            />
            <CustomSelect
              name="industryType"
              label="Industry Type"
              options={industryTypes}
              textColor={styleVariables.colorBlack}
            />
          </>
        );
      case 1:
        return (
          <>
            <CustomSelect
              name="industrySize"
              label="Industry Size"
              options={industrySizes}
              textColor={styleVariables.colorBlack}
            />
            <CustomTextField
              name="location"
              label="Location"
              textColor={styleVariables.colorBlack}
            />

            <CustomTextField
              name="about"
              label="About"
              placeholder={ABOUT_PLACEHOLDER_FOR_ORGANIZATION}
              multiline
              rows={3}
              textColor={styleVariables.colorBlack}
            />
          </>
        );
      default:
        return null;
    }
  };

  const individualUserRegistrationStepperComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <CustomSelect
              name="country"
              label="Country"
              options={Object.values(countries).map((country) => ({
                id: country.id,
                name: country.name,
              }))}
              textColor={styleVariables.colorBlack}
              alertMessage={WARNING_UPDATING_ORGANIZATION_COUNTRY}
            />
            <CustomTextField
              name="location"
              label="Location"
              textColor={styleVariables.colorBlack}
            />

            <CustomTextField
              name="about"
              label="About"
              placeholder={ABOUT_PLACEHOLDER_FOR_INDIVIDUAL_USER}
              multiline
              rows={3}
              textColor={styleVariables.colorBlack}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitOnboardingRegisterForm}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ marginBottom: '16px' }}
            >
              {steps.map((label, i) => (
                <Step key={i}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {(userDetails.role === ROLES.ADMIN ||
              userDetails.role === ROLES.SUPER_ADMIN) &&
              organizationRegistrationStepperComponent()}
            {userDetails.role === ROLES.INDIVIDUAL_USER_ADMIN &&
              individualUserRegistrationStepperComponent()}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '8px',
                marginTop: '8px',
              }}
            >
              {activeStep > 0 && (
                <Button
                  fullWidth
                  onClick={handleBack}
                  variant="contained"
                  color="secondary"
                >
                  Back
                </Button>
              )}

              {activeStep < steps.length - 1 && (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}

              {activeStep === steps.length - 1 && (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              )}
            </Box>
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(PATHS.overview);
                }}
              >
                Skip Registration
              </Button>
              <Typography variant="h6" sx={{ fontSize: '12px' }}>
                * You can update this information later
              </Typography>
            </Box>
          </Form>
        )}
      </Formik>
      {isFormSubmitted && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '50%',
            left: '50%',
            opacity: 0.5,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CustomLoading />
        </Box>
      )}
    </>
  );
});

export default RegistrationForm;
