import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Popover,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import UsageCard from 'src/components/usage-card/UsageCard';
import {
  InteractionCountByDay,
  UsageCardWithRoles,
  BarChartTypes,
} from 'src/types/overview';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { useEffect, useState, useCallback } from 'react';
import {
  getOrganizationInteractionCountForMonth,
  getTotalActiveSpacesIntegrated,
  getTotalUsersAuthorized,
  getUserInteractionCountForMonth,
  getUserInteractionDataForSelectedDates,
  getOrgInteractionDataForSelectedDates,
} from 'src/services/overview';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  ORGANIZATION_ID_NOT_FOUND_ERROR,
  UNHANDLED_ERROR,
} from 'src/constants/errors';
import { BOT_NAME } from 'src/constants/Bot';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import { DateRange, Range } from 'react-date-range';
import { getMonthRangeByCurrentDate, getEndOfDay } from 'src/utils/dateUtils';
import {
  ORG_BAR_CHART_LABEL,
  USER_BAR_CHART_LABEL,
} from 'src/constants/dialogMetaInfo';
import UsageChart from 'src/components/usage-chart/UsageChart';
import { CustomDivider } from 'src/components/custom-divider/CustomDivider';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import TagManager from 'react-gtm-module';
import { ROLES } from 'src/constants/Role';

const initialFieldsForOverViewCardsWithRole: UsageCardWithRoles[] = [
  {
    count: 0,
    allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.INDIVIDUAL_USER_ADMIN],
    title: `Groups Integrated with ${BOT_NAME}`,
    subtitle: `Total Count`,
    id: 'spacesIntegrated',
  },
  {
    count: '',
    allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
    title: `Users Authenticated with ${BOT_NAME}`,
    subtitle: `Total Users / Purchased Seats`,
    id: 'authorizedUsers',
  },
  {
    count: 0,
    allowedRoles: [
      ROLES.USER,
      ROLES.ADMIN,
      ROLES.SUPER_ADMIN,
      ROLES.INDIVIDUAL_USER_ADMIN,
    ],
    title: `Your Interactions with ${BOT_NAME}`,
    subtitle: `Total Count this month`,
    id: 'totalInteractions',
  },
  {
    count: 0,
    allowedRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
    title: ` Org Interactions with ${BOT_NAME}`,
    subtitle: `Total Count this month`,
    id: 'totalOrganizationsInteractions',
  },
];
const initialFieldsForDataForSelectedMonth: InteractionCountByDay[] = [
  { count: 0, label: '' },
];

const RANGE_SELECTION_KEY = 'selection';

const Overview = observer(() => {
  const [overViewCardsWithRole, setOverViewCardsWithRole] = useState(
    initialFieldsForOverViewCardsWithRole,
  );
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [currentBarChartType, setCurrentBarChartType] = useState<
    keyof typeof BarChartTypes
  >(BarChartTypes.UserBarChart);
  const [userInteractionBarChartLoading, setUserInteractionBarChartLoading] =
    useState(true);
  const [orgInteractionBarChartLoading, setOrgInteractionBarChartLoading] =
    useState(true);
  const { getUserDetails } = useAuthStore();
  const { notify } = useAlertStore();
  const userDetails = getUserDetails();
  const [
    userInteractionDataForSelectedDates,
    setUserInteractionDataForSelectedDates,
  ] = useState<InteractionCountByDay[]>(initialFieldsForDataForSelectedMonth);
  const [
    orgInteractionDataForSelectedDates,
    setOrgInteractionDataForSelectedDates,
  ] = useState<InteractionCountByDay[]>(initialFieldsForDataForSelectedMonth);
  const { organizationDetails } = useOrganizationStore();
  const gtmId = process.env.REACT_APP_GTM_ID;

  const [dateRangeInPopOver, setDateRangeInPopOver] = useState<Range[]>([
    {
      startDate: getMonthRangeByCurrentDate(new Date())
        .currentMonthInitialIsoDate,
      endDate: getEndOfDay(new Date()), // current day is the maximum end date
      key: RANGE_SELECTION_KEY,
    },
  ]);
  const [selectedRangeForUser, setSelectedRangeForUser] = useState<Range[]>([
    {
      startDate: getMonthRangeByCurrentDate(new Date())
        .currentMonthInitialIsoDate,
      endDate: getEndOfDay(new Date()),
      key: RANGE_SELECTION_KEY,
    },
  ]);
  const [selectedRangeForOrg, setSelectedRangeForOrg] = useState<Range[]>([
    {
      startDate: getMonthRangeByCurrentDate(new Date())
        .currentMonthInitialIsoDate,
      endDate: getEndOfDay(new Date()),
      key: RANGE_SELECTION_KEY,
    },
  ]);

  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  if (!organizationDetails) {
    return <ErrorBoundary message={ORGANIZATION_ID_NOT_FOUND_ERROR} />;
  }

  if (
    !dateRangeInPopOver?.[0]?.startDate ||
    !dateRangeInPopOver?.[0]?.endDate ||
    !selectedRangeForUser?.[0]?.startDate ||
    !selectedRangeForUser?.[0]?.endDate ||
    !selectedRangeForOrg?.[0]?.startDate ||
    !selectedRangeForOrg?.[0]?.endDate
  )
    return null;

  const fetchOverviewStats = useCallback(async () => {
    if (!userDetails) return;
    setIsPageLoading(true);
    let spacesIntegrated = 0;
    let authorizedUsers = '- / -';
    let totalInteractions = 0;
    let totalOrganizationsInteractions = 0;
    let isErrorOccurred = false;

    if (
      userDetails.role === ROLES.ADMIN ||
      userDetails.role === ROLES.SUPER_ADMIN
    ) {
      try {
        const [
          { success: getTotalUsersSuccess, content: getTotalUsersContent },
          {
            success: getOrgInteractionCountSuccess,
            content: getOrgInteractionCountContent,
          },
        ] = await Promise.all([
          getTotalUsersAuthorized(userDetails.organizationId),
          getOrganizationInteractionCountForMonth(
            userDetails.organizationId,
            new Date().toString(),
          ),
        ]);

        if (getTotalUsersSuccess && getTotalUsersContent) {
          authorizedUsers = `${getTotalUsersContent.total}/${organizationDetails.purchasedSeats}`;
        }

        if (!getTotalUsersSuccess) {
          isErrorOccurred = true;
        }

        if (getOrgInteractionCountSuccess && getOrgInteractionCountContent) {
          totalOrganizationsInteractions = getOrgInteractionCountContent.count;
        }

        if (!getOrgInteractionCountSuccess) {
          isErrorOccurred = true;
        }
      } catch (error) {
        notify(UNHANDLED_ERROR);
      }
    }

    if (
      userDetails.role === ROLES.ADMIN ||
      userDetails.role === ROLES.SUPER_ADMIN ||
      userDetails.role === ROLES.INDIVIDUAL_USER_ADMIN
    ) {
      try {
        const {
          success: getTotalSpacesSuccess,
          content: getTotalSpacesContent,
        } = await getTotalActiveSpacesIntegrated(userDetails.organizationId);

        if (getTotalSpacesSuccess && getTotalSpacesContent) {
          spacesIntegrated = getTotalSpacesContent.total;
        }

        if (!getTotalSpacesSuccess) {
          isErrorOccurred = true;
        }
      } catch (error) {
        notify(UNHANDLED_ERROR);
      }
    }

    /**
     * Below api calls applied to all roles
     */
    const {
      success: getUserInteractionCountSuccess,
      content: getUserInteractionCountContent,
    } = await getUserInteractionCountForMonth(
      userDetails._id,
      userDetails.organizationId,
      new Date().toString(),
    );

    if (!getUserInteractionCountSuccess) {
      isErrorOccurred = true;
    }

    if (getUserInteractionCountSuccess && getUserInteractionCountContent) {
      totalInteractions = getUserInteractionCountContent.count;
    }

    if (isErrorOccurred) notify(UNHANDLED_ERROR);

    setOverViewCardsWithRole((overViewCardsWithRole) => {
      return overViewCardsWithRole.map((overViewCardWithRole) => {
        if (overViewCardWithRole.id === 'totalInteractions') {
          overViewCardWithRole.count = totalInteractions;
        } else if (overViewCardWithRole.id === 'spacesIntegrated') {
          overViewCardWithRole.count = spacesIntegrated;
        } else if (overViewCardWithRole.id === 'authorizedUsers') {
          overViewCardWithRole.count = authorizedUsers;
        } else if (
          overViewCardWithRole.id === 'totalOrganizationsInteractions'
        ) {
          overViewCardWithRole.count = totalOrganizationsInteractions;
        }

        return overViewCardWithRole;
      });
    });
    setIsPageLoading(false);
  }, [userDetails?._id, userDetails?.organizationId, userDetails?.role]);

  async function fetchUserBarChatData(dateRange: Range[]) {
    if (!userDetails || !dateRange[0].startDate || !dateRange[0].endDate) {
      notify(UNHANDLED_ERROR);

      return null;
    }

    setUserInteractionBarChartLoading(true);
    const {
      success: getUserInteractionDataByDatesSuccess,
      content: userInteractionDataByDates,
    } = await getUserInteractionDataForSelectedDates(
      userDetails._id,
      userDetails.organizationId,
      new Date(dateRange[0].startDate).toISOString(),
      new Date(dateRange[0].endDate).toISOString(),
      String(new Date().getTimezoneOffset()),
    );

    if (!getUserInteractionDataByDatesSuccess || !userInteractionDataByDates) {
      setUserInteractionBarChartLoading(false);
      notify(UNHANDLED_ERROR);

      return null;
    }

    setUserInteractionDataForSelectedDates(userInteractionDataByDates);
    setUserInteractionBarChartLoading(false);
  }

  async function fetchOrgBarChatData(dateRange: Range[]) {
    if (!userDetails || !dateRange[0].startDate || !dateRange[0].endDate) {
      notify(UNHANDLED_ERROR);

      return null;
    }

    setOrgInteractionBarChartLoading(true);
    const {
      success: getOrgInteractionDataByDatesSuccess,
      content: orgInteractionDataByDates,
    } = await getOrgInteractionDataForSelectedDates(
      userDetails.organizationId,
      new Date(dateRange[0].startDate).toISOString(),
      new Date(dateRange[0].endDate).toISOString(),
      String(new Date().getTimezoneOffset()),
    );

    if (!getOrgInteractionDataByDatesSuccess || !orgInteractionDataByDates) {
      setOrgInteractionBarChartLoading(false);
      notify(UNHANDLED_ERROR);

      return null;
    }

    setOrgInteractionDataForSelectedDates(orgInteractionDataByDates);
    setOrgInteractionBarChartLoading(false);
  }

  const handleSelectDateRange = (ranges: { [key: string]: Range }) => {
    if (!ranges[RANGE_SELECTION_KEY].endDate) {
      notify(UNHANDLED_ERROR);

      return null;
    }

    if (currentBarChartType === BarChartTypes.UserBarChart) {
      setSelectedRangeForUser([
        {
          ...ranges[RANGE_SELECTION_KEY],
          endDate: getEndOfDay(ranges[RANGE_SELECTION_KEY].endDate),
        },
      ]);
    } else if (currentBarChartType === BarChartTypes.OrgBarChart) {
      setSelectedRangeForOrg([
        {
          ...ranges[RANGE_SELECTION_KEY],
          endDate: getEndOfDay(ranges[RANGE_SELECTION_KEY].endDate),
        },
      ]);
    }

    setDateRangeInPopOver([
      {
        ...ranges[RANGE_SELECTION_KEY],
        endDate: getEndOfDay(ranges[RANGE_SELECTION_KEY].endDate),
      },
    ]);
  };

  const handleOpenDateRangePicker = (
    barChartType: keyof typeof BarChartTypes,
  ) => {
    if (barChartType === BarChartTypes.UserBarChart) {
      setDateRangeInPopOver(selectedRangeForUser);
    } else if (barChartType === BarChartTypes.OrgBarChart) {
      setDateRangeInPopOver(selectedRangeForOrg);
    }

    setOpenDateRangePicker(true);
    setCurrentBarChartType(barChartType);
  };

  useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';
    const isNotHanabiDomain =
      organizationDetails.domainName !== 'hanabitech.com';

    if (
      isProduction &&
      gtmId &&
      userDetails &&
      organizationDetails &&
      isNotHanabiDomain
    ) {
      const tagManagerArgs = {
        gtmId: gtmId,
        dataLayer: {
          customer: userDetails._id,
          org_id: userDetails.organizationId,
        },
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, [gtmId, userDetails, organizationDetails]);

  useEffect(() => {
    if (selectedRangeForUser) {
      fetchUserBarChatData(selectedRangeForUser);
    }
  }, [selectedRangeForUser]);

  useEffect(() => {
    if (!userDetails) return;

    if (
      selectedRangeForOrg &&
      (userDetails.role === ROLES.ADMIN ||
        userDetails.role === ROLES.SUPER_ADMIN)
    ) {
      fetchOrgBarChatData(selectedRangeForOrg);
    }
  }, [selectedRangeForOrg, userDetails?.role]);

  useEffect(() => {
    fetchOverviewStats();
  }, []);

  if (!userDetails) return null;

  if (isPageLoading) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ModuleHeader title={'Overview'} />
      <Box sx={{ display: 'flex', position: 'relative', marginBottom: '32px' }}>
        <CssBaseline />
        <Grid container spacing={2}>
          {overViewCardsWithRole.map(
            ({ count, allowedRoles, subtitle, title, id }) =>
              allowedRoles.includes(userDetails.role) && (
                <Grid item xs={12} md={3} key={id}>
                  <UsageCard
                    key={id}
                    count={count}
                    title={title}
                    subtitle={subtitle}
                  />
                </Grid>
              ),
          )}
        </Grid>
      </Box>

      <CustomDivider />

      <UsageChart
        chartLabel={USER_BAR_CHART_LABEL}
        handleOpenDateRangePicker={handleOpenDateRangePicker}
        usageChartType={BarChartTypes.UserBarChart}
        interactionBarChartLoading={userInteractionBarChartLoading}
        interactionDataSet={userInteractionDataForSelectedDates}
        selectedRange={selectedRangeForUser}
      />

      {(userDetails.role === ROLES.ADMIN ||
        userDetails.role === ROLES.SUPER_ADMIN) && (
        <>
          <CustomDivider />
          <UsageChart
            chartLabel={ORG_BAR_CHART_LABEL}
            handleOpenDateRangePicker={handleOpenDateRangePicker}
            usageChartType={BarChartTypes.OrgBarChart}
            interactionBarChartLoading={orgInteractionBarChartLoading}
            interactionDataSet={orgInteractionDataForSelectedDates}
            selectedRange={selectedRangeForOrg}
          />
        </>
      )}

      <Popover
        open={openDateRangePicker}
        onClose={() => {
          setOpenDateRangePicker(false);
        }}
        anchorEl={document.body}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DateRange
          ranges={dateRangeInPopOver}
          onChange={handleSelectDateRange}
          months={2}
          direction={isLargeScreen ? 'horizontal' : 'vertical'}
          maxDate={new Date()}
        />
      </Popover>
    </>
  );
});

export default Overview;
