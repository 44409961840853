import { Box, Button, Typography } from '@mui/material';
import {
  checkNameAvailability,
  createGroup,
  getAllReportGroups,
  updateGroupByGroupId,
  deleteReportGroupById,
} from '../../services/report-group';
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {
  SNAKE_CASE_VALIDATION_ERROR,
  GROUP_NAME_ALREADY_TAKEN_ERROR,
  UNHANDLED_ERROR,
} from '../../constants/errors';
import { validateTextInSnakeCase } from '../../utils/commonValidation';
import { CreateGroupProp, ReportGroup } from '../../types/reportGroup';
import {
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  DIALOG_CONTENT_FOR_REPORT_DELETE,
  DIALOG_CONTENT_FOR_REPORT_GROUP,
  DIALOG_TITLE_FOR_DELETE,
  DIALOG_TITLE_FOR_REPORT_GROUP,
  DIALOG_TITLE_FOR_UPDATE_REPORT_GROUP,
  DIALOG_CONTENT_FOR_UPDATE_REPORT_GROUP,
  CREATE_REPORT_GROUP_BUTTON_TEXT,
  UPDATE_GROUP_SUCCESS,
} from '../../constants/dialogMetaInfo';
import { observer } from 'mobx-react-lite';
import { useDialogStore } from '../../stores/DialogStore/DialogStore';
import { DialogFor } from '../../stores/DialogStore/DialogStore';
import { useAlertStore } from '../../stores/AlertStore/AlertStore';
import { useAuthStore } from '../../stores/AuthStore/AuthStore';
import { PATHS } from 'src/constants/navigateRoutes';
import {
  DATA_GRID_COLUMN_WIDTH_3,
  DATA_GRID_COLUMN_WIDTH_6,
  DATA_GRID_COLUMN_WIDTH_7,
} from 'src/constants/Layout';
import { ROLES } from 'src/constants/Role';
import TablePaginationActions from 'src/constants/TablePaginationActions';
import {
  PAGE_SIZE_OPTIONS,
  WORDS_PER_ROW_CELL,
  truncateString,
} from 'src/constants/utils';
import { FormField } from 'src/components/custom-dialog/CustomDialog';

const REPORT_GROUP_INITIAL_FIELDS: FormField[] = [
  {
    label: 'Report Group Name',
    id: 'title',
    value: '',
    type: 'text',
  },
];

const ReportGroupPage = observer(() => {
  const navigate = useNavigate();
  const { notify } = useAlertStore();
  const { getUserDetails, logout } = useAuthStore();
  const [deleteReportId, setDeleteReportId] = useState<string | null>(null);
  const [reportGroups, setReportGroups] = useState<ReportGroup[]>([]);
  const [groupIdForUpdate, setGroupIdForUpdate] = useState<string | null>(null);
  const [loadingForDataGrid, setLoadingForDataGrid] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);

  const userDetails = getUserDetails();

  const {
    dialogType,
    dialogueState,
    updateDialogueState,
    dialogFormFields,
    updateDialogMetaData,
    resetFormFieldsValue,
  } = useDialogStore();

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  useEffect(() => {
    if (!userDetails) {
      logout();
    }
  }, [userDetails, logout]);

  const openDialogForCreateGroup = () => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_REPORT_GROUP,
      DIALOG_CONTENT_FOR_REPORT_GROUP,
      DialogFor.REPORT_GROUP,
      resetFormFieldsValue(REPORT_GROUP_INITIAL_FIELDS),
    );

    updateDialogueState('OPEN');

    setGroupIdForUpdate(null);
  };

  const openDialogForDeleteGroup = (deleteGroupId: string) => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_REPORT_DELETE,
      DialogFor.DELETE_REPORT_GROUP,
      [],
    );

    updateDialogueState('OPEN');

    setDeleteReportId(deleteGroupId);
  };

  const openDialogForEditGroup = (group: any, groupId: string) => {
    setGroupIdForUpdate(groupId);
    const updatedFields = REPORT_GROUP_INITIAL_FIELDS.map((field) => {
      if (field.id === 'title') {
        field.value = group.title;
      }

      return field;
    });

    updateDialogMetaData(
      DIALOG_TITLE_FOR_UPDATE_REPORT_GROUP,
      DIALOG_CONTENT_FOR_UPDATE_REPORT_GROUP,
      DialogFor.REPORT_GROUP,
      updatedFields,
    );

    updateDialogueState('OPEN');
  };

  const deleteReportGroup = async (groupId: string) => {
    const {
      success: deleteReportGroupSuccess,
      message: deleteReportGroupErrorMessage,
    } = await deleteReportGroupById(groupId);

    if (!deleteReportGroupSuccess && deleteReportGroupErrorMessage) {
      notify(deleteReportGroupErrorMessage);
    } else {
      notify(DELETE_GROUP_SUCCESS);
      fetchReportGroups();
    }
  };

  const fetchReportGroups = useCallback(async () => {
    if (!userDetails) return;
    const { organizationId } = userDetails;

    setLoadingForDataGrid(true);

    try {
      const {
        success: getReportGroupsSuccess,
        content: reportGroups,
        message: getReportGroupsErrorMessage,
      } = await getAllReportGroups(
        organizationId.toString(),
        paginationModel.page,
        paginationModel.pageSize,
      );

      if (!getReportGroupsSuccess && getReportGroupsErrorMessage) {
        notify(UNHANDLED_ERROR);

        setLoadingForDataGrid(false);

        setReportGroups([]);

        return;
      }

      if (reportGroups && reportGroups.data) {
        const updatedContent = reportGroups.data.map((reportGroup) => ({
          id: reportGroup._id,
          ...reportGroup,
        }));

        setRowCountState(reportGroups?.totalCount);
        setReportGroups(updatedContent);
      }
    } catch (error) {
      notify(UNHANDLED_ERROR);
      setReportGroups([]);
    } finally {
      setLoadingForDataGrid(false);
    }
  }, [paginationModel.page, paginationModel.pageSize, notify, userDetails]);

  const validateReportGroupFormFields = async (body: CreateGroupProp) => {
    if (!userDetails) return;
    const { organizationId } = userDetails;

    for (const field of dialogFormFields) {
      if (field.id === 'title') {
        const { success: checkNameAvailSuccess, content: nameAvailability } =
          await checkNameAvailability(
            {
              title: field.value,
            },
            organizationId.toString(),
          );

        if (!checkNameAvailSuccess) {
          notify(UNHANDLED_ERROR);

          return null;
        }

        const isValidated = validateTextInSnakeCase(field.value);

        if (!isValidated) {
          notify(SNAKE_CASE_VALIDATION_ERROR);

          return null;
        }

        if (!nameAvailability) {
          notify(GROUP_NAME_ALREADY_TAKEN_ERROR);

          return null;
        }

        body[field.id] = field.value;
      }
    }

    return body;
  };

  const createReportGroup = async () => {
    updateDialogueState('LOADING');

    let body: CreateGroupProp = {
      createdBy: '',
      title: '',
    };
    const updatedReportGroup = await validateReportGroupFormFields(body);

    if (!updatedReportGroup || !userDetails) return;
    const { _id: mongoUserId, organizationId } = userDetails;

    body = updatedReportGroup;

    body['createdBy'] = mongoUserId.toString();

    if (!groupIdForUpdate) {
      // Create a new Group
      const { success: createGroupSuccess, message: createGroupErrorMessage } =
        await createGroup(body, organizationId.toString());

      if (!createGroupSuccess && createGroupErrorMessage) {
        notify(createGroupErrorMessage);
      } else {
        notify(CREATE_GROUP_SUCCESS);
        fetchReportGroups();
      }
    } else {
      // Update a Group
      const { success: updateGroupSuccess, message: updateGroupErrorMessage } =
        await updateGroupByGroupId(
          groupIdForUpdate,
          body,
          organizationId.toString(),
          mongoUserId.toString(),
        );

      if (!updateGroupSuccess && updateGroupErrorMessage) {
        notify(updateGroupErrorMessage);
      } else {
        notify(UPDATE_GROUP_SUCCESS);
        fetchReportGroups();
      }
    }

    updateDialogueState('CLOSED');
  };

  useEffect(() => {
    fetchReportGroups();
  }, [fetchReportGroups]);

  const confirmDeleteReportGroup = async () => {
    if (deleteReportId) {
      updateDialogueState('LOADING');

      await deleteReportGroup(deleteReportId);
      setDeleteReportId(null);

      updateDialogueState('CLOSED');
    }
  };

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.REPORT_GROUP:
        createReportGroup();
        break;

      case DialogFor.DELETE_REPORT_GROUP:
        confirmDeleteReportGroup();
        break;
      default:
    }
  }, [dialogueState]);

  const reportGroupColumns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Description',
      width: DATA_GRID_COLUMN_WIDTH_6,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Typography
          component="p"
          variant="body1"
          sx={{ maxWidth: 400, margin: '16px 0' }}
        >
          {truncateString(row.title, WORDS_PER_ROW_CELL)}
        </Typography>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: DATA_GRID_COLUMN_WIDTH_3,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Typography component="p" variant="body1">
            {truncateString(row?.createdBy?.displayName, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      width: DATA_GRID_COLUMN_WIDTH_3,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Typography component="p" variant="body1">
            {new Date(row.createdAt).toLocaleDateString()}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: DATA_GRID_COLUMN_WIDTH_7,
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              navigate(
                `${PATHS.reportGroups}/report-message/groups/${params.row._id}`,
              );
            }}
          >
            View
          </Button>
          <Button
            id="edit-report-group"
            variant="contained"
            color="primary"
            disabled={
              userDetails?.role === ROLES.ADMIN
                ? false
                : params.row.createdBy._id.toString() !==
                  userDetails?._id.toString()
            }
            size="small"
            onClick={() => {
              openDialogForEditGroup(params.row, params.row._id);
            }}
          >
            Edit
          </Button>
          <Button
            id="delete-report-group"
            variant="contained"
            color="secondary"
            disabled={
              userDetails?.role === ROLES.ADMIN
                ? false
                : params.row.createdBy._id.toString() !==
                  userDetails?._id.toString()
            }
            size="small"
            onClick={() => {
              openDialogForDeleteGroup(params.row._id);
            }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box component="main">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginBottom: '16px',
        }}
      >
        <Button
          id="create-report-group"
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialogForCreateGroup}
        >
          {CREATE_REPORT_GROUP_BUTTON_TEXT}
        </Button>
      </Box>

      <Box>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight
          paginationMode="server"
          rows={reportGroups}
          columns={reportGroupColumns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          disableColumnMenu
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          loading={loadingForDataGrid}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paginationModel.page - 1,
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          rowCount={rowCountState}
          onPaginationModelChange={(newPaginationModel) => {
            setPaginationModel((oldPaginationModel) => ({
              ...oldPaginationModel,
              page: newPaginationModel.page + 1,
              pageSize: newPaginationModel.pageSize,
            }));
          }}
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
        />
      </Box>
    </Box>
  );
});

export default ReportGroupPage;
