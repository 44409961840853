import { Box, Button } from '@mui/material';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { observer } from 'mobx-react-lite';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import { FormField } from 'src/components/custom-dialog/CustomDialog';
import {
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORG_BUTTON_TEXT,
  DELETE_USER_BUTTON_TEXT,
  DELETE_USER_SUCCESS,
  DIALOG_CONTENT_FOR_DELETE_ORG,
  DIALOG_CONTENT_FOR_DELETE_USER,
  DIALOG_CONTENT_FOR_ORGANIZATION_DELETE,
  DIALOG_CONTENT_FOR_USER_DELETE,
  DIALOG_TITLE_FOR_DELETE,
  DIALOG_TITLE_FOR_DELETE_ORG,
  DIALOG_TITLE_FOR_DELETE_USER,
  SMALL_DIALOG_TEXTFIELD_ROWS,
} from 'src/constants/dialogMetaInfo';
import { STYLE_FOR_LARGE_DIALOG } from 'src/constants/Layout';
import { useEffect, useState } from 'react';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  deleteOrganizationById,
  validateOrganizationId,
} from 'src/services/organization';
import { deleteUsersByIds, validateMongoUserId } from 'src/services/user';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';

const DELETE_ORG_INITIAL_FIELDS: FormField[] = [
  {
    label: 'Organization Id',
    id: 'orgId',
    value: '',
    type: 'text',
    fieldHeading: 'organization Id',
  },
];

const DELETE_USER_INITIAL_FIELDS: FormField[] = [
  {
    label: 'User Id',
    id: 'userId',
    value: '',
    type: 'text',
    fieldHeading: 'user Id',
  },
];

const UserDeleteRequest = observer(() => {
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { notify } = useAlertStore();
  const [deleteOrgId, setDeleteOrgId] = useState<string | null>(null);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);

  const {
    dialogueState,
    dialogType,
    updateDialogueState,
    dialogFormFields,
    updateDialogMetaData,
  } = useDialogStore();

  const openDialogForDeleteOrganization = () => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE_ORG,
      DIALOG_CONTENT_FOR_DELETE_ORG,
      DialogFor.DELETE_ORGANIZATION,
      DELETE_ORG_INITIAL_FIELDS,
      SMALL_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const openDialogForConfirmDeleteOrganization = async () => {
    let orgId = null;

    for (const field of dialogFormFields) {
      if (field.id === 'orgId') {
        orgId = field.value;
      }
    }

    if (!orgId) {
      notify('Please enter a value for the organization ID.');
      updateDialogueState('OPEN');

      return;
    }

    updateDialogueState('LOADING');

    const {
      success: validateOrganizationIdSuccess,
      content: isValidOrganizationId,
    } = await validateOrganizationId(orgId);

    if (!validateOrganizationIdSuccess || !isValidOrganizationId) {
      notify('Invalid organization ID.');
      updateDialogueState('OPEN');

      return;
    }

    setDeleteOrgId(orgId);

    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_ORGANIZATION_DELETE,
      DialogFor.CONFIRM_DELETE_ORGANIZATION,
      [],
    );

    updateDialogueState('OPEN');
  };

  const deleteOrganization = async () => {
    updateDialogueState('LOADING');

    if (!deleteOrgId || !userDetails?._id) {
      notify(UNHANDLED_ERROR);
      updateDialogueState('OPEN');

      return;
    }

    const { success: deleteOrgSuccess, content: deletedOrg } =
      await deleteOrganizationById(deleteOrgId, userDetails._id.toString());

    if (!deleteOrgSuccess || !deletedOrg) {
      notify(UNHANDLED_ERROR);
      updateDialogueState('OPEN');

      return;
    }

    setDeleteOrgId(null);
    notify(DELETE_ORGANIZATION_SUCCESS);
    updateDialogueState('CLOSED');
  };

  const openDialogForDeleteUser = async () => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE_USER,
      DIALOG_CONTENT_FOR_DELETE_USER,
      DialogFor.DELETE_USER,
      DELETE_USER_INITIAL_FIELDS,
      SMALL_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const openDialogForConfirmDeleteUser = async () => {
    let userId = null;

    for (const field of dialogFormFields) {
      if (field.id === 'userId') {
        userId = field.value;
      }
    }

    if (!userId) {
      notify('Please enter a value for the user ID.');
      updateDialogueState('OPEN');

      return;
    }

    updateDialogueState('LOADING');

    const { success: validateMongoUserIdSuccess, content: isValidMongoUserId } =
      await validateMongoUserId(userId);

    if (!validateMongoUserIdSuccess || !isValidMongoUserId) {
      notify('Invalid user ID.');
      updateDialogueState('OPEN');

      return;
    }

    setDeleteUserId(userId);

    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_USER_DELETE,
      DialogFor.CONFIRM_DELETE_USER,
      [],
    );

    updateDialogueState('OPEN');
  };

  const deleteUser = async () => {
    updateDialogueState('LOADING');

    if (!deleteUserId || !userDetails?._id) {
      notify(UNHANDLED_ERROR);
      updateDialogueState('OPEN');

      return;
    }

    const { success: deleteUserSuccess, content: deletedUser } =
      await deleteUsersByIds({
        userIds: [deleteUserId],
        deletedBy: userDetails._id.toString(),
      });

    if (!deleteUserSuccess || !deletedUser) {
      notify(UNHANDLED_ERROR);
      updateDialogueState('OPEN');

      return;
    }

    setDeleteUserId(null);
    notify(DELETE_USER_SUCCESS);
    updateDialogueState('CLOSED');
  };

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.DELETE_ORGANIZATION:
        openDialogForConfirmDeleteOrganization();
        break;
      case DialogFor.CONFIRM_DELETE_ORGANIZATION:
        deleteOrganization();
        break;
      case DialogFor.DELETE_USER:
        openDialogForConfirmDeleteUser();
        break;
      case DialogFor.CONFIRM_DELETE_USER:
        deleteUser();
        break;
      default:
    }
  }, [dialogueState]);

  return (
    <Box
      component="main"
      sx={{
        marginTop: '16px',
      }}
    >
      <ModuleHeader title="User Delete Request" />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          marginBottom: '16px',
        }}
      >
        <Button
          id="delete-org"
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialogForDeleteOrganization}
        >
          {DELETE_ORG_BUTTON_TEXT}
        </Button>
        <Button
          id="delete-user"
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialogForDeleteUser}
        >
          {DELETE_USER_BUTTON_TEXT}
        </Button>
      </Box>
    </Box>
  );
});

export default UserDeleteRequest;
