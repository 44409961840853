import { Divider, DividerProps } from '@mui/material';
import styleVariables from '../../scss/_variables.scss';

export const CustomDivider = (props: DividerProps) => {
  return (
    <Divider
      {...props}
      sx={{
        bgcolor: styleVariables.mutedGray,
        ...(props.sx ? props.sx : {}),
      }}
    />
  );
};
