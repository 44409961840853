import React from 'react';
import { PLAN_TYPES } from 'src/constants/cardsEnum';
import { Avatar, SxProps, Theme } from '@mui/material';
import FREE_PLAN_PERSONA_IMG from '../../assets/free-plan-persona.png';
import BASIC_PLAN_PERSONA_IMG from '../../assets/basic-plan-persona.png';
import PRO_PLAN_PERSONA_IMG from '../../assets/pro-plan-persona.png';

interface PersonaByPlanProps {
  currentPlan: string;
  sx?: SxProps<Theme>;
}

export const PersonaByPlan: React.FC<PersonaByPlanProps> = ({
  currentPlan,
  sx,
}) => {
  let imgSrc;

  switch (currentPlan) {
    case PLAN_TYPES.FREE:
      imgSrc = FREE_PLAN_PERSONA_IMG;
      break;
    case PLAN_TYPES.BASIC:
      imgSrc = BASIC_PLAN_PERSONA_IMG;
      break;
    case PLAN_TYPES.PRO:
      imgSrc = PRO_PLAN_PERSONA_IMG;
      break;
    default:
      imgSrc = '';
      break;
  }

  return <Avatar src={imgSrc} alt={currentPlan} sx={{ ...sx }} />;
};

export default PersonaByPlan;
