import { SUPPORT_EMAIL } from './Developer';

export const MUI_DRAWER_OVERRIDING_Z_INDEX = 1201;
// https://www.geeksforgeeks.org/react-mui-z-index/

export const EVENT_SOURCE_CONNECTION_RETRY_DELAY = 5000;
export const LEMONSQUEEZY_PAYMENT_LOADER_TEXT =
  'Payment processing. This might take up to 3 minutes.';

export const LEMONSQUEEZY_INITIAL_CHECKOUT_MAX_TIMEOUT = 3 * 60 * 1000; // in ms

export const LEMONSQUEEZY_BY_DEFAULT_RECURRING_INTERVAL = 'month';

export const LEMONSQUEEZY_PAYMENT_GUIDE_FOR_USERS = [
  {
    heading: `Don't refresh the page:`,
    description: `If you're subscribing or changing your plan, do not refresh the page during the process.`,
  },
  {
    heading: 'Pop-up Blocker Issue:',
    description:
      "If you see a pop-up blocker notification during the checkout process, please allow pop-ups for the first time. This is a one-time process, and you won't need to do it again for future transactions.",
  },
  {
    heading: 'Support:',
    description: `If you encounter any issues during the payment process, please contact our support team for assistance at ${SUPPORT_EMAIL}.`,
  },
];

export const SUBSCRIPTION_UPDATE_MESSAGE =
  'Your subscription was updated successfully.';
export const SUBSCRIPTION_CREATE_MESSAGE =
  'Your subscription was created successfully.';
export const SUBSCRIPTION_RESUME_MESSAGE =
  'Your subscription was resumed successfully.';

export const LEMONSQUEEZY_POLLING_INTERVAL = 5000; //in ms

export const CANCELLED_PLAN_NOTIFICATION_MESSAGE =
  'You already cancelled the plan. Paid plans continue until the end of the paid billing cycle.';

export const PAID_PLAN_CANCELLED_MESSAGE =
  'Paid plan cancelled successfully. The existing subscription will continue until the end of the paid billing cycle.';

export const PAID_PLAN_CANCELLED_MESSAGE_IN_PAST_DUE =
  'Paid plan cancelled successfully.';

export const LEMONSQUEEZY_PAYMENT_FAILURE_MESSAGE =
  'Something went wrong during the payment process. Additional steps might be needed to complete the payment. Please check your email inbox for any instructions from Lemonsqueezy.';

export const LEMONSQUEEZY_INITIAL_CHECKOUT_FAILURE_MESSAGE =
  'We did not receive payment updates from LemonSqueezy. If you have already made the payment, please try closing this message. If you have not made the payment, please retry the checkout process.';
