import { Box, Button, Typography, Switch } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import {
  createMemory,
  deleteMemoryById,
  getMemoryById,
  getMemoryListByOrgId,
  updateMemoryById,
} from '../../services/memory';
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { observer } from 'mobx-react-lite';
import {
  DIALOG_CONTENT_FOR_MEMORY,
  DIALOG_TITLE_FOR_MEMORY,
  DIALOG_TITLE_FOR_UPDATE_MEMORY,
  DIALOG_CONTENT_FOR_UPDATE_MEMORY,
  DIALOG_TITLE_FOR_DELETE,
  DIALOG_CONTENT_FOR_MEMORY_DELETE,
  CREATE_MEMORY_SUCCESS,
  UPDATE_MEMORY_SUCCESS,
  DELETE_MEMORY_SUCCESS,
  CREATE_MEMORY_BUTTON_TEXT,
  DIALOG_CONTENT_MAX_LENGTH,
  LARGE_DIALOG_TEXTFIELD_ROWS,
} from 'src/constants/dialogMetaInfo';
import { CreateMemory, Memory, MemoryApiResponse } from 'src/types/memory';
import { useAlertStore } from '../../stores/AlertStore/AlertStore';
import {
  MEMORY_NOT_FOUND_BY_ID,
  NOT_AUTHORIZED_MESSAGE,
  UNHANDLED_ERROR,
} from 'src/constants/errors';
import { ERROR_STATUS_CODE } from 'src/constants/axiosInstance';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import { STYLE_FOR_LARGE_DIALOG } from 'src/constants/Layout';
import TablePaginationActions from 'src/constants/TablePaginationActions';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import { ROLES } from 'src/constants/Role';
import {
  PAGE_SIZE_OPTIONS,
  WORDS_PER_ROW_CELL,
  truncateString,
} from 'src/constants/utils';
import { FormField } from 'src/components/custom-dialog/CustomDialog';
import { useSearchParams } from 'react-router-dom';
import { DATA_GRID_MEMORY_SEARCH_DEBOUNCE } from 'src/constants/memory';

const MEMORY_INITIAL_FIELDS: FormField[] = [
  {
    label: 'Memory Context Description',
    id: 'memoryDescription',
    value: '',
    type: 'textarea',
    maxLength: DIALOG_CONTENT_MAX_LENGTH,
  },
];

const GLOBAL_MEMORY_CUD_ACCESS: Array<keyof typeof ROLES> = [ROLES.SUPER_ADMIN];

const MemoryList = observer(() => {
  const [rememberContexts, setRememberContexts] = useState<Memory[]>([]);
  const [deleteMemoryId, setDeleteMemoryId] = useState<string | null>(null);
  const [memoryIdForUpdate, setMemoryIdForUpdate] = useState<string | null>(
    null,
  );
  const [loadingForDataGrid, setLoadingForDataGrid] = useState(false);
  const [isUserAuthorized, setIsUserAuthorized] = useState(true);
  const [rowCountState, setRowCountState] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });
  const [memorySearchQuery, setMemorySearchQuery] = useState<string | null>(
    null,
  );

  const [searchParams] = useSearchParams();

  const {
    dialogueState,
    dialogType,
    updateDialogueState,
    dialogFormFields,
    updateDialogMetaData,
    resetFormFieldsValue,
  } = useDialogStore();

  const { notify } = useAlertStore();

  const { getUserDetails, logout } = useAuthStore();

  const userDetails = getUserDetails();

  const handleFetchMemoryContext = useCallback(async () => {
    if (!userDetails) return;

    const { organizationId } = userDetails;

    setLoadingForDataGrid(true);

    try {
      const {
        success: getMemorySuccess,
        content: memories,
        statusCode,
      } = await getMemoryListByOrgId(
        organizationId.toString(),
        paginationModel.page,
        paginationModel.pageSize,
        memorySearchQuery,
      );

      if (!getMemorySuccess || !memories) {
        if (statusCode !== ERROR_STATUS_CODE.FORBIDDEN) {
          notify(UNHANDLED_ERROR);
        }

        setIsUserAuthorized(
          statusCode === ERROR_STATUS_CODE.FORBIDDEN ? false : true,
        );

        setLoadingForDataGrid(false);

        setRememberContexts([]);

        return;
      }

      if (getMemorySuccess) {
        setIsUserAuthorized(true);
        const updatedContent = memories.data.map((memory) => {
          const updatedMemory = { ...memory, id: memory._id };

          return updatedMemory;
        });

        setRowCountState(memories.totalCount);
        setRememberContexts(updatedContent);
      }
    } catch (error) {
      notify(UNHANDLED_ERROR);
      setRememberContexts([]);
    } finally {
      setLoadingForDataGrid(false);
    }
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    notify,
    userDetails,
    memorySearchQuery,
  ]);

  const handleCreateOrUpdateMemory = async () => {
    if (!userDetails) return;

    updateDialogueState('LOADING');

    const { _id: mongoUserId, organizationId } = userDetails;
    const body: CreateMemory = {
      organizationId: organizationId.toString(),
      text: '',
      userId: mongoUserId.toString(),
    };

    for (const field of dialogFormFields) {
      if (field.id === 'memoryDescription') {
        body.text = field.value;
      }
    }

    if (!memoryIdForUpdate) {
      // create memory
      const { success: createMemorySuccess, content: memory } =
        await createMemory(body);

      if (!createMemorySuccess || !memory) {
        notify(UNHANDLED_ERROR);

        return;
      }

      notify(CREATE_MEMORY_SUCCESS);
    } else {
      // update memory
      const { success: updateMemorySuccess, content: updatedMemory } =
        await updateMemoryById(memoryIdForUpdate, body);

      if (!updateMemorySuccess || !updatedMemory) {
        notify(UNHANDLED_ERROR);

        return;
      }

      notify(UPDATE_MEMORY_SUCCESS);
    }

    updateDialogueState('CLOSED');

    handleFetchMemoryContext();
  };

  const confirmDeleteMemory = async () => {
    if (deleteMemoryId) {
      updateDialogueState('LOADING');

      const { success: deleteMemorySuccess, content: deletedMemory } =
        await deleteMemoryById(deleteMemoryId);

      if (!deleteMemorySuccess || !deletedMemory) {
        notify(UNHANDLED_ERROR);

        return;
      }

      notify(DELETE_MEMORY_SUCCESS);
      updateDialogueState('CLOSED');
      setDeleteMemoryId(null);
      handleFetchMemoryContext();
    }
  };

  const openDialogForCreateMemory = () => {
    setMemoryIdForUpdate(null);
    updateDialogMetaData(
      DIALOG_TITLE_FOR_MEMORY,
      DIALOG_CONTENT_FOR_MEMORY,
      DialogFor.MEMORY,
      resetFormFieldsValue(MEMORY_INITIAL_FIELDS),
      LARGE_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const openDialogForEditMemory = (
    memoryDetails: MemoryApiResponse,
    memoryId: string,
  ) => {
    setMemoryIdForUpdate(memoryId);
    const updatedFields = MEMORY_INITIAL_FIELDS.map((field) => {
      if (field.id === 'memoryDescription' && memoryDetails.text) {
        field.value = memoryDetails.text;
      }

      return field;
    });

    updateDialogMetaData(
      DIALOG_TITLE_FOR_UPDATE_MEMORY,
      DIALOG_CONTENT_FOR_UPDATE_MEMORY,
      DialogFor.MEMORY,
      updatedFields,
      LARGE_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const openDialogForDeleteGroup = (deleteMemoryId: string) => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_MEMORY_DELETE,
      DialogFor.DELETE_MEMORY,
      [],
    );

    updateDialogueState('OPEN');

    setDeleteMemoryId(deleteMemoryId);
  };

  const fetchMemoryById = async () => {
    const memoryId = searchParams.get('id');

    if (!memoryId) return;

    try {
      const { success: getMemorySuccess, content: memory } =
        await getMemoryById(memoryId);

      if (!getMemorySuccess || !memory) {
        notify(MEMORY_NOT_FOUND_BY_ID);

        return;
      }

      openDialogForEditMemory(memory, memoryId);
    } catch (error) {
      notify(UNHANDLED_ERROR);
    }
  };

  const handleGlobalMemoryChange = async (
    memoryDetails: Memory,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      !memoryDetails?.id ||
      !userDetails?.organizationId ||
      !userDetails?._id
    ) {
      notify(UNHANDLED_ERROR);

      return;
    }

    setLoadingForDataGrid(true);

    const { success: updateMemorySuccess, content: updatedMemory } =
      await updateMemoryById(memoryDetails.id, {
        isGlobal: event.target.checked,
        organizationId: userDetails.organizationId.toString(),
        userId: userDetails._id.toString(),
      });

    if (!updateMemorySuccess || !updatedMemory) {
      notify(UNHANDLED_ERROR);
      setLoadingForDataGrid(false);

      return;
    }

    await handleFetchMemoryContext();
    setLoadingForDataGrid(false);
    notify(UPDATE_MEMORY_SUCCESS);
  };

  useEffect(() => {
    handleFetchMemoryContext();
  }, [handleFetchMemoryContext]);

  useEffect(() => {
    fetchMemoryById();
  }, []);

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.MEMORY:
        handleCreateOrUpdateMemory();
        break;

      case DialogFor.DELETE_MEMORY:
        confirmDeleteMemory();
        break;
      default:
    }
  }, [dialogueState]);

  useEffect(() => {
    if (!userDetails) {
      logout();
    }
  }, [userDetails, logout]);

  if (!userDetails) {
    return <ErrorBoundary message={UNHANDLED_ERROR} />;
  }

  if (
    (userDetails.role !== ROLES.SUPER_ADMIN &&
      userDetails.role !== ROLES.ADMIN &&
      userDetails.role !== ROLES.INDIVIDUAL_USER_ADMIN) ||
    !isUserAuthorized
  ) {
    return (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="p" variant="h6">
          {NOT_AUTHORIZED_MESSAGE}
        </Typography>
      </Box>
    );
  }

  const memorySearchToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  const memorySearchOperators = getGridStringOperators().filter(
    (operator) => operator.value === 'contains',
  );

  const handleMemorySearch = (model: GridFilterModel) => {
    const memorySearchItem = model.items.find(({ field }) => field === 'text');

    if (memorySearchItem && memorySearchItem.value) {
      setMemorySearchQuery(memorySearchItem.value);
    } else {
      setMemorySearchQuery(null);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'text',
      headerName: 'Memory',
      width: 450,
      filterable: true,
      filterOperators: memorySearchOperators,
      renderCell: (index) => {
        return (
          <Typography
            component="p"
            variant="body1"
            sx={{ maxWidth: 400, margin: '16px 0' }}
          >
            {truncateString(index.row.text, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      filterable: false,
      width: 140,
      renderCell: (index) => {
        return (
          <Typography component="p" variant="body1" sx={{ margin: '16px 0' }}>
            {truncateString(
              index?.row?.userId?.displayName,
              WORDS_PER_ROW_CELL,
            )}
          </Typography>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      filterable: false,
      width: 140,
      renderCell: (index) => {
        return (
          <Typography component="p" variant="body1">
            {new Date(index.row.createdAt).toLocaleDateString()}
          </Typography>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      filterable: false,
      width: 140,
      renderCell: (index) => {
        return (
          <Typography component="p" variant="body1">
            {new Date(index.row.updatedAt).toLocaleDateString()}
          </Typography>
        );
      },
    },
    {
      field: 'globalMemory',
      headerName: 'Global memory',
      filterable: false,
      width: 140,
      renderCell: (params: GridRenderCellParams<Memory>) => (
        <Switch
          id="global-memory"
          disabled={!GLOBAL_MEMORY_CUD_ACCESS.includes(userDetails.role)}
          checked={params.row?.isGlobal ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleGlobalMemoryChange(params.row, event)
          }
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 170,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <Button
            id="edit-memory"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => openDialogForEditMemory(params.row, params.row._id)}
          >
            Edit
          </Button>
          <Button
            id="delete-memory"
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => openDialogForDeleteGroup(params.row._id)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box component="main">
      <ModuleHeader title="Memory" />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginBottom: '16px',
        }}
      >
        <Button
          id="create-memory"
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialogForCreateMemory}
        >
          {CREATE_MEMORY_BUTTON_TEXT}
        </Button>
      </Box>
      <Box>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight
          paginationMode="server"
          rows={rememberContexts}
          slots={{
            toolbar: memorySearchToolbar,
          }}
          filterMode="server"
          filterDebounceMs={DATA_GRID_MEMORY_SEARCH_DEBOUNCE}
          onFilterModelChange={handleMemorySearch}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          disableColumnMenu
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          loading={loadingForDataGrid}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paginationModel.page - 1,
                pageSize: paginationModel.pageSize,
              },
            },
            columns: {
              columnVisibilityModel: {
                globalMemory: GLOBAL_MEMORY_CUD_ACCESS.includes(
                  userDetails.role,
                ),
              },
            },
          }}
          rowCount={rowCountState}
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
          onPaginationModelChange={(newPaginationModel) => {
            setPaginationModel((oldPaginationModel) => ({
              ...oldPaginationModel,
              page: newPaginationModel.page + 1,
              pageSize: newPaginationModel.pageSize,
            }));
          }}
        />
      </Box>
    </Box>
  );
});

export default MemoryList;
