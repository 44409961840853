import { makeAutoObservable } from 'mobx';
import createStoreContext from '../createStoreContext';

export const [PaymentStoreProvider, usePaymentStore] = createStoreContext(
  class PaymentStore {
    isPaymentFlowStarted = false;

    public constructor() {
      makeAutoObservable(this);
    }

    startPaymentFlow = () => {
      this.isPaymentFlowStarted = true;
    };

    completePaymentFlow = () => {
      this.isPaymentFlowStarted = false;
    };
  },
);

export type GlobalStore = ReturnType<typeof usePaymentStore>;
