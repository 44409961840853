import { Button, Box, Typography, Dialog } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { USER_DETAILS_NOT_FOUND_ERROR } from 'src/constants/errors';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import ErrorBoundary from '../error-boundary/ErrorBoundry';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import variableStyles from 'src/scss/_variables.scss';
import {
  customEventTracking,
  CustomEventTrackingPayload,
} from 'src/services/customEventTracking';

interface UpgradePopupProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const UpgradePopup: React.FC<UpgradePopupProps> = observer(
  ({ isOpen, setIsOpen }) => {
    const { getUserDetails } = useAuthStore();
    const userDetails = getUserDetails();
    const navigate = useNavigate();
    const location = useLocation();
    const trimmedPath = location.pathname.substring(1);

    const handleUpgrade = async () => {
      if (userDetails) {
        const body: CustomEventTrackingPayload = {
          userId: userDetails?._id,
          orgId: userDetails?.organizationId,
          eventName: `${trimmedPath}_upgrade_click`,
        };

        customEventTracking(body);
      }

      navigate(PATHS.pricingPlans);
    };

    if (!userDetails) {
      return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;
    }

    return (
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '24px',
            borderRadius: '8px',
            gap: 2,
            textAlign: 'center',
            maxWidth: '400px',
            margin: 'auto',
            backgroundColor: variableStyles.midnightIndigo,
          }}
        >
          <Typography
            variant="body1"
            style={{
              marginTop: '16px',
              lineHeight: 1.5,
            }}
          >
            To unlock this premium feature, please upgrade your plan.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleUpgrade}
            style={{
              padding: '4px 12px',
              fontSize: '16px',
            }}
          >
            Upgrade Now
          </Button>
        </Box>
      </Dialog>
    );
  },
);

export default UpgradePopup;
