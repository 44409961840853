import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box,
  CssBaseline,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SideNavBar from './components/side-nav-bar/SideNavBar';
import CustomAppBar from './components/custom-app-bar/CustomAppBar';
import { Outlet, useSearchParams } from 'react-router-dom';
import styleVariables from './scss/_variables.scss';
import styles from './scss/App.module.scss';
import { Footer } from './components/footer/Footer';
import { useEffect, useState } from 'react';
import DnsVerification from 'src/components/dns-verification/DnsVerification';
import { observer } from 'mobx-react-lite';
import { checkDnsVerification } from 'src/services/organization';
import { ROLES } from 'src/constants/Role';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import { UNHANDLED_ERROR } from './constants/errors';
import OnboardingPopup from 'src/components/onboarding-popup/OnboardingPopup';
import { checkIfUserInteractedWithHanaByUserId } from './services/overview';
import { FREE_PRO_PLAN_MESSAGE } from './constants/Announcements';
import Announcement from './components/announcement/Announcement';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const App = observer(() => {
  const [isSideNavBarOpen, setIsSideNavBarOpen] = useState(false); // for small screens
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [openDialogForDnsVerification, setOpenDialogForDnsVerification] =
    useState(false);
  const [openDialogForIntegratingHana, setOpenDialogForIntegratingHana] =
    useState(false);
  const { notify } = useAlertStore();
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { organizationDetails } = useOrganizationStore();
  const [searchParams] = useSearchParams();

  const checkDnsVerifyCalledByUser = async () => {
    if (!organizationDetails || !userDetails) {
      notify(UNHANDLED_ERROR);

      return;
    }

    if (
      userDetails.role === ROLES.USER ||
      userDetails.role === ROLES.ADMIN ||
      userDetails.role === ROLES.SUPER_ADMIN
    ) {
      const dnsVerify = searchParams.get('dns-verify');
      const {
        success: checkDnsVerificationSuccess,
        content: dnsVerificationStatus,
      } = await checkDnsVerification(organizationDetails._id.toString());

      if (!checkDnsVerificationSuccess || !dnsVerificationStatus) {
        notify(UNHANDLED_ERROR);

        return;
      }

      if (
        dnsVerify &&
        !dnsVerificationStatus.isDnsVerified &&
        userDetails.role === ROLES.USER
      ) {
        setOpenDialogForDnsVerification(true);
      } else {
        setOpenDialogForDnsVerification(false);
      }
    }
  };

  useEffect(() => {
    checkDnsVerifyCalledByUser();
    checkIfUserInteractedWithHana();
  }, [searchParams]);

  const checkIfUserInteractedWithHana = async () => {
    try {
      const hasVisitedHanaInteractionPopupInCurrentSession =
        sessionStorage.getItem('hasVisitedHanaInteractionPopup');

      // Let's assume if the user manually enters the dns-verify parameter for the first time, we don't need to show the pop-up at that time. Later, it will show automatically based on session data.
      const dnsVerify = searchParams.get('dns-verify');

      if (hasVisitedHanaInteractionPopupInCurrentSession || dnsVerify) {
        return;
      }

      if (!userDetails) {
        notify(UNHANDLED_ERROR);

        return;
      }

      const { success, content } = await checkIfUserInteractedWithHanaByUserId(
        userDetails?._id,
      );

      if (!success || !content) {
        notify(UNHANDLED_ERROR);

        return;
      }

      if (!content.hasInteracted) {
        setOpenDialogForIntegratingHana(true);
        sessionStorage.setItem('hasVisitedHanaInteractionPopup', 'true');
      }
    } catch (error) {
      notify(UNHANDLED_ERROR);
    }
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>
      <CssBaseline />
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'grid',
            width: isLargeScreen ? styleVariables.appDrawerWidth : 0,
          },
        }} // Responsiveness
        className={styles['nav-bar-container']}
      >
        <SideNavBar open={isSideNavBarOpen} onChange={setIsSideNavBarOpen} />
      </Box>
      <Box
        sx={{ bgcolor: styleVariables.midnightIndigo }}
        className={styles['module-content-container']}
      >
        <Announcement
          texts={[FREE_PRO_PLAN_MESSAGE]}
          icon={<CloseOutlinedIcon sx={{ color: styles.darkNavyBlue }} />}
        />
        <CustomAppBar setIsSideNavBarOpen={setIsSideNavBarOpen} />
        <Paper
          className="hanabi-scrollbar"
          variant="outlined"
          component="main"
          sx={{
            overflowX: 'hidden',
            overflowY: 'auto',
            borderRadius: '16px',
            margin: '16px',
            bgcolor: styleVariables.darkNavyBlue,
            padding: { xs: '16px', lg: '32px' },
            height: '100%',
          }}
        >
          <Outlet />
        </Paper>
        <Footer />
      </Box>
      {openDialogForDnsVerification && (
        <DnsVerification
          isOpen={openDialogForDnsVerification}
          setIsOpen={setOpenDialogForDnsVerification}
        />
      )}
      {openDialogForIntegratingHana && (
        <OnboardingPopup
          openDialog={openDialogForIntegratingHana}
          setModal={setOpenDialogForIntegratingHana}
        />
      )}
    </Box>
  );
});

export default App;
