import { UNHANDLED_ERROR } from 'src/constants/errors';
import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { ApiResponse } from 'src/types/commonResponseTypes';
import {
  DnsVerificationApiResponse,
  IsDnsVerified,
  DnsVerificationPayload,
} from 'src/types/dnsVerification';

export const createDnsVerificationCode = async (
  body: DnsVerificationPayload,
): Promise<ApiResponse<DnsVerificationApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['createDnsVerificationCode']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const findDnsVerificationDetailsByOrgIdAndMongoUserId = async (
  orgId: string,
  mongoUserId: string,
): Promise<ApiResponse<DnsVerificationApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS[
      'findDnsVerificationDetailsByOrgIdAndMongoUserId'
    ](orgId, mongoUserId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const verifyDnsByOrgIdAndUserId = async (
  body: DnsVerificationPayload,
): Promise<ApiResponse<IsDnsVerified>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['verifyDnsByOrgIdAndUserId']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};
