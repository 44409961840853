import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import {
  DELETE_USER_SUCCESS,
  DIALOG_CONTENT_FOR_USER_DELETE,
  DIALOG_MESSAGE_UPDATE_ROLE,
  DIALOG_TITLE_FOR_DELETE,
  DIALOG_TITLE_REMOVE_ACCESS,
  DIALOG_TITLE_UPDATE_ROLE,
  UPDATE_USER_ACCESS,
  UPDATE_USER_ROLE,
  dialogMessageGrantAccess,
  dialogMessageRemoveAccess,
} from 'src/constants/dialogMetaInfo';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import {
  deleteUsersByIds,
  getAllUsers,
  updateUserActiveStatus,
  updateUserRole,
} from 'src/services/user';
import {
  UpdateUserActiveStatus,
  UpdateUserRole,
  User,
  UserApiResponse,
} from 'src/types/user';
import { ERROR_STATUS_CODE } from 'src/constants/axiosInstance';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import { NOT_AUTHORIZED_MESSAGE, UNHANDLED_ERROR } from 'src/constants/errors';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import { observer } from 'mobx-react-lite';
import {
  PAGE_SIZE_OPTIONS,
  WORDS_PER_ROW_CELL,
  truncateString,
} from 'src/constants/utils';
import { ROLES } from 'src/constants/Role';
import TablePaginationActions from 'src/constants/TablePaginationActions';
import { BOT_NAME } from 'src/constants/Bot';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';

const Members: React.FC = () => {
  const [isUserAuthorized, setIsUserAuthorized] = useState(true);
  const [loadingForDataGrid, setLoadingForDataGrid] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [updateUserId, setUpdateUserId] = useState<string>('');
  const [rowCountState, setRowCountState] = useState<number>(0);
  const [newUserRole, setNewUserRole] = useState<keyof typeof ROLES | null>(
    null,
  );
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const { getUserDetails, logout } = useAuthStore();
  const userDetails = getUserDetails();

  const {
    dialogueState,
    updateDialogueState,
    updateDialogMetaData,
    dialogType,
  } = useDialogStore();

  const { notify } = useAlertStore();

  const handleUserHanaAccess = async () => {
    if (!userDetails || !updateUserId) return;

    updateDialogueState('LOADING');

    const { _id: mongoUserId } = userDetails;

    const currentIsActive = allUsers.filter(
      (user) => user._id === updateUserId,
    )[0]['isActive'];

    const newIsActive = !currentIsActive;

    const body: UpdateUserActiveStatus = {
      isActive: newIsActive,
      updatedBy: mongoUserId,
      usersData: [{ _id: updateUserId }],
    };

    const { success: updateActiveStatusSuccess, content: activeStatusContent } =
      await updateUserActiveStatus(body);

    if (updateActiveStatusSuccess && activeStatusContent?.acknowledged) {
      setAllUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updateUserId ? { ...user, isActive: newIsActive } : user,
        ),
      );
      notify(UPDATE_USER_ACCESS);
    } else {
      notify(UNHANDLED_ERROR);
    }

    updateDialogueState('CLOSED');
    setUpdateUserId('');
  };

  const openDialogForUpdateHanaAccess = (userDetails: User, userId: string) => {
    setUpdateUserId(userId);

    let dialogtitle: string;
    let dialogDescription: string;

    if (userDetails.isActive) {
      dialogtitle = DIALOG_TITLE_REMOVE_ACCESS;
      dialogDescription = dialogMessageRemoveAccess(userDetails.displayName);
    } else {
      dialogtitle = 'Grant Access';
      dialogDescription = dialogMessageGrantAccess(userDetails.displayName);
    }

    updateDialogMetaData(
      dialogtitle,
      dialogDescription,
      DialogFor.UPDATE_HANA_ACCESS,
      [],
    );

    updateDialogueState('OPEN');
  };

  const handleUserRoleChange = async () => {
    if (!userDetails || !updateUserId || !newUserRole) return;

    updateDialogueState('LOADING');

    const { _id: mongoUserId } = userDetails;

    const body: UpdateUserRole = {
      role: newUserRole,
      updatedBy: mongoUserId,
      usersData: [{ _id: updateUserId }],
    };

    const { success: updateRoleSuccess, content: roleContent } =
      await updateUserRole(body);

    if (updateRoleSuccess && roleContent?.acknowledged) {
      setAllUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updateUserId ? { ...user, role: newUserRole } : user,
        ),
      );
      notify(UPDATE_USER_ROLE);
    } else {
      notify(UNHANDLED_ERROR);
    }

    updateDialogueState('CLOSED');
    setUpdateUserId('');
    setNewUserRole(null);
  };

  const openDialogForUpdateRole = (
    userId: string,
    newRole: keyof typeof ROLES,
  ) => {
    setUpdateUserId(userId);
    setNewUserRole(newRole);

    updateDialogMetaData(
      DIALOG_TITLE_UPDATE_ROLE,
      DIALOG_MESSAGE_UPDATE_ROLE,
      DialogFor.UPDATE_USER_ROLE,
      [],
    );

    updateDialogueState('OPEN');
  };

  const openDialogForDeleteUser = (deleteUserId: string) => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_USER_DELETE,
      DialogFor.DELETE_USER,
      [],
    );

    updateDialogueState('OPEN');

    setDeleteUserId(deleteUserId);
  };

  const confirmDeleteUser = async () => {
    if (!userDetails) return;

    if (deleteUserId) {
      updateDialogueState('LOADING');

      const { success: deleteUserSuccess, content: deletedUser } =
        await deleteUsersByIds({
          userIds: [deleteUserId],
          deletedBy: userDetails._id.toString(),
        });

      if (!deleteUserSuccess || !deletedUser) {
        notify(UNHANDLED_ERROR);

        updateDialogueState('CLOSED');

        return;
      }

      notify(DELETE_USER_SUCCESS);
      updateDialogueState('CLOSED');
      setDeleteUserId(null);
      fetchUsers();
    }
  };

  const disableHanaAccessOrRoleChangeOrDeleteButton = (
    params: GridRenderCellParams<User>,
    userDetails: UserApiResponse | null,
  ): boolean => {
    // If userDetails is null, disable it
    if (!userDetails) return true;

    // SUPER_ADMIN can disable any profile
    if (userDetails.role === ROLES.SUPER_ADMIN) return false;

    // ADMIN can disable other ADMIN profiles, but can't disable SUPER_ADMIN profiles
    if (userDetails.role === ROLES.ADMIN) {
      return params.row.role === ROLES.SUPER_ADMIN;
    }

    // A USER can disable their own profile but not other USER profiles
    if (userDetails.role === ROLES.USER) {
      return params.row._id !== userDetails._id;
    }

    // By default, disable the toggle
    return false;
  };

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => {
        return (
          <Typography component="p" variant="body1" sx={{ maxWidth: 170 }}>
            {truncateString(params?.row?.displayName, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 340,
      renderCell: (params) => {
        return (
          <Typography component="p" variant="body1">
            {truncateString(params.row.email, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 250,
      renderCell: (params) => {
        return (
          <FormControl sx={{ minWidth: 200, margin: '16px 0' }} size="medium">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              variant="outlined"
              labelId="role-select-label"
              id="role-select"
              value={params.row.role}
              onChange={(e) =>
                openDialogForUpdateRole(params.row.id, e.target.value)
              }
              label="Role"
              required
              disabled={disableHanaAccessOrRoleChangeOrDeleteButton(
                params,
                userDetails,
              )}
            >
              <MenuItem value={`${ROLES.USER}`} id="role-selected">
                User
              </MenuItem>
              <MenuItem value={`${ROLES.ADMIN}`} id="role-selected">
                Admin
              </MenuItem>
              {(params.row.role === ROLES.SUPER_ADMIN ||
                userDetails?.role === ROLES.SUPER_ADMIN) && (
                <MenuItem value={`${ROLES.SUPER_ADMIN}`}>Super Admin</MenuItem>
              )}
            </Select>
          </FormControl>
        );
      },
    },

    {
      field: 'isActive',
      headerName: `${BOT_NAME} Access`,
      width: 150,
      renderCell: (params) => {
        return (
          <Switch
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            checked={params.row.isActive}
            onChange={() =>
              openDialogForUpdateHanaAccess(params.row, params.row.id)
            }
            disabled={disableHanaAccessOrRoleChangeOrDeleteButton(
              params,
              userDetails,
            )}
            color="primary"
            id="user-hana-access"
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          id="delete-user"
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => openDialogForDeleteUser(params.row._id)}
          disabled={disableHanaAccessOrRoleChangeOrDeleteButton(
            params,
            userDetails,
          )}
        >
          Delete
        </Button>
      ),
    },
  ];

  const fetchUsers = useCallback(async () => {
    if (!userDetails) return;

    const { organizationId } = userDetails;

    setLoadingForDataGrid(true);

    try {
      const {
        success: getAllUsersSuccess,
        content: allUsers,
        statusCode,
      } = await getAllUsers(
        organizationId.toString(),
        paginationModel.page,
        paginationModel.pageSize,
      );

      if (!getAllUsersSuccess || !allUsers) {
        if (statusCode !== ERROR_STATUS_CODE.FORBIDDEN) {
          notify(UNHANDLED_ERROR);
        }

        setIsUserAuthorized(
          statusCode === ERROR_STATUS_CODE.FORBIDDEN ? false : true,
        );

        setLoadingForDataGrid(false);

        setAllUsers([]);

        return;
      }

      if (getAllUsersSuccess) {
        setIsUserAuthorized(true);

        const updatedContent = allUsers.data.map((user) => ({
          ...user,
          id: user._id,
        }));

        setRowCountState(allUsers.totalCount);
        setAllUsers(updatedContent);
      }
    } catch (error) {
      notify(UNHANDLED_ERROR);
      setAllUsers([]);
    } finally {
      setLoadingForDataGrid(false);
    }
  }, [paginationModel.page, paginationModel.pageSize, notify, userDetails]);

  useEffect(() => {
    if (!userDetails) {
      logout();
    }
  }, [userDetails, logout]);

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.UPDATE_HANA_ACCESS:
        handleUserHanaAccess();
        break;

      case DialogFor.UPDATE_USER_ROLE:
        handleUserRoleChange();
        break;
      case DialogFor.DELETE_USER:
        confirmDeleteUser();
        break;
      default:
    }
  }, [dialogueState]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (!userDetails) {
    return <ErrorBoundary message={UNHANDLED_ERROR} />;
  }

  if (
    (userDetails.role !== ROLES.SUPER_ADMIN &&
      userDetails.role !== ROLES.ADMIN) ||
    !isUserAuthorized
  ) {
    return (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="p" variant="h6">
          {NOT_AUTHORIZED_MESSAGE}
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="main">
      <ModuleHeader title="Users" />
      <Box>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight
          paginationMode="server"
          rows={allUsers}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          disableColumnMenu
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          loading={loadingForDataGrid}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paginationModel.page - 1,
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          rowCount={rowCountState}
          onPaginationModelChange={(newPaginationModel) => {
            setPaginationModel((oldPaginationModel) => ({
              ...oldPaginationModel,
              page: newPaginationModel.page + 1,
              pageSize: newPaginationModel.pageSize,
            }));
          }}
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default observer(Members);
