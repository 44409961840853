import { NotificationItem } from 'src/components/notification-menu/NotificationMenu';
import { ROLES } from './Role';
import { PATHS } from './navigateRoutes';
import { SUPPORT_EMAIL } from './Developer';
import { ListItemData } from 'src/components/custom-list-items/Custom_ListItems_MUI_Component';

export const DNS_VERIFY_NOTIFICATION: NotificationItem = {
  text: 'Promote Yourself as Admin',
  description: 'You can promote yourself by completing DNS verification.',
  accessRoles: [ROLES.USER],
  navigateTo: `?dns-verify=true`,
};

export const ONBOARDING_NOTIFICATION: NotificationItem = {
  text: 'Complete Onboarding',
  description: 'You can add the organization details for accurate responses.',
  accessRoles: [ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.INDIVIDUAL_USER_ADMIN],
  navigateTo: PATHS.onBoardRegistration,
};

export const NO_NOTIFICATIONS_TITLE = 'No notification';
export const NOTIFICATIONS_TITLE = 'Notifications';

export const DNS_VERIFICATION_TITLE = 'Save your key';

export const DNS_VERIFICATION_INSTRUCTIONS: ListItemData[] = [
  {
    description: 'Please copy the provided secret key after generating token',
  },
  {
    description:
      'Navigate to the website where your domain is deployed (e.g., Google Domains, Cloudflare).',
  },
  {
    description:
      'Locate and open the DNS settings/tab on the respective deployment webpage.',
  },
  {
    description: `Add a new DNS record with the following details:`,
  },
  {
    description: `Host Name: @ (This represents your organization's domain)`,
  },
  {
    description: `Type: TXT`,
  },
  {
    description: `Value/Data: Paste the copied secret key`,
  },
  {
    description: 'Save the newly created DNS record.',
  },
  {
    description: 'Return to the dashboard and click on the "Verify" button',
  },
  {
    description:
      'If you are facing issues, you can manually verify your domain by sending us an email with supporting reason.',
  },
];

export const DNS_VERIFICATION_SUCCESS =
  'You have been verified successfully. You are now an Admin.';
export const DNS_VERIFICATION_ALREADY_VERIFIED =
  'A user from your organization has already verified the domain.';
export const DNS_VERIFICATION_FAILURE = `We could not find the txt record. Please add the above text value to your domain or contact us at ${SUPPORT_EMAIL}.`;

export const COPY_NOTIFICATION_MESSAGE = 'You have copied the key.';

export const MANUAL_DNS_VERIFY_EMAIL_SUBJECT =
  'Manual Verification Request for Admin Promotion';

export const MANUAL_DNS_VERIFY_EMAIL_BODY = `Dear Support Team,

I am writing to request manual verification of my domain to proceed with my promotion as an Admin. 

Here are the details of my request:
- Why I should be promoted to Admin for my domain: [Provide a brief reason why you need manual verification]
            
Best regards,
[Your Name]`;
