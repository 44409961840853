import { Card, CardContent, Typography } from '@mui/material';
import { YouTubeVideo } from 'src/types/videosTypes';
import styleVariables from '../../scss/_variables.scss';
import styles from './VideoCard.module.scss';

const VideoCard = ({ title, youtubeVideoId, subtitle }: YouTubeVideo) => {
  return (
    <Card
      sx={{
        width: '100%',
        minHeight: '100%',
        background: styleVariables.midnightIndigo,
        color: styleVariables.colorWhite,
        textAlign: 'center',
        borderRadius: 4,
      }}
    >
      <CardContent>
        <iframe
          src={`https://www.youtube.com/embed/${youtubeVideoId}`}
          allowFullScreen
          className={styles.videoIFrame}
          title={title}
          loading="lazy"
        />
        <Typography
          sx={{ marginTop: '16px', textTransform: 'capitalize' }}
          variant="body1"
          component="p"
        >
          {title}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ marginTop: '16px' }}
          variant="body2"
          component="p"
        >
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VideoCard;
