import { Button } from '@mui/material';
import styles from './UpgradeToPro.module.scss';
import ROCKET_ICON from '../../assets/Rocket-Spaceship.png';
import styleVariables from '../../scss/_variables.scss';

export interface UpgradeToProProps {
  title: string;
  subTitle: string;
  ctaText: string;
  handleChangePlan: () => void;
}

const UpgradeToPro = ({
  title,
  subTitle,
  ctaText,
  handleChangePlan,
}: UpgradeToProProps) => {
  return (
    <div className={styles['wrapper-card']}>
      <div className={styles['icon-container']}>
        <img className={styles['header-icon']} src={ROCKET_ICON} />
      </div>
      <p className={styles['title']}>{title}</p>
      <p className={styles['sub-title']}>{subTitle}</p>
      <Button
        id="upgrade-cta-btn"
        sx={{
          background: styleVariables.specialGradient,
          color: styleVariables.colorWhite,
        }}
        className={styles['cta-button']}
        onClick={handleChangePlan}
      >
        {ctaText}
      </Button>
    </div>
  );
};

export default UpgradeToPro;
