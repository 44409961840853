import { Box, Button, Typography } from '@mui/material';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { observer } from 'mobx-react-lite';
import { ROLES } from 'src/constants/Role';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import { FormField } from 'src/components/custom-dialog/CustomDialog';
import {
  DIALOG_CONTENT_FOR_SCHEDULE_DM_ANNOUNCEMENT,
  DIALOG_TITLE_FOR_DELETE,
  DIALOG_CONTENT_FOR_DM_ANNOUNCEMENT_DELETE,
  DELETE_DM_ANNOUNCEMENT_SUCCESS,
  DIALOG_TITLE_FOR_SCHEDULE_DM_ANNOUNCEMENT,
  SCHEDULE_DM_ANNOUNCEMENT_BUTTON_TEXT,
  SCHEDULE_DM_ANNOUNCEMENT_SUCCESS,
  SMALL_DIALOG_TEXTFIELD_ROWS,
  DIALOG_TITLE_FOR_ABORT,
  DIALOG_CONTENT_FOR_DM_ANNOUNCEMENT_ABORT,
  ABORT_DM_ANNOUNCEMENT_SUCCESS,
} from 'src/constants/dialogMetaInfo';
import {
  DATA_GRID_COLUMN_WIDTH,
  DATA_GRID_COLUMN_WIDTH_7,
  STYLE_FOR_LARGE_DIALOG,
} from 'src/constants/Layout';
import { useCallback, useEffect, useState } from 'react';
import {
  PAGE_SIZE_OPTIONS,
  WORDS_PER_ROW_CELL,
  truncateString,
} from 'src/constants/utils';
import TablePaginationActions from 'src/constants/TablePaginationActions';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  abortDmAnnouncementByJobId,
  deleteDmAnnouncementByJobId,
  getScheduledDmAnnouncementsBySuperAdminOrgId,
  scheduleDmAnnouncement,
} from 'src/services/crm';
import {
  GetScheduledDmAnnouncementApiResponse,
  ScheduleDmAnnouncementToAllUsersJobPayload,
} from 'src/types/crm';
import { formatDateTime } from 'src/utils/dateUtils';

const DM_ANNOUNCEMENT_INITIAL_FIELDS: FormField[] = [
  {
    label: 'Enter Date and Time',
    id: 'announcementScheduleIsoDate',
    value: '',
    type: 'date-time',
    fieldHeading: 'Date/Time',
  },
  {
    label: 'Campaign Name',
    id: 'campaignName',
    value: '',
    type: 'text',
    fieldHeading: 'campaign Name',
  },
];

const DmAnnouncement = observer(() => {
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { notify } = useAlertStore();
  const [scheduledDmAnnouncements, setScheduledDmAnnouncements] = useState<
    GetScheduledDmAnnouncementApiResponse[]
  >([]);
  const [loadingForDataGrid, setLoadingForDataGrid] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [deleteDmAnnouncementJobId, setDeleteDmAnnouncementJobId] = useState<
    string | null
  >(null);
  const [abortDmAnnouncementJobId, setAbortDmAnnouncementJobId] = useState<
    string | null
  >(null);

  const {
    dialogueState,
    dialogType,
    updateDialogueState,
    dialogFormFields,
    updateDialogMetaData,
  } = useDialogStore();

  const openDialogForScheduleDmAnnouncement = () => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_SCHEDULE_DM_ANNOUNCEMENT,
      DIALOG_CONTENT_FOR_SCHEDULE_DM_ANNOUNCEMENT,
      DialogFor.SCHEDULE_DM_ANNOUNCEMENT,
      DM_ANNOUNCEMENT_INITIAL_FIELDS,
      SMALL_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const confirmDeleteDmAnnouncementJob = async () => {
    if (deleteDmAnnouncementJobId) {
      updateDialogueState('LOADING');

      const {
        success: deleteDmAnnouncementSuccess,
        content: deletedDmAnnouncementMessage,
      } = await deleteDmAnnouncementByJobId(deleteDmAnnouncementJobId);

      if (!deleteDmAnnouncementSuccess || !deletedDmAnnouncementMessage) {
        notify(UNHANDLED_ERROR);
        updateDialogueState('OPEN');

        return;
      }

      notify(DELETE_DM_ANNOUNCEMENT_SUCCESS);
      updateDialogueState('CLOSED');
      setDeleteDmAnnouncementJobId(null);
      fetchScheduledDmAnnouncements();
    }
  };

  const openDialogForDeleteDmAnnouncementByJobId = (
    deleteDmAnnouncementJobId: string,
  ) => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_DM_ANNOUNCEMENT_DELETE,
      DialogFor.DELETE_DM_ANNOUNCEMENT,
      [],
    );

    updateDialogueState('OPEN');

    setDeleteDmAnnouncementJobId(deleteDmAnnouncementJobId);
  };

  const confirmAbortDmAnnouncementJob = async () => {
    if (abortDmAnnouncementJobId) {
      updateDialogueState('LOADING');

      const {
        success: abortDmAnnouncementSuccess,
        content: abortedDmAnnouncementMessage,
      } = await abortDmAnnouncementByJobId(abortDmAnnouncementJobId);

      if (!abortDmAnnouncementSuccess || !abortedDmAnnouncementMessage) {
        notify(UNHANDLED_ERROR);
        updateDialogueState('OPEN');

        return;
      }

      notify(ABORT_DM_ANNOUNCEMENT_SUCCESS);
      updateDialogueState('CLOSED');
      setAbortDmAnnouncementJobId(null);
      fetchScheduledDmAnnouncements();
    }
  };

  const openDialogForAbortDmAnnouncementByJobId = (
    abortDmAnnouncementJobId: string,
  ) => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_ABORT,
      DIALOG_CONTENT_FOR_DM_ANNOUNCEMENT_ABORT,
      DialogFor.ABORT_DM_ANNOUNCEMENT,
      [],
    );

    updateDialogueState('OPEN');

    setAbortDmAnnouncementJobId(abortDmAnnouncementJobId);
  };

  const isDmAnnouncementDeleteButtonDisabled = (
    row: GetScheduledDmAnnouncementApiResponse,
  ) => {
    const isPastScheduleDate =
      new Date().getTime() >=
      new Date(row.data.announcementScheduleIsoDate).getTime();

    return isPastScheduleDate && !row.data.abortExecution;
  };

  const isForceStopDmAnnouncementButtonDisabled = (
    row: GetScheduledDmAnnouncementApiResponse,
  ) => {
    const isScheduledDateInFuture =
      new Date().getTime() <
      new Date(row.data.announcementScheduleIsoDate).getTime();

    return isScheduledDateInFuture || row.data.abortExecution;
  };

  const columns: GridColDef<GetScheduledDmAnnouncementApiResponse>[] = [
    {
      field: 'campaignName',
      headerName: 'Campaign name',
      width: DATA_GRID_COLUMN_WIDTH,
      renderCell: ({ row }) => {
        return (
          <Typography
            component="p"
            variant="body1"
            sx={{ maxWidth: DATA_GRID_COLUMN_WIDTH, margin: '16px 0' }}
          >
            {truncateString(row.data.campaignName, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'announcementScheduleIsoDate',
      headerName: 'DM announcement scheduled date',
      width: DATA_GRID_COLUMN_WIDTH,
      renderCell: ({ row }) => {
        return (
          <Typography component="p" variant="body1">
            {formatDateTime(row.data.announcementScheduleIsoDate)}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: DATA_GRID_COLUMN_WIDTH_7,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                openDialogForDeleteDmAnnouncementByJobId(params.row.data.jobId);
              }}
              disabled={isDmAnnouncementDeleteButtonDisabled(params.row)}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                openDialogForAbortDmAnnouncementByJobId(params.row.data.jobId);
              }}
              disabled={isForceStopDmAnnouncementButtonDisabled(params.row)}
            >
              Force Stop
            </Button>
          </Box>
        );
      },
    },
  ];

  const fetchScheduledDmAnnouncements = useCallback(async () => {
    if (!userDetails || userDetails.role !== ROLES.SUPER_ADMIN) return;
    const { organizationId } = userDetails;

    setLoadingForDataGrid(true);

    try {
      const {
        success: getScheduledDmAnnouncementsSuccess,
        content: scheduledDmAnnouncements,
      } = await getScheduledDmAnnouncementsBySuperAdminOrgId(
        organizationId.toString(),
        paginationModel.page,
        paginationModel.pageSize,
      );

      if (!getScheduledDmAnnouncementsSuccess || !scheduledDmAnnouncements) {
        notify(UNHANDLED_ERROR);

        setLoadingForDataGrid(false);

        setScheduledDmAnnouncements([]);

        return;
      }

      const updatedContent = scheduledDmAnnouncements.data.map(
        (scheduledDmAnnouncement) => {
          const updatedScheduledDmAnnouncement = {
            ...scheduledDmAnnouncement,
            id: scheduledDmAnnouncement._id,
          };

          return updatedScheduledDmAnnouncement;
        },
      );

      setScheduledDmAnnouncements(updatedContent);
      setRowCountState(scheduledDmAnnouncements.totalCount);
    } catch (error) {
      notify(UNHANDLED_ERROR);
    } finally {
      setLoadingForDataGrid(false);
    }
  }, [paginationModel.page, paginationModel.pageSize, notify, userDetails]);

  useEffect(() => {
    fetchScheduledDmAnnouncements();
  }, [fetchScheduledDmAnnouncements]);

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.SCHEDULE_DM_ANNOUNCEMENT:
        handleScheduleDmAnnouncement();
        break;
      case DialogFor.DELETE_DM_ANNOUNCEMENT:
        confirmDeleteDmAnnouncementJob();
        break;
      case DialogFor.ABORT_DM_ANNOUNCEMENT:
        confirmAbortDmAnnouncementJob();
        break;
      default:
    }
  }, [dialogueState]);

  const handleScheduleDmAnnouncement = async () => {
    if (!userDetails) return;

    const { organizationId } = userDetails;

    const body: ScheduleDmAnnouncementToAllUsersJobPayload = {
      campaignName: '',
      announcementScheduleIsoDate: '',
      superAdminOrgId: organizationId,
    };

    for (const field of dialogFormFields) {
      if (field.id === 'campaignName') {
        body.campaignName = field.value;
      }

      if (field.id === 'announcementScheduleIsoDate') {
        body.announcementScheduleIsoDate = field.value;
      }
    }

    if (!body.announcementScheduleIsoDate) {
      notify('announcementScheduleIsoDate is required.');
      updateDialogueState('OPEN');

      return;
    }

    if (!body.campaignName) {
      notify('campaignName is required.');
      updateDialogueState('OPEN');

      return;
    }

    updateDialogueState('LOADING');

    // schedule DM announcement
    const {
      success: scheduleDmAnnouncementSuccess,
      content: scheduleDmAnnouncementResponse,
    } = await scheduleDmAnnouncement(body);

    if (!scheduleDmAnnouncementSuccess || !scheduleDmAnnouncementResponse) {
      notify(UNHANDLED_ERROR);
      updateDialogueState('OPEN');

      return;
    }

    notify(SCHEDULE_DM_ANNOUNCEMENT_SUCCESS);

    updateDialogueState('CLOSED');

    fetchScheduledDmAnnouncements();
  };

  return (
    <Box
      component="main"
      sx={{
        marginTop: '16px',
      }}
    >
      <ModuleHeader title="DM Announcement" />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginBottom: '16px',
        }}
      >
        <Button
          id="schedule-announcement"
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialogForScheduleDmAnnouncement}
        >
          {SCHEDULE_DM_ANNOUNCEMENT_BUTTON_TEXT}
        </Button>
      </Box>
      <Box>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight
          paginationMode="server"
          rows={scheduledDmAnnouncements}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          disableColumnMenu
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          loading={loadingForDataGrid}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paginationModel.page - 1,
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          rowCount={rowCountState}
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
          onPaginationModelChange={(newPaginationModel) => {
            setPaginationModel((oldPaginationModel) => ({
              ...oldPaginationModel,
              page: newPaginationModel.page + 1,
              pageSize: newPaginationModel.pageSize,
            }));
          }}
        />
      </Box>
    </Box>
  );
});

export default DmAnnouncement;
