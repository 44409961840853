export const industryTypes = [
  { id: 'AG', name: 'Agriculture' },
  { id: 'AR', name: 'Architecture' },
  { id: 'AT', name: 'Arts and Entertainment' },
  { id: 'AV', name: 'Aviation' },
  { id: 'BE', name: 'Beauty and Cosmetics' },
  { id: 'BI', name: 'Biotechnology' },
  { id: 'CO', name: 'Construction' },
  { id: 'ED', name: 'Education' },
  { id: 'EN', name: 'Energy' },
  { id: 'FI', name: 'Finance' },
  { id: 'FM', name: 'Food and Beverage' },
  { id: 'GO', name: 'Government and Public Administration' },
  { id: 'HC', name: 'Healthcare' },
  { id: 'HO', name: 'Hospitality' },
  { id: 'IN', name: 'Insurance' },
  { id: 'IT', name: 'Information Technology' },
  { id: 'LE', name: 'Legal' },
  { id: 'MA', name: 'Manufacturing' },
  { id: 'ME', name: 'Media' },
  { id: 'NG', name: 'Non-Governmental Organization' },
  { id: 'PH', name: 'Pharmaceuticals' },
  { id: 'PR', name: 'Professional Services' },
  { id: 'RE', name: 'Real Estate' },
  { id: 'RT', name: 'Retail' },
  { id: 'SC', name: 'Supply Chain' },
  { id: 'SP', name: 'Sports' },
  { id: 'TE', name: 'Telecommunications' },
  { id: 'TR', name: 'Transportation' },
  { id: 'UT', name: 'Utilities' },
  { id: 'OT', name: 'Other' },
];

export const industrySizes = [
  { id: 'SM', name: 'Small', range: '1-50 employees' },
  { id: 'MD', name: 'Medium', range: '51-250 employees' },
  { id: 'LG', name: 'Large', range: '251-500 employees' },
  { id: 'XL', name: 'Extra Large', range: '501+ employees' },
];

export const EDITORS_CHOICE_RATINGS = `7`;

export const GOOGLE_WORKSPACE_MARKET_PLACE_INSTALL_COUNT = `13`;
