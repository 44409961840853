import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { handleError } from '../utils/commonErrorUtils';
import { axiosInstance } from '../constants/axiosInstance';
import { ApiResponse } from '../types/commonResponseTypes';
import {
  UserConsentPoliciesPayload,
  UserConsentPolicyApiResponse,
  PolicyApiResponse,
  CheckLatestPoliciesApprovedByUserApiResponse,
} from 'src/types/policy';

export const getAllLatestPolicies = async (): Promise<
  ApiResponse<PolicyApiResponse[]>
> => {
  try {
    const { data } = await axiosInstance.get(
      API_ROUTES_FOR_AXIOS['getAllLatestPolicies'](),
    );

    return data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const createUserConsentPolicy = async (
  payload: UserConsentPoliciesPayload,
): Promise<ApiResponse<UserConsentPolicyApiResponse[]>> => {
  try {
    const { data } = await axiosInstance.post(
      API_ROUTES_FOR_AXIOS['createUserConsentPolicy'](),
      payload,
    );

    return data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const checkLatestPoliciesApprovedByMongoUserId = async (
  mongoUserId: string,
): Promise<ApiResponse<CheckLatestPoliciesApprovedByUserApiResponse>> => {
  try {
    const { data } = await axiosInstance.get(
      API_ROUTES_FOR_AXIOS['checkLatestPoliciesApprovedByMongoUserId'](
        mongoUserId,
      ),
    );

    return data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const getLatestPoliciesNotApprovedByMongoUserId = async (
  mongoUserId: string,
): Promise<ApiResponse<PolicyApiResponse[]>> => {
  try {
    const { data } = await axiosInstance.get(
      API_ROUTES_FOR_AXIOS['getLatestPoliciesNotApprovedByMongoUserId'](
        mongoUserId,
      ),
    );

    return data;
  } catch (error: any) {
    return handleError(error);
  }
};
