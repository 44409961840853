import { makeAutoObservable } from 'mobx';
import { FormField } from '../../components/custom-dialog/CustomDialog';
import createStoreContext from '../createStoreContext';
import React from 'react';

export enum DialogFor {
  REPORT_GROUP = 'REPORT_GROUP',
  DELETE_REPORT_GROUP = 'DELETE_REPORT_GROUP',
  REPORT_MESSAGE = 'REPORT_MESSAGE',
  DELETE_REPORT_MESSAGE = 'DELETE_REPORT_MESSAGE',
  MEMORY = 'MEMORY',
  DELETE_MEMORY = 'DELETE_MEMORY',
  UPDATE_HANA_ACCESS = 'UPDATE_HANA_ACCESS',
  UPDATE_USER_ROLE = 'UPDATE_USER_ROLE',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  CONFIRM_DELETE_ORGANIZATION = 'CONFIRM_DELETE_ORGANIZATION',
  DELETE_USER = 'DELETE_USER',
  CONFIRM_DELETE_USER = 'CONFIRM_DELETE_USER',
  DELETE_REMINDER = 'DELETE_REMINDER',
  REMINDER = 'REMINDER',
  CANCEL_PAID_PLAN = 'CANCEL_PAID_PLAN',
  SCHEDULE_MAIL = 'SCHEDULE_MAIL',
  SCHEDULE_DM_ANNOUNCEMENT = 'SCHEDULE_DM_ANNOUNCEMENT',
  DELETE_DM_ANNOUNCEMENT = 'DELETE_DM_ANNOUNCEMENT',
  ABORT_DM_ANNOUNCEMENT = 'ABORT_DM_ANNOUNCEMENT',
}

export type DialogueState = 'OPEN' | 'CLOSED' | 'LOADING' | 'ACCEPT' | 'REJECT';

export interface SubmitDialogFor {
  dialogType: DialogFor | null;
}

export const [DialogStoreProvider, useDialogStore] = createStoreContext(
  class DialogStore {
    dialogType: DialogFor | null = null;
    dialogueState: DialogueState = 'CLOSED';
    dialogFormFields: FormField[] = [];
    dialogTitle = '';
    dialogContent = '';
    dialogStyle? = {};
    inputFieldRow = 1;

    public constructor() {
      makeAutoObservable(this);
    }

    updateDialogueState = (state: DialogueState) => {
      this.dialogueState = state;
    };

    updateDialogMetaData = (
      title: string,
      content: string,
      type: DialogFor,
      formFields: FormField[],
      inputFieldRow: number = 1,
      dialogStyle?: React.CSSProperties,
    ) => {
      this.inputFieldRow = inputFieldRow;
      this.dialogStyle = dialogStyle;
      this.dialogTitle = title;
      this.dialogContent = content;
      this.dialogType = type;
      this.dialogFormFields = formFields;
    };

    resetDialog = () => {
      this.dialogueState = 'CLOSED';
      this.inputFieldRow = 1;
      this.dialogStyle = {};
      this.dialogType = null;
      this.dialogFormFields = [];
      this.dialogTitle = '';
      this.dialogContent = '';
    };

    resetFormFieldsValue = (formFields: FormField[]) => {
      return formFields.map((formField) => {
        formField.value = '';

        return formField;
      });
    };
  },
);

export type GlobalStore = ReturnType<typeof useDialogStore>;
