import * as Yup from 'yup';
import { IndividualUserOnboardingRegisterFormFields } from 'src/types/registrationFormTypes';
import { ABOUT_CHARACTER_LIMIT } from 'src/constants/Onboarding';

export const individualUserRegistrationValidationSchema: Yup.ObjectSchema<IndividualUserOnboardingRegisterFormFields> =
  Yup.object().shape({
    country: Yup.string().required('Country is required'),
    about: Yup.string()
      .required('About is required')
      .max(ABOUT_CHARACTER_LIMIT),
    location: Yup.string().required('Location is required'),
  });
