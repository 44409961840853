import React, { ReactNode } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
} from '@mui/material';
import styleVariables from '../../scss/_variables.scss';
import { PricingPlanFeatureApiResponse } from 'src/types/plan';
import PricingFeatures from './PricingFeatures';
import DoneIcon from '@mui/icons-material/Done';
import { CURRENT_PLAN_TEXT } from 'src/constants/cardsEnum';

export interface PricingCardProps {
  planType: string;
  price: string;
  pricingInterval: string | ReactNode;
  features: PricingPlanFeatureApiResponse[];
  isCurrentPlan?: boolean;
  onSelect: () => void;
  buttonTitle: string;
  disableButton?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({
  planType,
  price,
  pricingInterval,
  features,
  isCurrentPlan,
  buttonTitle,
  disableButton = false,
  onSelect,
}) => {
  return (
    <Card
      sx={{
        margin: 2,
        padding: 4,
        border: isCurrentPlan
          ? `1px solid ${styleVariables.hanabiThemeColorDarkRed}`
          : `1px solid ${styleVariables.mutedGray}`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isCurrentPlan && (
        <Typography
          sx={{
            background: styleVariables.specialGradient,
            color: styleVariables.colorWhite,
            textAlign: 'center',
            borderRadius: '2px',
          }}
        >
          {CURRENT_PLAN_TEXT.toUpperCase()}
        </Typography>
      )}
      <CardContent>
        <Typography color="text.secondary" gutterBottom>
          {planType.toUpperCase()}
        </Typography>
        <Typography variant="h4">
          {price}
          <Typography variant="body2" color="text.secondary">
            {pricingInterval}
          </Typography>
        </Typography>
        <PricingFeatures
          features={features}
          DescriptionIcon={
            <DoneIcon
              sx={{
                width: '20px',
                height: '20px',
              }}
            />
          }
          overallWrapperStyle={{ my: 2 }}
          highlightListItemStyle={{
            padding: 0,
            marginLeft: '48px',
            display: 'list-item',
            listStyleType: 'disc',
          }}
          descriptionStyle={{ paddingLeft: '12px' }}
        />
      </CardContent>
      <CardActions
        sx={{
          mt: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          size="large"
          variant="contained"
          sx={{ textAlign: 'center' }}
          onClick={onSelect}
          disabled={disableButton}
          id={planType.toUpperCase()}
        >
          {buttonTitle}
        </Button>
      </CardActions>
    </Card>
  );
};

export default PricingCard;
