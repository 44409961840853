import { useState } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { ROLES } from 'src/constants/Role';
import { Box } from '@mui/material';
import styles from 'src/scss/_variables.scss';
import {
  NOTIFICATIONS_TITLE,
  NO_NOTIFICATIONS_TITLE,
} from 'src/constants/notification';

export interface NotificationItem {
  text: string;
  description: string;
  accessRoles: Array<keyof typeof ROLES>;
  navigateTo: string;
}

interface NotificationProps {
  notifications: NotificationItem[];
  userRole: keyof typeof ROLES;
}

const NotificationMenu: React.FC<NotificationProps> = ({
  notifications,
  userRole,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const filteredNotifications = notifications.filter((notification) =>
    notification.accessRoles.includes(userRole),
  );

  return (
    <Box>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge
          color="success"
          variant={notifications.length > 0 ? 'dot' : 'standard'}
        >
          <NotificationsIcon sx={{ color: styles.colorWhite }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: styles.midnightIndigo,
            },
          },
        }}
      >
        <Typography sx={{ p: 1 }}>
          {notifications.length > 0
            ? NOTIFICATIONS_TITLE
            : NO_NOTIFICATIONS_TITLE}
        </Typography>
        <List>
          {filteredNotifications.map((notification, index) => (
            <ListItem
              key={index}
              onClick={() => {
                navigate(notification.navigateTo);
                handleClose();
              }}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemText
                primary={notification.text}
                secondary={notification.description}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default NotificationMenu;
