export const BACKEND_BASE_URL = process.env.REACT_APP_HANA_BACKEND_URL;
export const OAUTH_REDIRECT_URI = `${BACKEND_BASE_URL}/chat/api`;

export const API_ROUTES_FOR_AXIOS = {
  getSessionDetails: () => `/dashboard/check-valid-session`,
  getUserInfoByCookie: () => `/users/get-user-info`,
  logout: () => `/dashboard/logout`,
  groupNameAvailabilityCheck: (orgId: string) =>
    `/report-group/organizations/${orgId}/name-availability-check`,
  createReportGroup: (orgId: string) => `/report-group/organizations/${orgId}`,
  getReportGroupDetailsById: (groupId: string) => `/report-group/${groupId}`,
  deleteReportGroupById: (groupId: string) => `/report-group/${groupId}`,
  getReportGroupsByOrgId: (orgId: string, page: number, limit: number) =>
    `/report-group/organizations/${orgId}?page=${page}&limit=${limit}`,
  getReportGroupsByOrgIdAndUserId: (orgId: string, userId: string) =>
    `/report-group/organizations/${orgId}/users/${userId}`,
  updateReportGroupsByOrgIdAndUserId: (
    orgId: string,
    userId: string,
    groupId: string,
  ) => `/report-group/organizations/${orgId}/users/${userId}/groups/${groupId}`,
  getReportMessagesByGroupId: (groupId: string, page: number, limit: number) =>
    `/report-message/groups/${groupId}?page=${page}&limit=${limit}`,
  createReportMessage: () => `/report-message`,
  updateReportMessage: (messageId: string) => `/report-message/${messageId}`,
  deleteReportMessageById: (messageId: string) =>
    `/report-message/${messageId}`,
  createMemory: () => `/memory`,
  getMemoryListByOrgId: (
    orgId: string,
    page: number,
    limit: number,
    searchQuery: string | null,
  ) =>
    searchQuery
      ? `/memory/organizations/${orgId}?page=${page}&limit=${limit}&search=${searchQuery}`
      : `/memory/organizations/${orgId}?page=${page}&limit=${limit}`,
  deleteMemoryById: (memoryId: string) => `/memory/${memoryId}`,
  getMemoryById: (memoryId: string) => `/memory/${memoryId}`,
  updateMemoryById: (memoryId: string) => `/memory/${memoryId}`,
  getTotalActiveSpacesIntegrated: (orgId: string) =>
    `/spaces/organizations/${orgId}/spaces-count?isActive=true`,
  getTotalUsersAuthorized: (orgId: string) =>
    `/users/organizations/${orgId}/users-count`,
  getOrganizationInteractionCountForMonth: (
    orgId: string,
    currentDate: string,
  ) =>
    `/usage/organizations/${orgId}/interaction-count?currentDate=${currentDate}`,
  getUserInteractionCountForMonth: (
    mongoUserId: string,
    orgId: string,
    currentDate: string,
  ) =>
    `/usage/organizations/${orgId}/users/${mongoUserId}/interaction-count?currentDate=${currentDate}`,
  getAllUsers: (orgId: string, page: number, limit: number) =>
    `/users/organizations/${orgId}?page=${page}&limit=${limit}`,
  updateUserActiveStatus: () => `/users/update-active-status`,
  updateUserRole: () => `/users/update-role`,
  getUserInteractionDataForSelectedDates: (
    mongoUserId: string,
    orgId: string,
    startDate: string,
    endDate: string,
    timeZoneOffset: string,
  ) =>
    `/usage/organizations/${orgId}/users/${mongoUserId}/interaction-data-by-dates?startDate=${startDate}&endDate=${endDate}&timeZoneOffset=${timeZoneOffset}`,
  getOrgInteractionDataForSelectedDates: (
    orgId: string,
    startDate: string,
    endDate: string,
    timeZoneOffset: string,
  ) =>
    `/usage/organizations/${orgId}/interaction-data-by-dates?startDate=${startDate}&endDate=${endDate}&timeZoneOffset=${timeZoneOffset}`,
  updateOrganization: (organizationId: string) =>
    `/organization/${organizationId}`,
  getOrganization: (organizationId: string) =>
    `/organization/${organizationId}`,
  checkDnsVerification: (organizationId: string) =>
    `/organization/${organizationId}/check-dns-verification`,
  checkOnboardingComplete: (organizationId: string) =>
    `/organization/${organizationId}/check-onboarding-complete`,
  createDnsVerificationCode: () => `/dns-verification`,
  findDnsVerificationDetailsByOrgIdAndMongoUserId: (
    organizationId: string,
    mongoUserId: string,
  ) => `/dns-verification/organizations/${organizationId}/users/${mongoUserId}`,
  verifyDnsByOrgIdAndUserId: () => `/dns-verification/verify`,
  getOrganizationSpaceData: (
    organizationId: string,
    page: number,
    limit: number,
  ) => `/spaces/organizations/${organizationId}?page=${page}&limit=${limit}`,
  updateSpaceHanaAccessById: (spaceId: string) => `spaces/${spaceId}`,
  getOrganizationDetailsByOrgId: (orgId: string) => `/organization/${orgId}`,
  getPricingPlansDetails: (planName?: string) =>
    `/plans/client-pricing-details?${planName ? `plan=${planName}` : ''}`,
  getAllLatestPolicies: () => `/policy/get-all-latest-policies`,
  createUserConsentPolicy: () => `/user-consent-policy`,
  checkLatestPoliciesApprovedByMongoUserId: (mongoUserId: string) =>
    `/user-consent-policy/users/${mongoUserId}/check-latest-policies-approval`,
  getLatestPoliciesNotApprovedByMongoUserId: (mongoUserId: string) =>
    `/user-consent-policy/users/${mongoUserId}/not-approved-policies`,
  deleteOrganizationById: (orgId: string, mongoUserId: string) =>
    `/delete/organizations/${orgId}/users/${mongoUserId}`,
  deleteUsers: () => `/delete/users`,
  checkIfUserInteractedWithHana: (userId: string) =>
    `usage/check-hana-integration/${userId}`,
  getRemindersByOrgId: (
    orgId: string,
    page: number,
    limit: number,
    mongoUserId?: string,
  ) =>
    `reminder/organizations/${orgId}?page=${page}&limit=${limit}&mongoUserId=${mongoUserId}`,
  getScheduledMailsBySuperAdminOrgId: (
    orgId: string,
    page: number,
    limit: number,
  ) => `crm/scheduled-mails/organizations/${orgId}?page=${page}&limit=${limit}`,
  scheduleAMail: () => `crm/users/all/schedule-mail`,
  getReminderById: (reminderId: string) => `/reminder/${reminderId}`,
  deleteReminderById: (reminderId: string) => `/reminder/${reminderId}`,
  updateReminderById: (reminderId: string) => `/reminder/${reminderId}`,
  createReminder: () => `/reminder`,
  validateMongoUserId: (userId: string) => `/users/${userId}/validate`,
  validateOrganizationId: (orgId: string) => `/organization/${orgId}/validate`,
  scheduleDmAnnouncement: () => `crm/users/all/schedule-dm-announcement`,
  getScheduledDmAnnouncementsBySuperAdminOrgId: (
    orgId: string,
    page: number,
    limit: number,
  ) =>
    `crm/scheduled-dm-announcements/organizations/${orgId}?page=${page}&limit=${limit}`,
  deleteDmAnnouncementByJobId: (jobId: string) =>
    `crm/dm-announcement/${jobId}`,
  abortDmAnnouncementByJobId: (jobId: string) =>
    `crm/dm-announcement/${jobId}/abort`,
  connectLemonsqueezyEventSourceByOrgId: (orgId: string) =>
    `/lemonsqueezy/events/subscribe/${orgId}`,
  checkLemonsqueezyExistingSubscriptionByOrgIdFromDb: (orgId: string) =>
    `/lemonsqueezy/subscriptions/organizations/${orgId}/check-lemonsqueezy-existing-subscriptions`,
  createCheckoutInLemonsqueezy: () => `lemonsqueezy/checkout`,
  getLemonsqueezySubscriptionIntervalByOrgId: (orgId: string) =>
    `lemonsqueezy/subscriptions/organizations/${orgId}/recurring-interval`,
  updateSubscriptionByOrgIdInLemonsqueezy: (orgId: string) =>
    `lemonsqueezy/subscriptions/organizations/${orgId}`,
  cancelSubscriptionByOrgIdInLemonsqueezy: (orgId: string) =>
    `lemonsqueezy/subscriptions/organizations/${orgId}/cancel`,
  resumeSubscriptionByOrgIdInLemonsqueezy: (orgId: string) =>
    `lemonsqueezy/subscriptions/organizations/${orgId}/resume`,
  getSubscriptionStatusByOrgIdInLemonsqueezy: (orgId: string) =>
    `lemonsqueezy/subscriptions/organizations/${orgId}/status`,
  customEventTracking: () => `custom-event-tracking/api/v1/track-event`,
};
