import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { FormField } from 'src/components/custom-dialog/CustomDialog';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import {
  DATA_GRID_COLUMN_WIDTH,
  DATA_GRID_COLUMN_WIDTH_7,
  STYLE_FOR_LARGE_DIALOG,
} from 'src/constants/Layout';
import TablePaginationActions from 'src/constants/TablePaginationActions';
import {
  CREATE_REMINDER_BUTTON_TEXT,
  CREATE_REMINDER_SUCCESS,
  DELETE_REMINDER_SUCCESS,
  DIALOG_CONTENT_FOR_REMINDER,
  DIALOG_CONTENT_FOR_REMINDER_DELETE,
  DIALOG_CONTENT_FOR_UPDATE_REMINDER,
  DIALOG_CONTENT_MAX_LENGTH,
  DIALOG_TITLE_FOR_DELETE,
  DIALOG_TITLE_FOR_REMINDER,
  DIALOG_TITLE_FOR_UPDATE_REMINDER,
  SMALL_DIALOG_TEXTFIELD_ROWS,
  UPDATE_REMINDER_SUCCESS,
} from 'src/constants/dialogMetaInfo';
import {
  REMINDER_NOT_FOUND_BY_ID,
  UNHANDLED_ERROR,
} from 'src/constants/errors';
import {
  PAGE_SIZE_OPTIONS,
  WORDS_PER_ROW_CELL,
  extractGoogleUserId,
  truncateString,
} from 'src/constants/utils';
import {
  createReminder,
  deleteReminderById,
  getReminderById,
  getRemindersByOrgId,
  updateReminderById,
} from 'src/services/reminders';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import { ReminderDataType, ReminderResponse } from 'src/types/reminderType';
import { useSearchParams } from 'react-router-dom';

const REMINDER_INITIAL_FIELDS: FormField[] = [
  {
    label: 'Enter Date and Time',
    id: 'reminderDate',
    value: '',
    type: 'date-time',
    fieldHeading: 'Date/Time',
  },
  {
    label: 'https://mail.google.com/mail/u/0/#chat/space/AAAAspqV4S8',
    id: 'spaceName',
    value: '',
    type: 'text',
    fieldHeading: 'Space Name with ID',
  },
  {
    label: 'Enter Description',
    id: 'reminderContent',
    value: '',
    type: 'textarea',
    fieldHeading: 'Reminder Description',
    maxLength: DIALOG_CONTENT_MAX_LENGTH,
  },
];

const Reminder = observer(() => {
  const [reminders, setReminders] = useState<ReminderResponse[]>([]);
  const [deleteReminderId, setDeleteReminderId] = useState<string | null>(null);
  const [reminderIdForUpdate, setReminderIdForUpdate] = useState<string | null>(
    null,
  );
  const [rowCountState, setRowCountState] = useState(0);
  const { getUserDetails } = useAuthStore();
  const { notify } = useAlertStore();
  const userDetails = getUserDetails();
  const [searchParams] = useSearchParams();
  const [loadingForDataGrid, setLoadingForDataGrid] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const {
    dialogueState,
    dialogType,
    updateDialogueState,
    dialogFormFields,
    updateDialogMetaData,
  } = useDialogStore();

  const fetchReminders = useCallback(async () => {
    if (!userDetails) return;
    const { organizationId } = userDetails;

    setLoadingForDataGrid(true);

    try {
      const { success: reminderSuccess, content: remindersContent } =
        await getRemindersByOrgId(
          organizationId.toString(),
          paginationModel.page,
          paginationModel.pageSize,
          userDetails._id.toString(),
        );

      if (!reminderSuccess || !remindersContent) {
        notify(UNHANDLED_ERROR);

        setLoadingForDataGrid(false);

        setReminders([]);

        return;
      }

      if (reminderSuccess) {
        const updatedContent = remindersContent.data.map((reminder) => {
          const updatedReminder = { ...reminder, id: reminder._id };

          return updatedReminder;
        });

        setReminders(updatedContent);
        setRowCountState(remindersContent.totalCount);
      }
    } catch (error) {
      notify(UNHANDLED_ERROR);
    } finally {
      setLoadingForDataGrid(false);
    }
  }, [paginationModel.page, paginationModel.pageSize, notify, userDetails]);

  const handleCreateOrUpdateReminder = async () => {
    if (!userDetails) return;

    updateDialogueState('LOADING');

    const { _id: mongoUserId, organizationId } = userDetails;

    const googleUserId = extractGoogleUserId(userDetails.userID);

    if (!googleUserId) {
      notify(UNHANDLED_ERROR);
      updateDialogueState('OPEN');

      return;
    }

    const body: ReminderDataType = {
      mongoUserId,
      reminderText: '',
      orgId: organizationId.toString(),
      reminderDate: '',
      setByGoogleUserId: googleUserId,
      setForGoogleUserId: googleUserId,
      spaceName: '',
    };

    for (const field of dialogFormFields) {
      if (field.id === 'reminderContent') {
        body.reminderText = field.value;
      }

      if (field.id === 'spaceName') {
        body.spaceName = field.value;
      }

      if (field.id === 'reminderDate') {
        body.reminderDate = field.value;
      }
    }

    if (!reminderIdForUpdate) {
      // create reminder
      const { success: createReminderSuccess, content: reminder } =
        await createReminder(body);

      if (!createReminderSuccess || !reminder) {
        notify(UNHANDLED_ERROR);
        updateDialogueState('OPEN');

        return;
      }

      notify(CREATE_REMINDER_SUCCESS);
    } else {
      // update reminder
      const { success: updateReminderSuccess, content: updatedReminder } =
        await updateReminderById(reminderIdForUpdate, body);

      if (!updateReminderSuccess || !updatedReminder) {
        notify(UNHANDLED_ERROR);
        updateDialogueState('OPEN');

        return;
      }

      notify(UPDATE_REMINDER_SUCCESS);
    }

    setReminderIdForUpdate(null);

    updateDialogueState('CLOSED');

    fetchReminders();
  };

  const openDialogForCreateReminder = () => {
    setReminderIdForUpdate(null);

    updateDialogMetaData(
      DIALOG_TITLE_FOR_REMINDER,
      DIALOG_CONTENT_FOR_REMINDER,
      DialogFor.REMINDER,
      REMINDER_INITIAL_FIELDS,
      SMALL_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const confirmDeleteReminder = async () => {
    if (deleteReminderId) {
      updateDialogueState('LOADING');

      const { success: deleteReminderSuccess, content: deletedReminder } =
        await deleteReminderById(deleteReminderId);

      if (!deleteReminderSuccess || !deletedReminder) {
        notify(UNHANDLED_ERROR);
        updateDialogueState('OPEN');

        return;
      }

      notify(DELETE_REMINDER_SUCCESS);
      updateDialogueState('CLOSED');
      setDeleteReminderId(null);
      fetchReminders();
    }
  };

  const openDialogForEditReminder = (
    reminderDetails: any,
    reminderId: string,
  ) => {
    setReminderIdForUpdate(reminderId);
    const updatedFields = REMINDER_INITIAL_FIELDS.map((field) => {
      if (field.id === 'reminderContent') {
        field.value = reminderDetails.data.reminderText;
      } else if (field.id === 'spaceName') {
        field.value = reminderDetails.data.spaceName;
      } else if (field.id === 'reminderDate') {
        field.value = reminderDetails.data.reminderDate;
      }

      return field;
    });

    updateDialogMetaData(
      DIALOG_TITLE_FOR_UPDATE_REMINDER,
      DIALOG_CONTENT_FOR_UPDATE_REMINDER,
      DialogFor.REMINDER,
      updatedFields,
      SMALL_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const openDialogForDeleteReminder = (deleteReminderId: string) => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_REMINDER_DELETE,
      DialogFor.DELETE_REMINDER,
      [],
    );

    updateDialogueState('OPEN');

    setDeleteReminderId(deleteReminderId);
  };

  const fetchReminderById = async () => {
    const reminderId = searchParams.get('id');

    if (!reminderId) return;

    try {
      const { success: getReminderSuccess, content: reminder } =
        await getReminderById(reminderId);

      if (!getReminderSuccess || !reminder) {
        notify(REMINDER_NOT_FOUND_BY_ID);

        return;
      }

      openDialogForEditReminder(reminder, reminderId);
    } catch (error) {
      notify(UNHANDLED_ERROR);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: 'Description',
      width: DATA_GRID_COLUMN_WIDTH,
      renderCell: (params) => {
        return (
          <Typography
            component="p"
            variant="body1"
            sx={{ maxWidth: DATA_GRID_COLUMN_WIDTH, margin: '16px 0' }}
          >
            {truncateString(params.row.data.reminderText, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      width: DATA_GRID_COLUMN_WIDTH_7,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Typography component="p" variant="body1">
            {new Date(row.data.createTime).toLocaleDateString()}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: DATA_GRID_COLUMN_WIDTH_7,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            <Button
              id="edit-reminder"
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                openDialogForEditReminder(params.row, params.row.data.jobId)
              }
            >
              Edit
            </Button>
            <Button
              id="delete-reminder"
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => openDialogForDeleteReminder(params.row.data.jobId)}
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fetchReminders();
  }, [fetchReminders]);

  useEffect(() => {
    fetchReminderById();
  }, []);

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.REMINDER:
        handleCreateOrUpdateReminder();
        break;

      case DialogFor.DELETE_REMINDER:
        confirmDeleteReminder();
        break;
      default:
    }
  }, [dialogueState]);

  if (!userDetails) {
    return <ErrorBoundary message={UNHANDLED_ERROR} />;
  }

  return (
    <Box component="main">
      <ModuleHeader title="Reminders" />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginBottom: '16px',
        }}
      >
        <Button
          id="create-reminder"
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialogForCreateReminder}
        >
          {CREATE_REMINDER_BUTTON_TEXT}
        </Button>
      </Box>
      <Box>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight
          paginationMode="server"
          rows={reminders}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          disableColumnMenu
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          loading={loadingForDataGrid}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paginationModel.page - 1,
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          rowCount={rowCountState}
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
          onPaginationModelChange={(newPaginationModel) => {
            setPaginationModel((oldPaginationModel) => ({
              ...oldPaginationModel,
              page: newPaginationModel.page + 1,
              pageSize: newPaginationModel.pageSize,
            }));
          }}
        />
      </Box>
    </Box>
  );
});

export default Reminder;
