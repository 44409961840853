import { UserApiResponse } from 'src/types/user';

export interface UsageCardProps {
  count: number | string;
  title: string;
  subtitle: string;
}

export interface UsageCardWithRoles extends UsageCardProps {
  allowedRoles: UserApiResponse['role'][];
  id: string;
}

export interface InteractionCountByDay {
  [key: string]: string | number;
  label: string;
  count: number;
}

//  organization api response

export interface TotalSpacesCountApiResponse {
  total: number;
}

export interface TotalOrgInteractionCountApiResponse {
  count: number;
}

export enum BarChartTypes {
  UserBarChart = 'UserBarChart',
  OrgBarChart = 'OrgBarChart',
}

export interface CheckIsHanaAddedApiResponse {
  hasInteracted: boolean;
}
