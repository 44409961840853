import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import { CustomTextFieldProps } from 'src/types/registrationFormTypes';
import styleVariables from 'src/scss/_variables.scss';

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  isReadOnly = false,
  multiline,
  rows,
  textColor,
  placeholder,
  ...rest
}) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      id={name}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      margin="normal"
      multiline={multiline}
      rows={rows}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : ''}
      InputProps={{
        readOnly: isReadOnly,
        style: { color: textColor || styleVariables.colorWhite },
      }}
      {...field}
      {...rest}
      sx={{ marginTop: '8px' }}
    />
  );
};
