export enum PLAN_TYPES {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PRO = 'PRO',
}
export const PRICING_CARD_BUTTON_TITLE = 'Upgrade';

export const CURRENT_PLAN_TEXT = 'Current Plan';

export const PRICING_CARD_CURRENCY_SYMBOL = '$';
export const PRICING_CARD_FREE_PLAN_LABEL = 'Free';
