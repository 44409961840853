export const ROUTES = {
  overview: 'overview',
  memory: 'memory',
  users: 'users',
  spaces: 'spaces',
  reportGroups: 'report-groups',
  // integrations: 'integrations',
  help: 'help',
  settings: 'settings',
  reminder: 'reminder',
  crm: 'crm',
};

export const PATHS = {
  home: `/`,
  unAuthorized: `/auth`,
  overview: `/overview`,
  settings: `/settings`,
  profile: `profile`,
  onBoardRegistration: `/onBoard-registration`,
  reportGroups: `/report-groups`,
  reportMessages: `report-message/groups/:groupId`,
  memory: `/memory`,
  users: `/users`,
  help: `/help`,
  spaces: `/spaces`,
  pricingPlans: `/pricing-plans`,
  reminder: `/reminder`,
  payment: `/payment`,
  policyApproval: `/policy-approval`,
  crm: '/crm',
};
