export enum EMAIL_CAMPAIGN_NAMES {
  ANNOUNCEMENT_TO_ALL_USERS = 'announcement_to_all_users',
}

export interface AgendaJobResponse<T> {
  data: T;
  lastModifiedBy: null;
  name: string;
  nextRunAt: string;
  priority: number;
  shouldSaveResult: boolean;
  type: string;
  _id: string;
}

export interface ScheduleMailToAllUsersJobPayload {
  campaignName: EMAIL_CAMPAIGN_NAMES;
  emailScheduleIsoDate: string;
  sendGridTemplateId: string;
  superAdminOrgId: string;
}

export interface ScheduleDmAnnouncementToAllUsersJobPayload {
  campaignName: string;
  announcementScheduleIsoDate: string;
  superAdminOrgId: string;
}

export interface GetScheduledDmAnnouncementCustomDataApiResponse
  extends Omit<ScheduleDmAnnouncementToAllUsersJobPayload, 'superAdminOrgId'> {
  orgId: string;
  abortExecution: boolean;
  jobId: string;
}

export type GetScheduledDmAnnouncementApiResponse =
  AgendaJobResponse<GetScheduledDmAnnouncementCustomDataApiResponse>;

export interface GetScheduledMailCustomDataApiResponse
  extends Omit<ScheduleMailToAllUsersJobPayload, 'superAdminOrgId'> {
  orgId: string;
}

export type GetScheduledMailApiResponse =
  AgendaJobResponse<GetScheduledMailCustomDataApiResponse>;
