import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS, ROUTES } from 'src/constants/navigateRoutes';
import HANA_LOGO from '../../assets/Hana-Logo.png';
import styles from './SideNavBar.module.scss';
import UpgradeToPro from '../upgrade-to-pro/UpgradeToPro';
import styleVariables from '../../scss/_variables.scss';
import { CustomDivider } from '../custom-divider/CustomDivider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PsychologyIcon from '@mui/icons-material/Psychology';
import GroupsIcon from '@mui/icons-material/Groups';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import { observer } from 'mobx-react-lite';
import ErrorBoundary from '../error-boundary/ErrorBoundry';
import {
  ORGANIZATION_ID_NOT_FOUND_ERROR,
  USER_DETAILS_NOT_FOUND_ERROR,
} from 'src/constants/errors';
import CustomPersona from '../persona-by-plan/PersonaByPlan';
import { ROLES } from 'src/constants/Role';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { userLogout } from 'src/services/auth';

interface SideNavBarOptions {
  text: string;
  route: string;
  icon: React.ReactNode;
  access: Array<keyof typeof ROLES>;
}

export interface SideNavBarProps {
  open: boolean;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideNavBar = observer(({ open, onChange }: SideNavBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { organizationDetails } = useOrganizationStore();
  const { logout, getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();

  const handleChangePlan = async () => {
    navigate(`${PATHS.settings}?tab=billing`);
  };

  const handleNavigation = async (route: string) => {
    if (route === PATHS.unAuthorized) {
      await userLogout();
      logout();
    }

    navigate(route);
  };

  const sideNavBarOptions: SideNavBarOptions[] = [
    {
      text: 'Overview',
      route: ROUTES.overview,
      icon: <DashboardIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    {
      text: 'Memory',
      route: ROUTES.memory,
      icon: <PsychologyIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    {
      text: 'Users',
      route: ROUTES.users,
      icon: <GroupsIcon />,
      access: [ROLES.USER, ROLES.ADMIN, ROLES.SUPER_ADMIN],
    },
    {
      text: 'Spaces',
      route: ROUTES.spaces,
      icon: <WorkspacesIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    {
      text: 'Report Groups',
      route: ROUTES.reportGroups,
      icon: <SummarizeIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    {
      text: 'Reminders',
      route: ROUTES.reminder,
      icon: <NotificationsActiveIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    // {
    //   text: 'Integrations',
    //   route: ROUTES.integrations,
    //   icon: <IntegrationInstructionsIcon />,
    // },
    {
      text: 'Settings',
      route: ROUTES.settings,
      icon: <SettingsIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    {
      text: 'CRM',
      route: ROUTES.crm,
      icon: <ContactMailIcon />,
      access: [ROLES.SUPER_ADMIN],
    },
  ];

  const additionalSideNavBarOptions: SideNavBarOptions[] = [
    {
      text: 'Help/Learn',
      route: ROUTES.help,
      icon: <HelpIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    {
      text: 'Sign out',
      route: PATHS.unAuthorized,
      icon: <LogoutIcon />,
      access: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
  ];

  if (!userDetails) {
    return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;
  }

  if (!organizationDetails) {
    return <ErrorBoundary message={ORGANIZATION_ID_NOT_FOUND_ERROR} />;
  }

  const IS_NOT_PRO_PLAN = organizationDetails.planId.displayName !== 'PRO';

  return (
    <Drawer
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        gridTemplateColumns: 3,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '70vw', md: styleVariables.appDrawerWidth },
        },
        overflow: 'hidden',
      }}
      anchor="left"
      SlideProps={{
        direction: 'right',
      }}
      variant={isLargeScreen ? 'permanent' : 'temporary'}
      open={isLargeScreen ? true : open}
      onClose={isLargeScreen ? undefined : () => onChange(false)}
    >
      <Toolbar
        className={styles['toolbar']}
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(PATHS.overview)}
      >
        <CustomPersona currentPlan={organizationDetails.planId.displayName} />
        <img className={styles['hana-logo']} src={HANA_LOGO} />
      </Toolbar>
      <CustomDivider orientation="horizontal" />
      <div className={`hanabi-scrollbar ${styles['modules-wrapper']}`}>
        <List>
          {sideNavBarOptions.map(
            ({ text, route, icon, access }) =>
              access.includes(userDetails.role) && (
                <ListItem
                  className={
                    `/${route}` === location.pathname
                      ? styles['module-active']
                      : ''
                  }
                  key={text}
                  disablePadding
                >
                  <ListItemButton onClick={() => navigate(`/${route}`)}>
                    <ListItemIcon sx={{ color: styles.colorWhite }}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ),
          )}
        </List>
        <CustomDivider orientation="horizontal" />
        <List>
          {additionalSideNavBarOptions.map(
            ({ text, route, icon, access }) =>
              access.includes(userDetails.role) && (
                <ListItem
                  className={
                    `/${route}` === location.pathname
                      ? styles['module-active']
                      : ''
                  }
                  key={text}
                  disablePadding
                >
                  <ListItemButton onClick={() => handleNavigation(route)}>
                    <ListItemIcon sx={{ color: styles.colorWhite }}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ),
          )}
        </List>
      </div>
      <div className={styles['upgrade-container']}>
        {IS_NOT_PRO_PLAN && (
          <UpgradeToPro
            title="Sky rocket your productivity"
            subTitle="Upgrade for unlimited interactions"
            ctaText="Upgrade to Pro"
            handleChangePlan={handleChangePlan}
          />
        )}
      </div>
    </Drawer>
  );
});

export default SideNavBar;
