import styleVariables from '../scss/_variables.scss';

export const DATA_GRID_COLUMN_WIDTH = 500;

export const DATA_GRID_COLUMN_WIDTH_2 = 150;

export const DATA_GRID_COLUMN_WIDTH_3 = 170;

export const DATA_GRID_COLUMN_WIDTH_4 = 260;

export const DATA_GRID_COLUMN_WIDTH_5 = 250;

export const DATA_GRID_COLUMN_WIDTH_6 = 445;

export const DATA_GRID_COLUMN_WIDTH_7 = 255;

export const AVATAR_HEIGHT_WIDTH = 112;

export const STYLE_FOR_LARGE_DIALOG = {
  width: '80vw',
  height: '80vh',
  maxWidth: 'none',
  maxHeight: 'none',
};

export const STYLE_FOR_POP_DIALOG = {
  border: `1px solid ${styleVariables.hanabiThemeColorBrightOrangeRed}`,
  boxShadow: `0 0 4px ${styleVariables.hanabiThemeColorBrightOrangeRed}`,
  borderRadius: '12px',
  // overflow: 'auto',
};

export const DEFAULT_MULTILINE_ROW_FOR_TEXT_AREA = 5;

export const HANA_ACCESS_DAILOG_IMG_SIZE = 200;
