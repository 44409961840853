import { Box, IconButton, Typography } from '@mui/material'; // Avatar,
import { CustomDivider } from '../custom-divider/CustomDivider';

export interface ModuleHeaderProps {
  title: string;
  ModuleHeaderIcon?: React.ReactNode;
  onModuleHeaderIconClick?: () => void;
}

export const ModuleHeader = ({
  title,
  ModuleHeaderIcon,
  onModuleHeaderIconClick,
}: ModuleHeaderProps) => {
  return (
    <Box sx={{ marginBottom: '16px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {ModuleHeaderIcon && (
          <IconButton
            sx={{ padding: '0 8px 0 0' }}
            onClick={onModuleHeaderIconClick}
            disabled={!onModuleHeaderIconClick}
          >
            {ModuleHeaderIcon}
          </IconButton>
        )}
        <Typography
          variant="h4"
          component="h3"
          fontSize={'24px'}
          fontWeight={'bold'}
        >
          {title}
        </Typography>
      </Box>
      <CustomDivider orientation="horizontal" sx={{ marginTop: '16px' }} />
    </Box>
  );
};
