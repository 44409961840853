import {
  Box,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import CloseIcon from '@mui/icons-material/Close';

const CustomNotification = observer(() => {
  const {
    showNotification,
    notificationMessage,
    reset,
    callbackOfManualToastClose,
  } = useAlertStore();

  const handleToastClose = () => {
    if (callbackOfManualToastClose) {
      callbackOfManualToastClose();
    }

    reset();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={showNotification}
      sx={{
        '& .MuiSnackbarContent-root': {
          display: 'block',
        },
      }}
    >
      <SnackbarContent
        message={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body1">{notificationMessage}</Typography>
            <IconButton
              key="close"
              aria-label="close"
              onClick={handleToastClose}
              sx={{ marginLeft: 'auto' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        }
      />
    </Snackbar>
  );
});

export default CustomNotification;
