import { Box, CssBaseline, Grid, Typography, Link } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import { CustomDivider } from 'src/components/custom-divider/CustomDivider';
import { HELP_QUICK_START_VIDEOS } from 'src/constants/Help';
import VideoCard from 'src/components/video-card/VideoCard';
import styleVariables from '../../scss/_variables.scss';
import {
  DOCUMENTATION,
  FEEDBACK_URL,
  HANA_DISCORD_INVITE,
} from 'src/constants/Urls';
import { SUPPORT_EMAIL } from 'src/constants/Developer';

const Help = observer(() => {
  return (
    <>
      <ModuleHeader title={'Help'} />
      <Box sx={{ display: 'flex', position: 'relative', marginBottom: '32px' }}>
        <CssBaseline />
        <Grid container spacing={2}>
          {HELP_QUICK_START_VIDEOS.map((props, index) => (
            <Grid item xs={12} md={4} key={index}>
              <VideoCard {...props} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <CustomDivider />
      <Typography sx={{ marginTop: '16px' }} component="p" variant="h5">
        STILL NEED HELP?
      </Typography>
      <Typography sx={{ marginTop: '16px' }} component="p" variant="body1">
        Read -{' '}
        <Link
          id="doc_url"
          target="_blank"
          rel="noopener"
          href={DOCUMENTATION}
          color={styleVariables.hanabiThemeColorDarkRed}
        >
          Documentation
        </Link>
      </Typography>
      <Typography sx={{ marginTop: '16px' }} component="p" variant="body1">
        Contact us at -{' '}
        <Link
          id="mail_url"
          target="_blank"
          rel="noopener"
          href={`mailto:${SUPPORT_EMAIL}`}
          color={styleVariables.hanabiThemeColorDarkRed}
        >
          {SUPPORT_EMAIL}
        </Link>
      </Typography>
      <Typography sx={{ marginTop: '16px' }} component="p" variant="body1">
        Feedback form -{' '}
        <Link
          id="google_form_url"
          target="_blank"
          rel="noopener"
          href={FEEDBACK_URL}
          color={styleVariables.hanabiThemeColorDarkRed}
        >
          Google Form
        </Link>
      </Typography>{' '}
      <Typography sx={{ marginTop: '16px' }} component="p" variant="body1">
        Join Discord Community -{' '}
        <Link
          id="google_form_url"
          target="_blank"
          rel="noopener"
          href={HANA_DISCORD_INVITE}
          color={styleVariables.hanabiThemeColorDarkRed}
        >
          Discord Channel
        </Link>
      </Typography>
    </>
  );
});

export default Help;
