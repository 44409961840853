import { Dialog, MobileStepper } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import GetStepContent from './OnboardinSteps';
import { STYLE_FOR_POP_DIALOG } from 'src/constants/Layout';
import styleVariable from './onboarding.hana.module.scss';
import CloseIcon from '@mui/icons-material/Close';

interface OnboardingPopupProps {
  openDialog: boolean;
  setModal: (value: boolean) => void;
}

const STEPS = [1, 2];

const OnboardingPopup: React.FC<OnboardingPopupProps> = observer(
  ({ openDialog, setModal }) => {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = STEPS.length;

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    return (
      <Dialog open={openDialog} PaperProps={{ sx: STYLE_FOR_POP_DIALOG }}>
        <div className={styleVariable['dialog-box']}>
          <CloseIcon
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={() => {
              setModal(false);
            }}
          />
          <GetStepContent
            stepIndex={activeStep}
            start={handleNext}
            setModal={setModal}
          />

          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<></>}
            backButton={<></>}
            sx={{
              padding: '28px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& .MuiMobileStepper-dot:not(.MuiMobileStepper-dotActive)': {
                backgroundColor: styleVariable.slateGray,
              },
            }}
          />
        </div>
      </Dialog>
    );
  },
);

export default OnboardingPopup;
