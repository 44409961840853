import HanaHero from '../../assets/hana-hero.webp';
import { useGoogleLogin } from '@react-oauth/google';
import styles from './auth.module.scss';
import { AUTH_FOR } from '../../types/oAuth';
import { OAUTH_REDIRECT_URI } from '../../constants/ApiRoutes';
import google from '../../assets/devicon_google.svg';
import googleMarketPlace from '../../assets/market-place.webp';
import downloadIcon from '../../assets/download-icon.webp';
import { HANABI_WEBSITE } from 'src/constants/Urls';
import { BOT_NAME } from 'src/constants/Bot';
import { SCOPES } from 'src/constants/Auth';
import { Box, Link, Rating, Typography } from '@mui/material';
import { DEVELOPER_NAME } from 'src/constants/Developer';
import Announcement from 'src/components/announcement/Announcement';
import { FREE_PRO_PLAN_MESSAGE } from 'src/constants/Announcements';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  EDITORS_CHOICE_RATINGS,
  GOOGLE_WORKSPACE_MARKET_PLACE_INSTALL_COUNT,
} from 'src/constants/industryStaticData';

const AuthPage = () => {
  const OAUTH_LOGIN_FLOW = 'auth-code';
  const OAUTH_UX_MODE = 'redirect';

  const oAuthLogin = useGoogleLogin({
    flow: OAUTH_LOGIN_FLOW,
    ux_mode: OAUTH_UX_MODE,
    state: JSON.stringify({
      authFor:
        process.env.NODE_ENV === 'development'
          ? AUTH_FOR.FRONT_END_DEV
          : AUTH_FOR.FRONT_END_PROD,
    }),
    redirect_uri: OAUTH_REDIRECT_URI,
    scope: SCOPES.join(' '),
  });

  return (
    <>
      <Announcement
        texts={[FREE_PRO_PLAN_MESSAGE]}
        icon={<CloseOutlinedIcon sx={{ color: styles.darkNavyBlue }} />}
      />
      <Box className={`hanabi-scrollbar ${styles['auth-main-container']}`}>
        <Box className={styles['cta-container']}>
          <div className={styles['heading-container']}>
            <h1 className={styles['hero-heading']}>
              <span className="special-text">{BOT_NAME}</span> says, Hii!
            </h1>

            <div className={styles['info-text-div']}>
              <p className={styles['info-text']}>
                As your dedicated business assistant, I'm here to elevate your
                productivity and streamline your workflow.
              </p>
              <p className={styles['info-text']}>
                Explore my features at your own pace—I've designed the
                experience to be intuitive and user-friendly. Learn about my
                capabilities, integrate my services seamlessly into your
                business, and watch your efficiency soar.
              </p>
              <p className={styles['info-text']}>
                This journey is tailored to meet your unique needs, and I'm
                excited to become an invaluable asset on your business path!
              </p>
            </div>

            <div
              id="sign-in"
              onClick={oAuthLogin}
              className={styles['cta-button']}
            >
              <img src={google} alt="google-icon" />
              <span> Sign in with Google </span>
            </div>
          </div>

          <div className={styles['trust-mark-div']}>
            <div className={styles['market-place-div']}>
              <img src={googleMarketPlace} alt="google-market-place" />
              <Typography
                component="p"
                variant="body1"
                className={styles['market-place-div-p']}
              >
                Google Workspace Marketplace™
              </Typography>
            </div>
            <h3 className={styles['editor-choice-div-heading']}>
              EDITOR’S CHOICE
            </h3>

            <div className={styles['editor-choice-div']}>
              <div className={styles['ratings-div']}>
                <Rating
                  name="read-only"
                  value={5}
                  readOnly
                  sx={{ color: styles.yellowGold }}
                />{' '}
                <Typography>{EDITORS_CHOICE_RATINGS}</Typography>
              </div>{' '}
              <div className={styles['download-div']}>
                <img src={downloadIcon} alt="download-icon" />
                <Typography component="span" variant="body2">
                  {`${GOOGLE_WORKSPACE_MARKET_PLACE_INSTALL_COUNT}+`}
                </Typography>{' '}
              </div>
            </div>
          </div>

          <div className={styles['about-hana-tech']}>
            <Typography component="p" variant="body1">
              Today solve private limited. A product of{' '}
              <Link
                href={HANABI_WEBSITE}
                referrerPolicy="no-referrer"
                target="_blank"
              >
                {DEVELOPER_NAME}
              </Link>
            </Typography>

            <br />
            {process.env.REACT_APP_ENVIRONMENT && (
              <Typography
                component="p"
                variant="body2"
                sx={{ textTransform: 'capitalize' }}
              >
                {process.env.REACT_APP_ENVIRONMENT}
              </Typography>
            )}
          </div>
        </Box>
        <Box className={styles['hero-image-container']}>
          <img
            className={styles['hero-image']}
            src={HanaHero}
            alt={`${BOT_NAME} is an AI assistant developed by ${DEVELOPER_NAME} integrated directly in Google Workspace.`}
          />
        </Box>
      </Box>
    </>
  );
};

export default AuthPage;
