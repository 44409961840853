export const PAGE_SIZE_OPTIONS = [5, 10];

export const WORDS_PER_ROW_CELL = 100;

export const truncateString = (str: string, num: number): string => {
  if (!str) return 'NA';

  if (str.length <= num) {
    return str;
  }

  return str.slice(0, num).trim() + '...';
};

export const extractGoogleUserId = (userId: string) => {
  const parts = userId.split('/');

  return parts.length > 1 ? parts[1] : null;
};
