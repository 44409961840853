import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useField } from 'formik';
import { CustomSelectProps } from 'src/types/registrationFormTypes';
import styleVariables from 'src/scss/_variables.scss';

export const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  label,
  options,
  textColor,
  isReadOnly = false,
  alertMessage,
}) => {
  const [field, meta] = useField(name);
  const showError = meta.touched && meta.error;

  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin="normal"
      sx={{ marginTop: '8px' }}
    >
      <InputLabel id={`${name}-label`} error={meta.touched && !!meta.error}>
        {label}
      </InputLabel>
      <Select
        {...field}
        labelId={`${name}-label`}
        label={label}
        value={field.value || ''}
        error={meta.touched && !!meta.error}
        onChange={field.onChange}
        onBlur={field.onBlur}
        readOnly={isReadOnly}
        sx={{ color: textColor || styleVariables.colorWhite }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={name === 'country' ? option.id : option.name}
          >
            {option.name} {option.range}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error>{meta.error}</FormHelperText>}
      {meta.touched && !!alertMessage && (
        <FormHelperText> {alertMessage} </FormHelperText>
      )}
    </FormControl>
  );
};
