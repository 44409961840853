import React, { useState, useEffect } from 'react';
import { LinearProgress, Box, Typography, SxProps, Theme } from '@mui/material';
import {
  INTERVAL_DURATION,
  MAX_PROGRESS,
  PROGRESS_BASE_PERCENTAGE,
  PROGRESS_INCREMENT_HIGH,
  PROGRESS_INCREMENT_LOW,
} from 'src/constants/customProgressBar';
import { keyframes } from '@mui/material/styles';
import styleVariables from 'src/scss/_variables.scss';

const calculateUserVisibleProgress = (calculatedProgress: number) => {
  return (
    Math.round((Math.atan(calculatedProgress) / (Math.PI / 2)) * 100 * 1000) /
    1000
  );
};

const calculateProgress = (prevProgress: number) => {
  const newCalculatedProgress =
    prevProgress +
    (prevProgress >= PROGRESS_BASE_PERCENTAGE
      ? PROGRESS_INCREMENT_LOW
      : PROGRESS_INCREMENT_HIGH);
  const nextUserVisibleProgress = calculateUserVisibleProgress(
    newCalculatedProgress,
  );

  return { newCalculatedProgress, nextUserVisibleProgress };
};

interface CustomProgressBarProps {
  title: string;
  progressBarWrapperStyle?: SxProps<Theme>;
}

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({
  title,
  progressBarWrapperStyle,
}) => {
  const [calculatedProgress, setCalculatedProgress] = useState(0);

  const stripes = keyframes`
  from {
    background-position: 100% 100%;
  }
  to {
    background-position: 0 0;
  }
`;

  useEffect(() => {
    const updateProgress = () => {
      setCalculatedProgress((prevProgress) => {
        const { newCalculatedProgress, nextUserVisibleProgress } =
          calculateProgress(prevProgress);

        if (nextUserVisibleProgress >= MAX_PROGRESS) {
          clearInterval(intervalId);
        }

        return newCalculatedProgress;
      });
    };

    const intervalId = setInterval(updateProgress, INTERVAL_DURATION);

    return () => clearInterval(intervalId);
  }, []);

  const userVisibleProgress =
    calculateUserVisibleProgress(calculatedProgress).toFixed(0);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        ...progressBarWrapperStyle,
      }}
    >
      {title && (
        <Typography
          sx={{
            fontSize: '16px',
            textAlign: 'center',
            marginBottom: '8px',
          }}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={{
          width: '100%',
          height: 8,
          borderRadius: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '80%' }}>
          <LinearProgress
            sx={{
              height: 8,
              borderRadius: 5,
              backgroundImage: styleVariables.loaderStripeLinearGradient,
              backgroundSize: `200% 200%`,
              animation: `${stripes} 8s linear infinite`,
              '& .MuiLinearProgress-bar': {
                display: 'none', // Hide the default linear progress bar
              },
            }}
          />
        </Box>
        <Typography
          variant="h5"
          fontSize="12px"
          fontWeight="bold"
        >{`${userVisibleProgress}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CustomProgressBar;
