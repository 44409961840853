import { YouTubeVideo } from 'src/types/videosTypes';
import { BOT_NAME } from './Bot';

export const HELP_QUICK_START_VIDEOS: Array<YouTubeVideo> = [
  {
    youtubeVideoId: 'VEX56OK-jRE',
    title: `How to Add ${BOT_NAME} to Your Workspace - Step-by-Step Guide`,
    subtitle: `Learn how to seamlessly integrate ${BOT_NAME}, your AI-powered team assistant, into your Google Workspace to enhance productivity and collaboration.`,
  },
  {
    youtubeVideoId: 'ujP1e2uAxhQ',
    title: `Real-Life Example of ${BOT_NAME}'s Slash Commands and Chat with your preferred language`,
    subtitle: `Discover how ${BOT_NAME}'s slash commands, like /pdf and /browse, boost productivity by reading Google Docs, engaging in conversations in your preferred language, and answering questions in real-time.`,
  },
  {
    youtubeVideoId: 'z3Cuo4faSkw',
    title: 'Real-Life Example of Meet and Report Groups Commands!',
    subtitle: `Using the /meet and /reportGroups command, ${BOT_NAME} can analyze Google Meet transcripts and generate detailed reports. These features help you keep track of meetings and manage reporting efficiently.`,
  },
];
