export const HANABI_WEBSITE = 'https://hanabitech.com';

export const DOCUMENTATION =
  'https://hanabitech.notion.site/Welcome-To-Hana-Documentation';

export const FEEDBACK_URL = `https://forms.gle/b311Vff2aKzxXqZn9`;

export const HANABI_PRIVACY_POLICY =
  'https://hana.hanabitech.com/privacy-policy';

export const HANABI_TERMS_AND_CONDITIONS =
  'https://hana.hanabitech.com/terms-of-service';

export const HANABI_YOUTUBE_URL =
  'https://www.youtube.com/watch?v=fZs7W3XQBUw&list=PL9a9H540slPdDqxVRhz8j9aju_JREd1oH&index=2';

export const GOOGLE_CHAT_LINK = 'https://mail.google.com/chat/u/0/#chat/home';

export const HANA_DISCORD_INVITE = 'https://discord.gg/SxDhTZy8zj';
