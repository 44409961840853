import axios, { AxiosInstance } from 'axios';
import { BACKEND_BASE_URL } from './ApiRoutes';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BACKEND_BASE_URL,
  withCredentials: true,
});

export enum ERROR_STATUS_CODE {
  FORBIDDEN = 403,
}
