import { UNHANDLED_ERROR } from 'src/constants/errors';
import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { ApiResponse, PaginatedResponse } from 'src/types/commonResponseTypes';
import {
  DeleteUsersApiResponse,
  DeleteUsersPayload,
  UpdateUserActiveStatus,
  UpdateUserResponse,
  UpdateUserRole,
  UserApiResponse,
} from 'src/types/user';

export const getUserInfoByCookie = async (): Promise<
  ApiResponse<UserApiResponse>
> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getUserInfoByCookie']();
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const validateMongoUserId = async (
  userId: string,
): Promise<ApiResponse<boolean>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['validateMongoUserId'](userId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getAllUsers = async (
  orgId: string,
  page: number,
  limit: number,
): Promise<ApiResponse<PaginatedResponse<UserApiResponse[]>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getAllUsers'](orgId, page, limit);

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const updateUserActiveStatus = async (
  body: UpdateUserActiveStatus,
): Promise<ApiResponse<UpdateUserResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateUserActiveStatus']();
    const { data } = await axiosInstance.patch(apiUrl, body);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const updateUserRole = async (
  body: UpdateUserRole,
): Promise<ApiResponse<UpdateUserResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateUserRole']();
    const { data } = await axiosInstance.patch(apiUrl, body);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const deleteUsersByIds = async (
  deleteUsersPayload: DeleteUsersPayload,
): Promise<ApiResponse<DeleteUsersApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['deleteUsers']();
    const { data } = await axiosInstance.delete(apiUrl, {
      data: deleteUsersPayload,
    });

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
