import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import { getSessionDetails } from 'src/services/auth';
import { Box } from '@mui/material';
import CustomLoading from 'src/components/custom-loading';
import styleVariables from '../../scss/_variables.scss';

interface GuestRouteProps {
  children: ReactNode;
}

const GuestRoute: React.FC<GuestRouteProps> = observer(({ children }) => {
  const [isFetching, setIsFetching] = useState(true);

  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    setIsFetching(true);
    const { success: getSessionInfoSuccess, content: sessionInfo } =
      await getSessionDetails();

    if (getSessionInfoSuccess && sessionInfo) {
      navigate(PATHS.home);
    }

    setIsFetching(false);
  }, [navigate]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (isFetching) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          background: styleVariables.midnightIndigo,
        }}
      >
        <CustomLoading />
      </Box>
    );
  }

  // If the user is not authenticated, render the children components
  return <React.Fragment>{children}</React.Fragment>;
});

export default GuestRoute;
