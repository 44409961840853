import { ComponentType, FC } from 'react';
import { ROLES } from 'src/constants/Role';
import { SettingModuleTabType } from 'src/pages/setting/Settings';
import { SettingsTabs } from 'src/types/settings';

const FallbackComponent: FC = () => null;

export const loadTabContent = async (
  tabValue: keyof typeof SettingsTabs,
): Promise<{ default: ComponentType<any> }> => {
  switch (tabValue) {
    case SettingsTabs.billing:
      const module = await import('src/pages/billing/Index');

      return {
        default: module.default,
      };
    case SettingsTabs.security:
      const securityModule = await import('src/pages/security/Index');

      return {
        default: securityModule.default,
      };
    case SettingsTabs.profile:
      const profileModule = await import('src/pages/profile/Index');

      return {
        default: profileModule.default,
      };
    case SettingsTabs.organization:
    case SettingsTabs.individualUser:
      const organizationProfileUpdateModule = await import(
        'src/pages/organization-profile-update/Index'
      );

      return {
        default: organizationProfileUpdateModule.default,
      };
    default:
      return Promise.resolve({ default: FallbackComponent });
  }
};

export const getSettingModuleTabs = (
  userRole: keyof typeof ROLES,
): SettingModuleTabType[] => {
  return [
    {
      value: 'profile',
      label: 'Profile',
      allowedRoles: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
        ROLES.SUPER_ADMIN,
      ],
    },
    {
      value:
        userRole === ROLES.INDIVIDUAL_USER_ADMIN
          ? 'individualUser'
          : 'organization',
      label:
        userRole === ROLES.INDIVIDUAL_USER_ADMIN
          ? 'Edit Your details'
          : 'Organization',
      allowedRoles: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
        ROLES.SUPER_ADMIN,
      ],
    },
    {
      value: 'billing',
      label: 'Billing',
      allowedRoles: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
    {
      value: 'security',
      label: 'Security',
      allowedRoles: [
        ROLES.USER,
        ROLES.ADMIN,
        ROLES.SUPER_ADMIN,
        ROLES.INDIVIDUAL_USER_ADMIN,
      ],
    },
  ];
};
