import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useDialogStore } from '../../stores/DialogStore/DialogStore';
import React from 'react';
import variableStyle from '../../scss/_variables.scss';
import { CustomDivider } from '../custom-divider/CustomDivider';
import CustomLoading from '../custom-loading';
import DateAndTimePicker from '../date-and-time-picker/DateAndTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import styles from './CustomDialog.module.scss';
import { SPACE_ID_REGEX } from 'src/constants/Regex';

export interface FormField {
  label: string;
  id: string;
  value: string;
  type: string;
  maxLength?: number;
  helperText?: React.ReactNode;
  fieldHeading?: string;
}

const TEXT_FIELD_STYLES = {
  textareaInput: {
    border: `1.5px solid ${variableStyle.slateGray}`,
    borderRadius: '4px',
    padding: '8px',
  },
  maxLengthHelperText: { textAlign: 'right' },
};

const CustomDialog = observer(() => {
  const {
    dialogueState,
    dialogFormFields,
    updateDialogMetaData,
    dialogTitle,
    dialogContent,
    dialogType,
    dialogStyle,
    updateDialogueState,
    inputFieldRow,
  } = useDialogStore();

  const handleChange = (fieldId: string, value: string) => {
    if (updateDialogMetaData && dialogType) {
      const updatedFields = dialogFormFields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              value:
                field.id === 'spaceName'
                  ? value.replace(SPACE_ID_REGEX, 'spaces/')
                  : value,
            }
          : field,
      );

      updateDialogMetaData(
        dialogTitle,
        dialogContent,
        dialogType,
        updatedFields,
        inputFieldRow,
        dialogStyle,
      );
    }
  };

  const handleDialogClose = () => {
    updateDialogueState('CLOSED');
  };

  if (dialogueState === 'CLOSED') {
    return null;
  }

  const getDialogueContent = () => {
    if (!dialogType) {
      return (
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DialogContentText>
            <Typography component="p">Invalid dialogue type</Typography>
          </DialogContentText>
        </DialogContent>
      );
    }

    if (dialogueState === 'LOADING') {
      return (
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CustomLoading
            sx={{
              color: variableStyle.hanabiThemeColorBrightOrangeRed,
            }}
          />
        </DialogContent>
      );
    }

    return (
      <>
        <DialogContent sx={{ padding: '0 24px' }}>
          <DialogContentText>
            <Typography component="p">{dialogContent}</Typography>
          </DialogContentText>
          {dialogFormFields.map((field: FormField) => {
            if (field.type === 'date-time') {
              return (
                <React.Fragment key={field.id}>
                  <Typography
                    sx={{
                      marginTop: '14px',
                      marginBottom: '4px',
                      fontSize: '20px',
                      textTransform: 'capitalize',
                    }}
                    component="p"
                  >
                    {field.fieldHeading}
                  </Typography>
                  <DateAndTimePicker
                    key={field.id}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(newValue: Dayjs | null) => {
                      const formattedValue = newValue
                        ? newValue.toISOString()
                        : '';

                      handleChange(field.id, formattedValue);
                    }}
                  />
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={field.id}>
                  {field.fieldHeading && (
                    <Typography
                      sx={{
                        marginTop: '14px',
                        marginBottom: '4px',
                        fontSize: '20px',
                        textTransform: 'capitalize',
                      }}
                      component="p"
                    >
                      {field.fieldHeading}
                    </Typography>
                  )}

                  <TextField
                    key={field.id}
                    margin="dense"
                    id={field.id}
                    value={field.value || ''}
                    placeholder={field.label}
                    type={field.type}
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleChange(field.id, e.target.value)}
                    multiline={field.type === 'textarea'}
                    rows={inputFieldRow}
                    inputProps={{ maxLength: field.maxLength }}
                    InputProps={{
                      style: TEXT_FIELD_STYLES.textareaInput,
                    }}
                    helperText={
                      field.maxLength ? (
                        <span className={styles.maxLengthHelperText}>
                          {field.value.length}/{field.maxLength}
                        </span>
                      ) : (
                        field.helperText
                      )
                    }
                  />
                </React.Fragment>
              );
            }
          })}
        </DialogContent>
        <DialogActions sx={{ paddingRight: '24px', gap: '8px' }}>
          <Button
            id="cancel"
            variant="contained"
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            id="submit"
            variant="contained"
            onClick={() => {
              if (dialogType) {
                updateDialogueState('ACCEPT');
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog
      open={dialogueState === 'OPEN' || dialogueState === 'LOADING'}
      onClose={handleDialogClose}
      className="hanabi-scrollbar"
      PaperProps={{ sx: dialogStyle }}
    >
      <DialogTitle
        sx={{
          fontSize: '24px',
          textTransform: 'capitalize',
        }}
      >
        <Typography sx={{ marginBottom: '12px' }} component="p" variant="h5">
          {dialogTitle}
        </Typography>
        <CustomDivider orientation="horizontal" />
      </DialogTitle>
      {getDialogueContent()}
    </Dialog>
  );
});

export default CustomDialog;
