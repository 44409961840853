import { UNHANDLED_ERROR } from 'src/constants/errors';
import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { ApiResponse } from 'src/types/commonResponseTypes';
import {
  CheckDnsVerificationApiResponse,
  CheckOnboardingCompleteApiResponse,
  OrganizationApiResponse,
  OrganizationApiResponseWithPopulatedPlan,
} from 'src/types/organization';

export const checkDnsVerification = async (
  orgId: string,
): Promise<ApiResponse<CheckDnsVerificationApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['checkDnsVerification'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const checkOnboardingComplete = async (
  orgId: string,
): Promise<ApiResponse<CheckOnboardingCompleteApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['checkOnboardingComplete'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const updateOrganizationDetails = async (
  orgId: string,
  body: Omit<Partial<OrganizationApiResponse>, 'planId' | 'domainName' | '_id'>,
): Promise<ApiResponse<OrganizationApiResponseWithPopulatedPlan>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateOrganization'](orgId);
    const { data } = await axiosInstance.patch(apiUrl, body);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getOrganizationDetailsByOrgId = async (
  orgId: string,
): Promise<ApiResponse<OrganizationApiResponseWithPopulatedPlan>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getOrganizationDetailsByOrgId'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const validateOrganizationId = async (
  orgId: string,
): Promise<ApiResponse<boolean>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['validateOrganizationId'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const deleteOrganizationById = async (
  orgId: string,
  mongoUserId: string,
): Promise<ApiResponse<OrganizationApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['deleteOrganizationById'](
      orgId,
      mongoUserId,
    );
    const { data } = await axiosInstance.delete(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
