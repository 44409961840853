import { BOT_NAME } from './Bot';

export const CREATE_GROUP_SUCCESS = 'Report group successfully created.';

export const UPDATE_GROUP_SUCCESS = 'Report group successfully updated.';

export const DELETE_GROUP_SUCCESS = 'Report group successfully deleted.';

export const DIALOG_TITLE_FOR_DELETE = 'Confirm delete';

export const DIALOG_CONTENT_FOR_DELETE = 'Are you sure?';

export const DIALOG_CONTENT_FOR_REPORT_DELETE =
  'Are you sure you want to delete the report group?';

export const DIALOG_CONTENT_FOR_MEMORY_DELETE =
  'Are you sure you want to delete the memory?';

export const DIALOG_TITLE_FOR_REPORT_GROUP = 'Create a group';

export const DIALOG_CONTENT_FOR_REPORT_GROUP =
  'To create a new group, please enter the details below.';

export const DIALOG_TITLE_FOR_UPDATE_REPORT_GROUP = 'Edit a group';

export const DIALOG_CONTENT_FOR_UPDATE_REPORT_GROUP =
  'To update a group, please enter the details below.';

export const CREATE_REPORT_GROUP_BUTTON_TEXT = '+ Create Group';

export const DIALOG_TITLE_FOR_REPORT_MESSAGE = 'Create a message';

export const DIALOG_CONTENT_FOR_REPORT_MESSAGE =
  'To create a message, please enter the details below.';

export const CREATE_MESSAGE_SUCCESS = 'Report message successfully created.';

export const UPDATE_MESSAGE_SUCCESS = 'Report message successfully updated.';

export const DELETE_MESSAGE_SUCCESS = 'Report message successfully deleted.';

export const DIALOG_TITLE_FOR_MEMORY = 'Create a memory';

export const DIALOG_CONTENT_FOR_MEMORY =
  'To create a memory, please enter the details below.';

export const DIALOG_TITLE_FOR_UPDATE_MEMORY = 'Edit a memory';

export const DIALOG_CONTENT_FOR_UPDATE_MEMORY =
  'To update a memory, please enter the details below.';

export const NO_REPORT_GROUPS_PLACEHOLDER = 'No report groups found';

export const NO_MEMORY_LIST_PLACEHOLDER = 'No memory lists found';

export const CREATE_MEMORY_SUCCESS = 'Memory successfully created.';

export const UPDATE_MEMORY_SUCCESS = 'Memory successfully updated.';

export const DELETE_MEMORY_SUCCESS = 'Memory successfully deleted.';

export const CREATE_MEMORY_BUTTON_TEXT = '+ Create Memory';

export const CREATE_MESSAGE_BUTTON_TEXT = '+ Create message';

export const DELETED_USER_PLACEHOLDER_TEXT = 'Deleted user';

export const NO_USER_LIST_PLACEHOLDER = 'No users found';

export const UPDATE_USER_ACCESS = `${BOT_NAME} access successfully updated.`;

export const UPDATE_USER_ROLE = 'Role successfully updated.';

export const USER_BAR_CHART_LABEL = 'User interaction count';

export const ORG_BAR_CHART_LABEL = 'Org interaction count';

export const DIALOG_TITLE_UPDATE_ROLE = 'Update Role';

export const DIALOG_MESSAGE_UPDATE_ROLE = 'Are you sure about this action?';

export const DIALOG_TITLE_REMOVE_ACCESS = 'Remove Access';

export const DIALOG_TITLE_GRANT_ACCESS = 'Grant Access';

export const dialogMessageRemoveAccess = (displayName: string) =>
  `${displayName} wont be able to access ${BOT_NAME} after this action!`;

export const dialogMessageGrantAccess = (displayName: string) =>
  `Granting access will allow ${displayName} to use ${BOT_NAME}!`;

export const NO_SPACES_PLACEHOLDER = 'No spaces found';
export const DIALOG_CONTENT_FOR_ORGANIZATION_DELETE =
  'Are you sure you want to delete the organization?';
export const DELETE_ORGANIZATION_SUCCESS = 'Organization successfully deleted.';

export const DIALOG_CONTENT_FOR_INDIVIDUAL_USER_DELETE =
  'Are you sure you want to delete your account?';
export const DELETE_INDIVIDUAL_USER_SUCCESS =
  'Your account successfully deleted.';

export const DIALOG_CONTENT_FOR_USER_DELETE =
  'Are you sure you want to delete the user?';

export const DELETE_USER_SUCCESS = 'User successfully deleted.';

export const ALERT_TO_CLICK_CKECK_BOX = `Please click on check box to submit.`;

export const ADD_HANA_MESSAGE = `Please add ${BOT_NAME} to Google Chat and say @${BOT_NAME} hi to create an interaction.`;

export const DELETE_REMINDER_SUCCESS = 'Reminder successfully deleted.';

export const DIALOG_CONTENT_FOR_REMINDER_DELETE =
  'Are you sure you want to delete the reminder?';

export const CREATE_REMINDER_SUCCESS = 'Reminder successfully created.';

export const UPDATE_REMINDER_SUCCESS = 'Reminder successfully updated.';

export const DIALOG_TITLE_FOR_UPDATE_REMINDER = 'Edit a reminder';

export const DIALOG_CONTENT_FOR_UPDATE_REMINDER =
  'To update a reminder, please enter the details below.';

export const DIALOG_TITLE_FOR_CANCEL_PLAN = `Change to Free plan`;

export const DIALOG_CONTENT_FOR_CANCEL_PLAN = `Are you sure you want to cancel existing plan and change to Free plan?`;

export const DIALOG_CONTENT_FOR_CANCEL_PLAN_IF_PAST_DUE = `Are you sure you want to cancel the existing plan and switch to the Free plan? If you proceed, the current billing plan will be canceled, and you will be considered a new subscriber.`;

export const CHANGE_PLAN_SUCCESS = (planName: string) =>
  `Subscription changed to ${planName} plan successfully.`;

export const CREATE_REMINDER_BUTTON_TEXT = '+ Create Reminder';

export const DIALOG_TITLE_FOR_REMINDER = 'Create a reminder';

export const DIALOG_CONTENT_FOR_REMINDER =
  'To create a reminder, please enter the details below.';

export const DIALOG_CONTENT_MAX_LENGTH = 2000;

export const LARGE_DIALOG_TEXTFIELD_ROWS = 19;

export const SMALL_DIALOG_TEXTFIELD_ROWS = 7;

export const SCHEDULE_MAIL_BUTTON_TEXT = '+ Schedule mail';

export const DIALOG_TITLE_FOR_SCHEDULE_MAIL = 'Schedule a mail';

export const DIALOG_CONTENT_FOR_SCHEDULE_MAIL =
  'To schedule a mail, please enter the details below.';

export const SCHEDULE_MAIL_SUCCESS = 'Mail successfully scheduled.';

export const DELETE_ORG_BUTTON_TEXT = 'Delete Org';

export const DELETE_USER_BUTTON_TEXT = 'Delete User';

export const DIALOG_TITLE_FOR_DELETE_ORG = 'Delete a organization';

export const DIALOG_CONTENT_FOR_DELETE_ORG =
  'To delete a organization, please enter the details below.';

export const DIALOG_TITLE_FOR_DELETE_USER = 'Delete a user';

export const DIALOG_CONTENT_FOR_DELETE_USER =
  'To delete a user, please enter the details below.';

export const SCHEDULE_DM_ANNOUNCEMENT_BUTTON_TEXT =
  '+ Schedule dm announcement';

export const DIALOG_TITLE_FOR_SCHEDULE_DM_ANNOUNCEMENT =
  'Schedule a dm announcement';

export const DIALOG_CONTENT_FOR_SCHEDULE_DM_ANNOUNCEMENT =
  'To schedule a dm announcement, please enter the details below.';

export const SCHEDULE_DM_ANNOUNCEMENT_SUCCESS =
  'Dm announcement successfully scheduled.';

export const DIALOG_CONTENT_FOR_DM_ANNOUNCEMENT_DELETE =
  'Are you sure you want to delete the dm announcement?';

export const DELETE_DM_ANNOUNCEMENT_SUCCESS =
  'Dm announcement successfully deleted.';

export const DIALOG_TITLE_FOR_ABORT = 'Confirm abort';

export const DIALOG_CONTENT_FOR_DM_ANNOUNCEMENT_ABORT =
  'Are you sure you want to abort the dm announcement?';

export const ABORT_DM_ANNOUNCEMENT_SUCCESS =
  'Dm announcement successfully stopped.';
