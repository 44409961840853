import { API_ROUTES_FOR_AXIOS } from 'src/constants/ApiRoutes';
import { axiosInstance } from 'src/constants/axiosInstance';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { ApiResponse } from 'src/types/commonResponseTypes';

import { failureResponse } from 'src/utils/responseUtils';

export interface CustomEventTrackingPayload {
  userId: string;
  orgId: string;
  eventName: string;
}

export const customEventTracking = async (
  body: CustomEventTrackingPayload,
): Promise<ApiResponse<string>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['customEventTracking']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
