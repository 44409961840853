import { ReactNode, useState } from 'react';
import styles from './Announcement.module.scss';
import { Typography } from '@mui/material';

export interface AnnouncementProps {
  texts: Array<string>;
  icon?: ReactNode;
}

const Announcement = ({ texts, icon }: AnnouncementProps) => {
  const [isVisible, setIsVisible] = useState<boolean[]>(
    Array(texts.length).fill(true),
  );

  const handleClose = (index: number) => {
    setIsVisible((prev) =>
      prev.map((visible, i) => (i === index ? false : visible)),
    );
  };

  return (
    <div className={styles['banner-container']}>
      {texts.map(
        (text, index) =>
          isVisible[index] && (
            <div className={styles['banner-text-wrapper']} key={index}>
              <Typography
                variant="body2"
                component="p"
                className={styles['banner-text']}
              >
                {text}
              </Typography>
              {icon && (
                <div
                  id="announcement_close"
                  onClick={() => handleClose(index)}
                  className={styles['closeIcon']}
                >
                  {icon}
                </div>
              )}
            </div>
          ),
      )}
    </div>
  );
};

export default Announcement;
