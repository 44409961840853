import {
  Box,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Grid,
  OutlinedInput,
  Avatar,
  SelectChangeEvent,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { PLAN_TYPES } from 'src/constants/cardsEnum';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import { getPricingPlansDetails } from 'src/services/plan';
import CustomLoading from 'src/components/custom-loading';
import {
  ORGANIZATION_ID_NOT_FOUND_ERROR,
  UNHANDLED_ERROR,
  USER_DETAILS_NOT_FOUND_ERROR,
} from 'src/constants/errors';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import dollar_symbol from 'src/assets/dollar_symbol.svg';
import PricingFeatures from 'src/components/pricing-card/PricingFeatures';
import { PricingPlanApiResponse } from 'src/types/plan';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import DoneIcon from '@mui/icons-material/Done';
import { CustomListItems } from 'src/components/custom-list-items/Custom_ListItems_MUI_Component';
import { CustomDivider } from 'src/components/custom-divider/CustomDivider';
import GO_BACK_ARROW_ICON from '../../assets/back-arrow.webp';
import { capitalizeFirstLetter } from 'src/utils/format';
import styleVariables from 'src/scss/_variables.scss';
import { ROLES } from 'src/constants/Role';
import {
  createCheckoutInLemonsqueezy,
  checkLemonsqueezyExistingSubscriptionByOrgIdFromDb,
  getLemonsqueezySubscriptionIntervalByOrgId,
  updateSubscriptionByOrgIdInLemonsqueezy,
  getSubscriptionStatusByOrgIdInLemonsqueezy,
  resumeSubscriptionByOrgIdInLemonsqueezy,
} from 'src/services/lemonsqueezy';
import {
  LEMONSQUEEZY_PLAN_INTERVALS,
  LemonsqueezySubscriptionStatus,
} from 'src/types/lemonsqueezyTypes';
import {
  LEMONSQUEEZY_PAYMENT_GUIDE_FOR_USERS,
  LEMONSQUEEZY_BY_DEFAULT_RECURRING_INTERVAL,
  SUBSCRIPTION_UPDATE_MESSAGE,
  SUBSCRIPTION_CREATE_MESSAGE,
  SUBSCRIPTION_RESUME_MESSAGE,
  LEMONSQUEEZY_POLLING_INTERVAL,
  LEMONSQUEEZY_PAYMENT_FAILURE_MESSAGE,
  LEMONSQUEEZY_INITIAL_CHECKOUT_FAILURE_MESSAGE,
  LEMONSQUEEZY_INITIAL_CHECKOUT_MAX_TIMEOUT,
} from 'src/constants/lemonsqueezy';
import { openLinkWithRetry } from 'src/utils/retry';
import {
  customEventTracking,
  CustomEventTrackingPayload,
} from 'src/services/customEventTracking';
import { usePaymentStore } from 'src/stores/PaymentStore/PaymentStore';

const LemonsqueezyPayment = observer(() => {
  const { getUserDetails } = useAuthStore();
  const { notify } = useAlertStore();
  const { isPaymentFlowStarted, startPaymentFlow, completePaymentFlow } =
    usePaymentStore();
  const { organizationDetails, fetchOrganization } = useOrganizationStore();
  const userDetails = getUserDetails();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [purchasedSeats, setPurchasedSeats] = useState(1);
  const [currentPlanPrice, setCurrentPlanPrice] = useState<null | number>(null);
  const [
    isLemonsqueezyExistingSubscription,
    setIsLemonsqueezyExistingSubscription,
  ] = useState(false);
  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] =
    useState<LemonsqueezySubscriptionStatus | null>(null);
  const [planSummary, setPlanSummary] = useState<PricingPlanApiResponse[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<
    Exclude<keyof typeof PLAN_TYPES, 'FREE'> | undefined
  >(
    (queryParams.get('plan') as Exclude<keyof typeof PLAN_TYPES, 'FREE'>) ??
      undefined,
  );

  const [isPlanChangeTriggered, setIsPlanChangeTriggered] = useState(false);
  const [selectedRecurringInterval, setSelectedRecurringInterval] = useState<
    keyof typeof LEMONSQUEEZY_PLAN_INTERVALS | undefined
  >(undefined);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const pollingSetIntervalReference = useRef<NodeJS.Timeout | null>(null);
  const isFunctionCalledInPoll = useRef(false);

  const authorizedRoles: Array<keyof typeof ROLES> = [
    ROLES.ADMIN,
    ROLES.SUPER_ADMIN,
    ROLES.INDIVIDUAL_USER_ADMIN,
  ];

  const fetchPlanPricingDetails = async () => {
    if (!selectedPlan || !userDetails) return;

    if (!authorizedRoles.includes(userDetails.role)) return;

    try {
      const {
        success: getPricingPlansDetailsSuccess,
        content: pricingPlansDetails,
      } = await getPricingPlansDetails(selectedPlan);

      if (
        !getPricingPlansDetailsSuccess ||
        !pricingPlansDetails ||
        pricingPlansDetails.length === 0
      ) {
        notify(UNHANDLED_ERROR);

        return;
      }

      setPlanSummary(pricingPlansDetails);
      const selectedInterval =
        selectedRecurringInterval ?? LEMONSQUEEZY_BY_DEFAULT_RECURRING_INTERVAL;

      const pricingDetails = pricingPlansDetails[0].prices.find(
        ({ interval }) => interval === selectedInterval,
      );

      if (!pricingDetails?.price) {
        notify(UNHANDLED_ERROR);

        return;
      }

      const parsedPlanPrice = parseInt(pricingDetails.price);

      if (isNaN(parsedPlanPrice)) {
        notify(UNHANDLED_ERROR);

        return;
      }

      setCurrentPlanPrice(parsedPlanPrice);
    } catch (error) {
      notify(UNHANDLED_ERROR);

      return;
    }
  };

  const fetchLemonsqueezySubscriptionInterval = async () => {
    if (!userDetails) return;

    const {
      success: getSubscriptionIntervalSuccess,
      content: subscriptionInterval,
    } = await getLemonsqueezySubscriptionIntervalByOrgId(
      userDetails.organizationId.toString(),
    );

    if (!getSubscriptionIntervalSuccess || !subscriptionInterval) {
      notify(UNHANDLED_ERROR);

      return;
    }

    setSelectedRecurringInterval(subscriptionInterval);
  };

  const fetchOrgSubscriptionDetails = async () => {
    if (!userDetails) return;

    if (!authorizedRoles.includes(userDetails.role)) return;

    try {
      const {
        success: checkLemonsqueezyExistingSubscriptionSuccess,
        content: isLemonsqueezyExistingSubscriptionByOrgId,
      } = await checkLemonsqueezyExistingSubscriptionByOrgIdFromDb(
        userDetails.organizationId.toString(),
      );

      if (
        !checkLemonsqueezyExistingSubscriptionSuccess ||
        isLemonsqueezyExistingSubscriptionByOrgId === undefined
      ) {
        notify(UNHANDLED_ERROR);

        return;
      }

      if (isLemonsqueezyExistingSubscriptionByOrgId) {
        await fetchLemonsqueezySubscriptionInterval();
      }

      if (
        organizationDetails?.planId?.displayName === PLAN_TYPES.PRO ||
        organizationDetails?.planId?.displayName === PLAN_TYPES.BASIC
      ) {
        const {
          success: getSubscriptionStatusSuccess,
          content: subscriptionStatus,
        } = await getSubscriptionStatusByOrgIdInLemonsqueezy(
          organizationDetails._id,
        );

        if (subscriptionStatus === 'cancelled') {
          notify(
            'You have cancelled existing subscription. Resume now and upgrade anytime',
          );
        }

        if (getSubscriptionStatusSuccess && subscriptionStatus) {
          setCurrentSubscriptionStatus(subscriptionStatus);
        }
      }

      setIsLemonsqueezyExistingSubscription(
        isLemonsqueezyExistingSubscriptionByOrgId,
      );

      return;
    } catch (error) {
      notify(UNHANDLED_ERROR);
    }
  };

  const fetchSubscriptionDetails = async () => {
    setIsPageLoading(true);

    await fetchOrgSubscriptionDetails();

    setIsPageLoading(false);
  };

  const fetchPlanPricingDetailsOnPlanOrIntervalChange = async () => {
    await fetchPlanPricingDetails();
    isPlanChangeTriggered && setIsPageLoading(false);
    setIsPlanChangeTriggered(false);
  };

  const isValidPlanType = (key: string): key is keyof typeof PLAN_TYPES => {
    return Object.keys(PLAN_TYPES).includes(key);
  };

  const toastManualCloseCallbackForNewSubscription = () => {
    navigate(PATHS.pricingPlans);
  };

  const toastManualCloseCallbackForInitialCheckoutFailure = async () => {
    await checkSubscriptionUpdate();
  };

  useEffect(() => {
    const acceptedUpgradesPlan = Object.keys(PLAN_TYPES).filter(
      (plan) => plan !== PLAN_TYPES.FREE,
    );
    const planDisplayNameInQueryParams = queryParams.get('plan');

    if (
      !planDisplayNameInQueryParams ||
      !acceptedUpgradesPlan.includes(planDisplayNameInQueryParams)
    ) {
      navigate(PATHS.pricingPlans);

      return;
    }

    fetchSubscriptionDetails();

    if (!organizationDetails) return;

    const { purchasedSeats } = organizationDetails;

    setPurchasedSeats(purchasedSeats);
  }, []);

  useEffect(() => {
    if (selectedPlan || selectedRecurringInterval) {
      fetchPlanPricingDetailsOnPlanOrIntervalChange();
    }
  }, [selectedPlan, selectedRecurringInterval]);

  if (isPageLoading) {
    return <CustomLoading />;
  }

  if (!userDetails)
    return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;

  if (!organizationDetails)
    return <ErrorBoundary message={ORGANIZATION_ID_NOT_FOUND_ERROR} />;

  if (!currentPlanPrice) return <ErrorBoundary message={UNHANDLED_ERROR} />;

  const checkSubscriptionUpdate = async () => {
    const orgDetails = await fetchOrganization(
      userDetails.organizationId.toString(),
    );

    if (
      orgDetails?.planId.displayName === selectedPlan &&
      orgDetails?.purchasedSeats === purchasedSeats
    ) {
      await fetchOrgSubscriptionDetails();
      await fetchLemonsqueezySubscriptionInterval();
      setIsLemonsqueezyExistingSubscription(true);
      handleNotificationAndCleanup(
        isLemonsqueezyExistingSubscription
          ? SUBSCRIPTION_UPDATE_MESSAGE
          : SUBSCRIPTION_CREATE_MESSAGE,
        !!isLemonsqueezyExistingSubscription, //autoClose
        isLemonsqueezyExistingSubscription
          ? null
          : toastManualCloseCallbackForNewSubscription,
      );
    }
  };

  const handlePaymentFailureOrMaxTimeInCheckOut = async (
    initialCheckoutResponseTimeReached: boolean,
  ) => {
    if (isLemonsqueezyExistingSubscription) {
      const {
        success: getSubscriptionStatusSuccess,
        content: getSubscriptionStatus,
      } = await getSubscriptionStatusByOrgIdInLemonsqueezy(
        userDetails.organizationId.toString(),
      );

      if (!getSubscriptionStatusSuccess || !getSubscriptionStatus) {
        handleNotificationAndCleanup(UNHANDLED_ERROR);

        return;
      }

      if (getSubscriptionStatus === 'past_due') {
        handleNotificationAndCleanup(
          LEMONSQUEEZY_PAYMENT_FAILURE_MESSAGE,
          false,
        );

        return;
      }
    } else if (
      !isLemonsqueezyExistingSubscription &&
      initialCheckoutResponseTimeReached
    ) {
      handleNotificationAndCleanup(
        LEMONSQUEEZY_INITIAL_CHECKOUT_FAILURE_MESSAGE,
        false,
        toastManualCloseCallbackForInitialCheckoutFailure,
      );

      return;
    }
  };

  const pollForSubscriptionUpdates = () => {
    if (isFunctionCalledInPoll.current) return;
    isFunctionCalledInPoll.current = true;
    let initialCheckoutResponseTimeReached = false;

    if (!isLemonsqueezyExistingSubscription) {
      setTimeout(() => {
        initialCheckoutResponseTimeReached = true;
      }, LEMONSQUEEZY_INITIAL_CHECKOUT_MAX_TIMEOUT);
    }

    pollingSetIntervalReference.current = setInterval(async () => {
      try {
        await handlePaymentFailureOrMaxTimeInCheckOut(
          initialCheckoutResponseTimeReached,
        );

        await checkSubscriptionUpdate();
      } catch (error) {
        handleNotificationAndCleanup(UNHANDLED_ERROR);
      } finally {
        isFunctionCalledInPoll.current = false;
      }
    }, LEMONSQUEEZY_POLLING_INTERVAL);
  };

  const handleNotificationAndCleanup = (
    message: string,
    autoClose = true,
    callbackOfManualToastClose?: (() => void) | null,
  ) => {
    pollingSetIntervalReference.current &&
      clearInterval(pollingSetIntervalReference.current);
    notify(message, autoClose, callbackOfManualToastClose);
    completePaymentFlow();
  };

  const pollForSubscriptionResumeUpdate = () => {
    if (isFunctionCalledInPoll.current) return;
    isFunctionCalledInPoll.current = true;
    pollingSetIntervalReference.current = setInterval(async () => {
      try {
        const {
          success: getSubscriptionStatusSuccess,
          content: subscriptionStatus,
        } = await getSubscriptionStatusByOrgIdInLemonsqueezy(
          organizationDetails._id,
        );

        if (getSubscriptionStatusSuccess && subscriptionStatus === 'active') {
          await fetchOrgSubscriptionDetails();
          handleNotificationAndCleanup(SUBSCRIPTION_RESUME_MESSAGE);
        }
      } catch (error) {
        handleNotificationAndCleanup(UNHANDLED_ERROR);
      } finally {
        isFunctionCalledInPoll.current = false;
      }
    }, LEMONSQUEEZY_POLLING_INTERVAL);
  };

  const handleUpgrade = async () => {
    if (!selectedPlan || !isValidPlanType(selectedPlan)) {
      notify('selectedPlan invalid');

      return;
    }

    if (!selectedRecurringInterval) {
      notify('selectedRecurringInterval invalid');

      return;
    }

    startPaymentFlow();
    const { success: updateSubscriptionSuccess, content: updatedSubscription } =
      await updateSubscriptionByOrgIdInLemonsqueezy(
        userDetails.organizationId.toString(),
        {
          quantity: purchasedSeats.toString(),
          selectedPlan,
          selectedInterval: selectedRecurringInterval,
        },
      );

    if (!updateSubscriptionSuccess || !updatedSubscription) {
      handleNotificationAndCleanup(UNHANDLED_ERROR);
    }

    pollForSubscriptionUpdates();
  };

  const handleResume = async () => {
    if (!userDetails) {
      notify(UNHANDLED_ERROR);

      return;
    }

    startPaymentFlow();
    const {
      success: resumeSubscriptionSuccess,
      content: resumeSubscriptionResponse,
    } = await resumeSubscriptionByOrgIdInLemonsqueezy(
      userDetails.organizationId.toString(),
    );

    if (!resumeSubscriptionSuccess || !resumeSubscriptionResponse) {
      handleNotificationAndCleanup(UNHANDLED_ERROR);
    }

    pollForSubscriptionResumeUpdate();
  };

  const lemonsqueezySubscriptionUpgradeComponent = () => {
    return (
      <>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingY: isLargeScreen ? 0 : '12px',
              alignItems: 'center',
            }}
          >
            <Typography fontSize="16px" variant="h3" fontWeight="bold">
              Plan interval
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Select
              value={selectedRecurringInterval}
              onChange={handleRecurringIntervalChange}
              sx={{
                width: '80%',
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  borderColor: styleVariables.mutedGray,
                },
              }}
            >
              {Object.keys(LEMONSQUEEZY_PLAN_INTERVALS).map(
                (planInterval, index) => {
                  return (
                    <MenuItem value={planInterval} key={index}>
                      {capitalizeFirstLetter(planInterval)}
                    </MenuItem>
                  );
                },
              )}
            </Select>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          disabled={isPaymentFlowStarted}
          onClick={() => handleUpgrade()}
          sx={{
            marginBottom: 2,
          }}
        >
          Upgrade
        </Button>
      </>
    );
  };

  const lemonsqueezySubscriptionResumeComponent = () => {
    return (
      <Button
        variant="contained"
        onClick={() => handleResume()}
        sx={{
          marginBottom: 2,
        }}
      >
        Resume
      </Button>
    );
  };

  const handleCreateNewSubscription = async () => {
    if (!selectedPlan || !isValidPlanType(selectedPlan)) {
      notify('selectedPlan is invalid');

      return;
    }

    if (!purchasedSeats) {
      notify('purchasedSeats is invalid');

      return;
    }

    startPaymentFlow();

    if (userDetails) {
      const body: CustomEventTrackingPayload = {
        userId: userDetails?._id,
        orgId: userDetails?.organizationId,
        eventName: `${selectedPlan.toLowerCase()}_plan_checkout`,
      };

      customEventTracking(body);
    }

    const { success: createCheckoutSuccess, content: checkoutLink } =
      await createCheckoutInLemonsqueezy({
        selectedPlan,
        quantity: purchasedSeats.toString(),
      });

    if (!createCheckoutSuccess || !checkoutLink) {
      handleNotificationAndCleanup(UNHANDLED_ERROR);

      return;
    }

    openLinkWithRetry(checkoutLink);

    pollForSubscriptionUpdates();
  };

  const lemonSqueezylNewSubscriptionComponent = () => {
    return (
      <Button variant="contained" onClick={handleCreateNewSubscription}>
        Checkout
      </Button>
    );
  };

  const handlePlanChange = (event: SelectChangeEvent) => {
    setIsPageLoading(true);
    setIsPlanChangeTriggered(true);
    setSelectedPlan(event.target.value as typeof selectedPlan);
  };

  const handleSeatsChange = (increment: number) => {
    setPurchasedSeats((prevSeats) => Math.max(1, prevSeats + increment));
  };

  const handleRecurringIntervalChange = (event: SelectChangeEvent) => {
    setIsPageLoading(true);
    setIsPlanChangeTriggered(true);
    setSelectedRecurringInterval(
      event.target.value as typeof selectedRecurringInterval,
    );
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
      }}
    >
      <ModuleHeader
        title="BUY PLAN"
        ModuleHeaderIcon={
          <img
            style={{ width: '32px', height: '32px' }}
            src={GO_BACK_ARROW_ICON}
          />
        }
        onModuleHeaderIconClick={() => {
          navigate(PATHS.pricingPlans);
        }}
      />
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: '36px',
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            order: { xs: 2, md: 1 },
          }}
        >
          {currentSubscriptionStatus !== 'cancelled' && (
            <>
              <Grid container sx={{ justifyContent: 'center' }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingY: isLargeScreen ? 0 : '12px',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontSize="16px" variant="h3" fontWeight="bold">
                    Selected Plan
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Select
                    value={selectedPlan}
                    onChange={handlePlanChange}
                    sx={{
                      width: '80%',
                      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: styleVariables.mutedGray,
                      },
                    }}
                  >
                    {Object.keys(PLAN_TYPES)
                      .filter(
                        (planType) =>
                          planType === PLAN_TYPES.BASIC ||
                          planType === PLAN_TYPES.PRO,
                      )
                      .map((planType, index) => {
                        return (
                          <MenuItem value={planType} key={index}>
                            {capitalizeFirstLetter(planType)}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
              {(userDetails.role === ROLES.ADMIN ||
                userDetails.role === ROLES.SUPER_ADMIN) && (
                <Grid container sx={{ justifyContent: 'center' }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      paddingY: isLargeScreen ? 0 : '12px',
                    }}
                  >
                    <Typography fontSize="16px" variant="h3" fontWeight="bold">
                      Seat Count
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <OutlinedInput
                      sx={{ width: '80%' }}
                      value={purchasedSeats}
                      readOnly
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        width: '20%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        onClick={() => handleSeatsChange(1)}
                        sx={{ padding: 0.5 }}
                        disabled={
                          userDetails.role !== ROLES.ADMIN &&
                          userDetails.role !== ROLES.SUPER_ADMIN
                        }
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleSeatsChange(-1)}
                        sx={{ padding: 0.5 }}
                        disabled={
                          userDetails.role !== ROLES.ADMIN &&
                          userDetails.role !== ROLES.SUPER_ADMIN
                        }
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container sx={{ justifyContent: 'center' }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    paddingY: isLargeScreen ? 0 : '12px',
                    paddingRight: isLargeScreen ? '24px' : 0,
                    overflowWrap: 'anywhere',
                  }}
                >
                  <Typography fontSize="16px" variant="h3" fontWeight="bold">
                    Total amount for billing period (excluding tax & proration
                    will apply)
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <OutlinedInput
                    sx={{ width: '80%' }}
                    value={purchasedSeats * currentPlanPrice}
                    readOnly
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      width: '20%',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      src={dollar_symbol}
                      alt="dollar-icon"
                      variant="square"
                      sx={{
                        width: 'initial',
                        height: 'initial',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          {!isLemonsqueezyExistingSubscription &&
            lemonSqueezylNewSubscriptionComponent()}
          {isLemonsqueezyExistingSubscription &&
            selectedRecurringInterval &&
            currentSubscriptionStatus !== 'cancelled' &&
            lemonsqueezySubscriptionUpgradeComponent()}

          {isLemonsqueezyExistingSubscription &&
            currentSubscriptionStatus === 'cancelled' &&
            lemonsqueezySubscriptionResumeComponent()}
          <Box>
            <Typography variant="h6" component="h2">
              Note:
            </Typography>
            <CustomListItems
              listItemsData={LEMONSQUEEZY_PAYMENT_GUIDE_FOR_USERS}
              listItemStyle={{
                listStyleType: 'disc',
                marginLeft: isLargeScreen ? '24px' : '16px',
                padding: 0,
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={1}
          sx={{
            order: { md: 2 },
            display: {
              xs: 'none',
              md: 'flex',
            },
            justifyContent: 'flex-end',
          }}
        >
          <CustomDivider orientation="vertical" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            order: { xs: 1, md: 3 },
          }}
        >
          <Typography
            fontSize="16px"
            variant="h3"
            fontWeight="bold"
            sx={{ marginBottom: 2 }}
          >
            Plan Summary:
          </Typography>
          <PricingFeatures
            features={planSummary[0]?.features}
            DescriptionIcon={
              <DoneIcon
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
            }
            highlightListItemStyle={{
              padding: 0,
              marginLeft: '48px',
              display: 'list-item',
              listStyleType: 'disc',
            }}
            descriptionStyle={{ paddingLeft: '12px' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default LemonsqueezyPayment;
