import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';

interface DateAndTimePickerProps {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
}

const DateAndTimePicker: React.FC<DateAndTimePickerProps> = ({
  value,
  onChange,
}) => {
  const [selectedDateTime, setSelectedDateTime] = useState<Dayjs | null>(value);
  const fiveMinutesFromNow = dayjs().add(5, 'minute');

  const handleDateTimeChange = (newValue: Dayjs | null) => {
    setSelectedDateTime(newValue);
    onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={selectedDateTime}
        onChange={handleDateTimeChange}
        minDateTime={fiveMinutesFromNow}
      />
    </LocalizationProvider>
  );
};

export default DateAndTimePicker;
