import { makeAutoObservable } from 'mobx';
import createStoreContext from '../createStoreContext';

const NOTIFICATION_TIMEOUT = 5000; // in milliseconds

export const [AlertStoreProvider, useAlertStore] = createStoreContext(
  class AlertStore {
    showNotification = false;
    notificationMessage = '';
    callbackOfManualToastClose: (() => void) | null = null;
    private notificationTimeoutId: NodeJS.Timeout | null = null;

    public constructor() {
      makeAutoObservable(this);
    }

    notify = (
      message: string,
      autoClose = true,
      callbackOfManualToastClose: (() => void) | null = null,
    ) => {
      if (this.notificationTimeoutId !== null) {
        clearTimeout(this.notificationTimeoutId);
      }

      this.showNotification = true;
      this.notificationMessage = message;

      if (callbackOfManualToastClose) {
        this.callbackOfManualToastClose = callbackOfManualToastClose;
      }

      if (autoClose) {
        this.notificationTimeoutId = setTimeout(() => {
          this.reset();
        }, NOTIFICATION_TIMEOUT);
      }
    };

    reset = () => {
      this.showNotification = false;
      this.notificationMessage = '';
      this.callbackOfManualToastClose = null;
    };
  },
);

export type GlobalStore = ReturnType<typeof useAlertStore>;
