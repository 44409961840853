import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { ApiResponse, PaginatedResponse } from 'src/types/commonResponseTypes';
import {
  CreateMessageProp,
  GetReportMessageApiResponse,
  ReportMessageApiResponse,
} from 'src/types/reportMessage';

export const getReportMessagesByGroupId = async (
  groupId: string,
  page: number,
  limit: number,
): Promise<ApiResponse<PaginatedResponse<GetReportMessageApiResponse[]>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getReportMessagesByGroupId'](
      groupId,
      page,
      limit,
    );
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const createMessage = async (
  body: CreateMessageProp,
): Promise<ApiResponse<ReportMessageApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['createReportMessage']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const updateMessage = async (
  messageId: string,
  body: CreateMessageProp,
): Promise<ApiResponse<ReportMessageApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateReportMessage'](messageId);
    const { data } = await axiosInstance.patch(apiUrl, body);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const deleteReportMessageById = async (
  messageId: string,
): Promise<ApiResponse<ReportMessageApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['deleteReportMessageById'](messageId);
    const { data } = await axiosInstance.delete(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
