import React, { Suspense, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { loadTabContent, getSettingModuleTabs } from 'src/utils/loadTabContent';
import CustomLoading from 'src/components/custom-loading';
import styles from '../../scss/_variables.scss';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import { ROLES } from 'src/constants/Role';
import { SettingsTabs } from 'src/types/settings';
import {
  customEventTracking,
  CustomEventTrackingPayload,
} from 'src/services/customEventTracking';

export interface SettingModuleTabType {
  value: string;
  label: string;
  allowedRoles: Array<keyof typeof ROLES>;
}

const Settings: React.FC = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabQueryParam = searchParams.get('tab') as SettingsTabs;
  const [value, setValue] = useState<SettingsTabs>(tabQueryParam ?? 'profile');
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();

  useEffect(() => {
    if (!userDetails) return;

    if (
      tabQueryParam &&
      getSettingModuleTabs(userDetails.role).some(
        (tab) => tab.value === tabQueryParam,
      )
    ) {
      setValue(tabQueryParam);
    }
  }, [tabQueryParam]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: SettingsTabs,
  ) => {
    if (!userDetails) return;

    if (newValue === 'billing') {
      const body: CustomEventTrackingPayload = {
        userId: userDetails?._id,
        orgId: userDetails?.organizationId,
        eventName: `${newValue}_page_view`,
      };

      customEventTracking(body);
    }

    setSearchParams({ tab: newValue });
  };

  const TabComponent = React.lazy(() => loadTabContent(value));

  const tabStyles = {
    marginBottom: 4,
    '& .MuiTab-root.Mui-selected': {
      color: styles.colorWhite,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: styles.hanabiThemeColorDarkRed,
    },
  };

  if (!userDetails) return null;

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <ModuleHeader title="Settings" />
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        sx={tabStyles}
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
      >
        {getSettingModuleTabs(userDetails.role).map(
          ({ value, label, allowedRoles }, index) =>
            allowedRoles.includes(userDetails.role) && (
              <Tab value={value} label={label} key={index} />
            ),
        )}
      </Tabs>
      <Suspense fallback={<CustomLoading />}>
        <TabComponent />
      </Suspense>
    </Box>
  );
});

export default Settings;
