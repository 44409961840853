import { PLAN_TYPES } from 'src/constants/cardsEnum';

export enum LemonsqueezySseEventApiResponse {
  subscription_updated = 'subscription_updated',
}

export enum LEMONSQUEEZY_PLAN_INTERVALS {
  month = 'month',
  year = 'year',
}

export type LemonsqueezySubscriptionStatus =
  | 'on_trial'
  | 'active'
  | 'paused'
  | 'pause'
  | 'past_due'
  | 'unpaid'
  | 'cancelled'
  | 'expired';

export interface CreateCheckoutPayload {
  selectedPlan: Exclude<keyof typeof PLAN_TYPES, 'FREE'>;
  quantity: string;
}

export interface UpdateLemonsqueezySubscriptionPayload
  extends CreateCheckoutPayload {
  selectedInterval: keyof typeof LEMONSQUEEZY_PLAN_INTERVALS;
}
