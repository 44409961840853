import HANA_PERSONA_IMAGE from '../../assets/hana-hero.webp';
import { HANA_ACCESS_DAILOG_IMG_SIZE } from 'src/constants/Layout';
import { BOT_NAME } from 'src/constants/Bot';
import styles from './onboarding.hana.module.scss';

import {
  Typography,
  Avatar,
  Button,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material';
import React, { useState } from 'react';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  ADD_HANA_MESSAGE,
  ALERT_TO_CLICK_CKECK_BOX,
} from 'src/constants/dialogMetaInfo';
import CustomLoading from '../custom-loading';

import { checkIfUserInteractedWithHanaByUserId } from 'src/services/overview';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { GOOGLE_CHAT_LINK, HANABI_YOUTUBE_URL } from 'src/constants/Urls';

interface GetStepContentProps {
  stepIndex: number;
  start: () => void;
  setModal: (value: boolean) => void;
}

const GetStepContent: React.FC<GetStepContentProps> = ({
  stepIndex,
  start,
  setModal,
}) => {
  const { notify } = useAlertStore();
  const [isHanaAdded, setIsHanaAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();

  const handleSubmit = async () => {
    if (!userDetails) {
      notify(UNHANDLED_ERROR);

      return;
    }

    if (!isHanaAdded) {
      notify(ALERT_TO_CLICK_CKECK_BOX);

      return;
    }

    try {
      setLoading(true);

      const { success, content } = await checkIfUserInteractedWithHanaByUserId(
        userDetails._id,
      );

      if (!success || !content) {
        notify(UNHANDLED_ERROR);
        setLoading(false);

        return;
      }

      setIsHanaAdded(content.hasInteracted);

      if (!content.hasInteracted) {
        notify(ADD_HANA_MESSAGE);
      } else {
        setModal(false);
      }
    } catch (error) {
      notify(UNHANDLED_ERROR);
    } finally {
      setLoading(false);
    }
  };

  switch (stepIndex) {
    case 0:
      return (
        <div className={styles['case-one-box']}>
          <div className={styles['welcome-content']}>
            <Avatar
              alt={HANA_PERSONA_IMAGE}
              src={HANA_PERSONA_IMAGE}
              sx={{
                width: HANA_ACCESS_DAILOG_IMG_SIZE,
                height: HANA_ACCESS_DAILOG_IMG_SIZE,
                objectFit: 'cover',
              }}
            />
            <p className={styles['hero-heading']}>
              WELCOME TO
              <span
                className="special-text"
                style={{ textTransform: 'uppercase' }}
              >
                {' '}
                {BOT_NAME}!
              </span>
            </p>
          </div>

          <div className={styles['welcome-content-btn-box']}>
            <Typography variant="subtitle1">
              "Collaborate Smarter with {BOT_NAME}"
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={start}
            >
              Start
            </Button>
          </div>
        </div>
      );
    case 1:
      return (
        <div className={styles['case-two-box']}>
          <h1 className={styles['hana-workspace-heading']}>
            CREATE FIRST INTERACTION WITH{' '}
            <span
              className="special-text"
              style={{ textTransform: 'uppercase' }}
            >
              {BOT_NAME}
            </span>{' '}
          </h1>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: '20px',
            }}
          >
            <p className={styles['p-tag-content']}>
              Welcome to your new digital workspace with {BOT_NAME}! Now
              let&apos;s create your first interaction!
            </p>

            <p className={styles['p-tag-heading']}>Instructions:</p>

            <ol className={styles['pop-up-list']}>
              <li>
                Go to{' '}
                <Link
                  component={'a'}
                  href={GOOGLE_CHAT_LINK}
                  referrerPolicy="no-referrer"
                  target="_blank"
                >
                  Google Chat
                </Link>
              </li>
              <li>Open up one of your chat spaces/groups.</li>
              <li>
                Go to 'Apps and Integrations' settings by clicking on the space
                title and selecting from the drop down.
              </li>
              <li>Click 'Add apps' button in top right.</li>
              <li>Search {BOT_NAME} in the modal and add it to space.</li>
              <li>
                Go back to space and type '@{BOT_NAME} hi' in the space, wait
                for the @{BOT_NAME} to turn blue to make sure {BOT_NAME} is
                properly mentioned.
              </li>
              <li>
                You will see a message card from {BOT_NAME} to authorize. You
                must complete the auth flow and give all required permissions
                for {BOT_NAME} to interact with you.
              </li>
              <li>
                Type '@{BOT_NAME} hi' in the space again. It is important to
                repeat this.
              </li>
              <li>
                This time you should see a response from {BOT_NAME} greeting you
                back.
              </li>
              <li>
                That&apos;s it! You have done it! {BOT_NAME} is now ready to
                assist you. Click the checkbox below to confirm your
                interaction.
              </li>
            </ol>

            <p className={styles['p-tag-heading']}>Video Guide:</p>

            <p className={styles['p-tag-content']}>
              <a
                href={HANABI_YOUTUBE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {HANABI_YOUTUBE_URL}
              </a>
            </p>
          </div>

          <div
            style={{
              marginTop: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {loading ? (
              <CustomLoading />
            ) : (
              <FormControlLabel
                sx={{
                  border: `1px solid ${styles.slateGray}`,
                  borderRadius: '6px',
                  padding: '10px',
                  margin: 0,
                }}
                control={
                  <Checkbox
                    checked={isHanaAdded}
                    onChange={(event) => setIsHanaAdded(event.target.checked)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: styles.hanabiThemeColorDarkRed,
                      },
                    }}
                  />
                }
                label={<span>Added {BOT_NAME}</span>}
              />
            )}
          </div>

          <div className={styles['submit-btn']}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default GetStepContent;
