import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { PATHS } from './navigateRoutes';
import ProtectedRoute from 'src/components/protected-route/ProtectedRoute';
import App from 'src/App';
import Overview from 'src/pages/overview';
import ReportGroupPage from 'src/pages/report-group';
import ReportMessagesList from 'src/pages/report-message';
import MemoryList from 'src/pages/memory';
import OnBoardRegistration from 'src/pages/onboard-registration/OnBoardRegistration';
import AuthPage from 'src/pages/auth';
import ErrorPage from 'src/pages/error-page';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import Members from 'src/pages/members';
import GuestRoute from 'src/components/guest-route/GuestRoute';
import Help from 'src/pages/help';
import Spaces from 'src/pages/spaces/Spaces';
import PricingPlans from 'src/pages/pricing-plans';
import Settings from 'src/pages/setting/Settings';
import Reminder from 'src/pages/reminder/Index';
import LemonsqueezyPayment from 'src/pages/lemonsqueezy-payment';
import PolicyApproval from 'src/pages/policy-approval';
import Crm from 'src/pages/crm/Crm';
import CheckUpgradeRequired from 'src/components/check-upgrade-required/CheckUpgradeRequired';
import { SLASH_COMMANDS } from './slashCommands';

export const ROUTES: RouteObject[] = [
  {
    path: PATHS.home,
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={PATHS.overview} replace />,
      },
      {
        path: PATHS.overview,
        element: <Overview />,
      },
      {
        path: PATHS.users,
        element: <Members />,
      },
      {
        path: PATHS.spaces,
        element: <Spaces />,
      },
      {
        path: PATHS.settings,
        element: <Settings />,
      },
      {
        path: PATHS.help,
        element: <Help />,
      },
      {
        path: PATHS.reminder,
        element: (
          <CheckUpgradeRequired featureId={SLASH_COMMANDS.remind}>
            <Reminder />
          </CheckUpgradeRequired>
        ),
      },
      {
        path: PATHS.reportGroups,
        element: (
          <CheckUpgradeRequired featureId={SLASH_COMMANDS.reportGroups}>
            <ModuleHeader title="Report Groups" />
            <Outlet />
          </CheckUpgradeRequired>
        ),
        children: [
          {
            path: '',
            element: <ReportGroupPage />,
          },
          {
            path: PATHS.reportMessages,
            element: <ReportMessagesList />,
          },
        ],
      },
      {
        path: PATHS.memory,
        element: (
          <CheckUpgradeRequired featureId={SLASH_COMMANDS.memory}>
            <MemoryList />
          </CheckUpgradeRequired>
        ),
      },
      {
        path: PATHS.pricingPlans,
        element: <PricingPlans />,
      },
      {
        path: PATHS.payment,
        element: <LemonsqueezyPayment />,
      },
      {
        path: PATHS.crm,
        element: <Crm />,
      },
    ],
  },
  {
    path: PATHS.onBoardRegistration,
    element: (
      <ProtectedRoute>
        <OnBoardRegistration />
      </ProtectedRoute>
    ),
  },
  {
    path: PATHS.policyApproval,
    element: (
      <ProtectedRoute>
        <PolicyApproval />
      </ProtectedRoute>
    ),
  },
  {
    path: PATHS.unAuthorized,
    element: (
      <GuestRoute>
        <AuthPage />
      </GuestRoute>
    ),
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];
