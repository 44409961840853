import {
  Box,
  Button,
  Typography,
  Avatar,
  Grid,
  Pagination,
  PaginationItem,
} from '@mui/material';

import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  getReportMessagesByGroupId,
  updateMessage,
  createMessage,
  deleteReportMessageById,
} from '../../services/report-message';
import { CreateMessageProp } from 'src/types/reportMessage';
import { FormField } from '../../components/custom-dialog/CustomDialog';
import { observer } from 'mobx-react-lite';
import {
  DialogFor,
  useDialogStore,
} from '../../stores/DialogStore/DialogStore';
import { useAlertStore } from '../../stores/AlertStore/AlertStore';
import { useAuthStore } from '../../stores/AuthStore/AuthStore';
import {
  DIALOG_TITLE_FOR_REPORT_MESSAGE,
  DIALOG_CONTENT_FOR_REPORT_MESSAGE,
  DIALOG_TITLE_FOR_DELETE,
  DIALOG_CONTENT_FOR_DELETE,
  CREATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_BUTTON_TEXT,
  DELETED_USER_PLACEHOLDER_TEXT,
  LARGE_DIALOG_TEXTFIELD_ROWS,
} from '../../constants/dialogMetaInfo';
import { GetReportMessageApiResponse } from 'src/types/reportMessage';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { checkInputDateBeyondMaxTime } from 'src/utils/dateUtils';
import {
  REPORT_MESSAGE_EDIT_MAX_TIME_LIMIT_ERROR,
  UNHANDLED_ERROR,
} from 'src/constants/errors';
import { MAX_TIME_FOR_EDITING_OR_DELETING_REPORT_MESSAGE } from 'src/constants/reportMessage';
import CustomLoading from 'src/components/custom-loading';
import styleVariables from '../../scss/_variables.scss';
import { STYLE_FOR_LARGE_DIALOG } from 'src/constants/Layout';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

const MESSAGES_PER_PAGE: number = 9;

const REPORT_MESSAGE_INITIAL_FIELDS: FormField[] = [
  {
    label: 'Report Message Description',
    id: 'description',
    value: '',
    type: 'textarea',
  },
];

const ReportMessagesList = observer(() => {
  const { groupId } = useParams();
  const {
    updateDialogueState,
    dialogType,
    dialogueState,
    dialogFormFields,
    updateDialogMetaData,
    resetFormFieldsValue,
  } = useDialogStore();
  const { notify } = useAlertStore();
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const [reportMessages, setReportMessages] = useState<
    GetReportMessageApiResponse[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [existedMessageDetails, setExistedMessageDetails] =
    useState<null | GetReportMessageApiResponse>(null);

  const [isAPIFailed, setIsAPIFailed] = useState(false);

  const handleFetchReportMessages = useCallback(
    async (groupId: string) => {
      if (!userDetails) return;

      setLoading(true);

      try {
        const {
          success: getReportMessagesSuccess,
          content: reportMessages,
          message: getReportMessagesErrorMessage,
        } = await getReportMessagesByGroupId(
          groupId,
          currentPage,
          MESSAGES_PER_PAGE,
        );

        setExistedMessageDetails(null);

        if (!getReportMessagesSuccess && getReportMessagesErrorMessage) {
          notify(getReportMessagesErrorMessage);
          setExistedMessageDetails(null);
          setIsAPIFailed(true);
          setReportMessages([]);
        } else {
          reportMessages?.data?.forEach((reportMessage) => {
            if (
              reportMessage.createdBy &&
              reportMessage.createdBy._id.toString() ===
                userDetails._id.toString()
            ) {
              setExistedMessageDetails(reportMessage);

              return;
            }
          });
          setIsAPIFailed(false);
          setLoading(false);
          setTotalCount(reportMessages?.totalCount ?? 0);
          setReportMessages(reportMessages?.data ?? []);
        }
      } catch (error) {
        notify(UNHANDLED_ERROR);
        setIsAPIFailed(false);
        setLoading(false);
        setReportMessages([]);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, notify, userDetails?._id, isAPIFailed],
  );

  const handleCreateOrUpdateMessage = async () => {
    updateDialogueState('LOADING');

    const body: CreateMessageProp = {
      organizationId: '',
      createdBy: '',
      description: '',
      reportGroupId: '',
    };

    for (const field of dialogFormFields) {
      if (field.id === 'description') {
        body[field.id] = field.value;
      }
    }

    if (!groupId || !userDetails) return;
    const { _id: mongoUserId, organizationId } = userDetails;

    body['createdBy'] = mongoUserId.toString();
    body['reportGroupId'] = groupId;
    body['organizationId'] = organizationId.toString();

    if (!existedMessageDetails) {
      // Create a new message
      const {
        success: createMessageSuccess,
        message: createMessageErrorMessage,
      } = await createMessage(body);

      if (!createMessageSuccess && createMessageErrorMessage) {
        notify(createMessageErrorMessage);
        updateDialogueState('OPEN');
      } else {
        updateDialogueState('CLOSED');
        notify(CREATE_MESSAGE_SUCCESS);
        handleFetchReportMessages(groupId);
      }
    } else {
      // Update a message
      if (
        checkInputDateBeyondMaxTime(
          existedMessageDetails.createdAt.toString(),
          MAX_TIME_FOR_EDITING_OR_DELETING_REPORT_MESSAGE,
        )
      ) {
        notify(REPORT_MESSAGE_EDIT_MAX_TIME_LIMIT_ERROR);
        updateDialogueState('OPEN');

        return;
      }

      const {
        success: updateMessageSuccess,
        message: updateMessageErrorMessage,
      } = await updateMessage(existedMessageDetails._id.toString(), body);

      if (!updateMessageSuccess && updateMessageErrorMessage) {
        notify(updateMessageErrorMessage);
        updateDialogueState('OPEN');
      } else {
        updateDialogueState('CLOSED');
        notify(UPDATE_MESSAGE_SUCCESS);
        handleFetchReportMessages(groupId);
      }
    }
  };

  const confirmDeleteReportMessage = async () => {
    if (!existedMessageDetails || !groupId) return;

    updateDialogueState('LOADING');

    const {
      success: deleteMessageSuccess,
      message: deleteMessageErrorMessage,
    } = await deleteReportMessageById(existedMessageDetails._id.toString());

    if (!deleteMessageSuccess && deleteMessageErrorMessage) {
      notify(deleteMessageErrorMessage);
    } else {
      notify(DELETE_MESSAGE_SUCCESS);
      handleFetchReportMessages(groupId);
    }

    updateDialogueState('CLOSED');
  };

  const openDialogForCreateReportMessage = () => {
    setExistedMessageDetails(null);
    updateDialogMetaData(
      DIALOG_TITLE_FOR_REPORT_MESSAGE,
      DIALOG_CONTENT_FOR_REPORT_MESSAGE,
      DialogFor.REPORT_MESSAGE,
      resetFormFieldsValue(REPORT_MESSAGE_INITIAL_FIELDS),
      LARGE_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const openDialogForEditReportMessage = (
    reportMessage: GetReportMessageApiResponse,
  ) => {
    const updated = [...REPORT_MESSAGE_INITIAL_FIELDS];

    setExistedMessageDetails(reportMessage);
    updated[0].value = reportMessage.description;
    updateDialogMetaData(
      DIALOG_TITLE_FOR_REPORT_MESSAGE,
      DIALOG_CONTENT_FOR_REPORT_MESSAGE,
      DialogFor.REPORT_MESSAGE,
      updated,
      LARGE_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const openDialogForDeleteMessage = (
    reportMessage: GetReportMessageApiResponse,
  ) => {
    setExistedMessageDetails(reportMessage);
    updateDialogMetaData(
      DIALOG_TITLE_FOR_DELETE,
      DIALOG_CONTENT_FOR_DELETE,
      DialogFor.DELETE_REPORT_MESSAGE,
      [],
    );

    updateDialogueState('OPEN');
  };

  useEffect(() => {
    async function fetchData() {
      if (groupId) {
        setLoading(true);

        try {
          await handleFetchReportMessages(groupId);
        } catch (error) {
          notify(UNHANDLED_ERROR);
        } finally {
          setLoading(false);
        }
      }
    }

    fetchData();
  }, [groupId, handleFetchReportMessages]);

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.REPORT_MESSAGE:
        handleCreateOrUpdateMessage();
        break;

      case DialogFor.DELETE_REPORT_MESSAGE:
        confirmDeleteReportMessage();
        break;
      default:
    }
  }, [dialogueState]);

  if (!userDetails) return null;

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '16px',
          }}
        >
          <Button
            id="create-report-message"
            variant="contained"
            color="primary"
            size="small"
            onClick={openDialogForCreateReportMessage}
          >
            {CREATE_MESSAGE_BUTTON_TEXT}
          </Button>
        </Box>

        {!isAPIFailed && !loading && (
          <Grid container rowSpacing={2} columnSpacing={2}>
            {reportMessages.map((reportMessage) => {
              const { createdBy, description, _id, updatedAt, createdAt } =
                reportMessage;

              return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={_id}>
                  <Card
                    sx={{
                      padding: '24px',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      borderRadius: '8px',
                      backgroundColor: styleVariables.reportsGroupMessageBg,
                    }}
                  >
                    <CardHeader
                      sx={{
                        padding: 0,
                      }}
                      avatar={
                        <Avatar
                          src={createdBy?.profilePicture}
                          sx={{ width: '48px', height: '48px' }}
                          alt={`${
                            createdBy
                              ? createdBy.displayName
                              : DELETED_USER_PLACEHOLDER_TEXT
                          }`}
                        />
                      }
                      title={
                        <Typography>
                          {createdBy
                            ? createdBy.displayName
                            : DELETED_USER_PLACEHOLDER_TEXT}
                        </Typography>
                      }
                      subheader={
                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                          {' '}
                          {new Date(updatedAt).toLocaleTimeString('en-GB', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })}{' '}
                          {new Date(updatedAt).toDateString() ===
                          new Date().toDateString()
                            ? 'Today'
                            : new Date(updatedAt).toLocaleDateString('en-GB')}
                        </Typography>
                      }
                    />
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        padding: '24px 0',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontSize: 'small' }}>
                        {description}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        padding: 0,
                        gap: '16px',
                      }}
                    >
                      <Button
                        id="edit-report-message"
                        sx={{
                          padding: '6px 16px',
                        }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          openDialogForEditReportMessage(reportMessage)
                        }
                        disabled={
                          !createdBy ||
                          (userDetails.role !== 'ADMIN' &&
                            (createdBy._id.toString() !==
                              userDetails._id.toString() ||
                              (userDetails.role === 'USER' &&
                                checkInputDateBeyondMaxTime(
                                  createdAt.toString(),
                                  MAX_TIME_FOR_EDITING_OR_DELETING_REPORT_MESSAGE,
                                ))))
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        id="delete-report-message"
                        sx={{
                          padding: '6px 16px',
                        }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          openDialogForDeleteMessage(reportMessage)
                        }
                        disabled={
                          !createdBy ||
                          (userDetails.role !== 'ADMIN' &&
                            (createdBy._id.toString() !==
                              userDetails._id.toString() ||
                              checkInputDateBeyondMaxTime(
                                createdAt.toString(),
                                MAX_TIME_FOR_EDITING_OR_DELETING_REPORT_MESSAGE,
                              )))
                        }
                      >
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '16px',
          }}
        >
          <Pagination
            count={Math.ceil(totalCount / MESSAGES_PER_PAGE)}
            page={currentPage}
            onChange={(_event, value) => setCurrentPage(value)}
            size="small"
            sx={{ marginTop: '16px', maxWidth: '100%' }}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: KeyboardArrowLeft,
                  next: KeyboardArrowRight,
                }}
                {...item}
                sx={
                  item.page === currentPage
                    ? {
                        color: styleVariables.azureRadiance,
                      }
                    : null
                }
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
});

export default ReportMessagesList;
