export interface UserConsentPolicy {
  policyId: string;
  organizationId: string;
  userId: string;
  policyVersion: string;
}

// Api response Types
export interface PolicyApiResponse {
  _id: string;
  version: string;
  description: string;
  effectiveFrom: string;
  policyLink: string;
  createdAt: string;
  type: keyof typeof POLICY_TYPES;
  updatedAt: string;
}

export interface UserConsentPolicyApiResponse extends UserConsentPolicy {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface CheckLatestPoliciesApprovedByUserApiResponse {
  isLatestPoliciesApprovedByUser: boolean;
}

export enum POLICY_TYPES {
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
}

export interface UserConsentPoliciesPayload {
  policies: UserConsentPolicy[];
}
