import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { ApiResponse } from 'src/types/commonResponseTypes';
import {
  TotalOrgInteractionCountApiResponse,
  TotalSpacesCountApiResponse,
  InteractionCountByDay,
  CheckIsHanaAddedApiResponse,
} from 'src/types/overview';
import { TotalUsersCountApiResponse } from 'src/types/user';

export const getTotalActiveSpacesIntegrated = async (
  orgId: string,
): Promise<ApiResponse<TotalSpacesCountApiResponse>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS['getTotalActiveSpacesIntegrated'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getTotalUsersAuthorized = async (
  orgId: string,
): Promise<ApiResponse<TotalUsersCountApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getTotalUsersAuthorized'](orgId);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getOrganizationInteractionCountForMonth = async (
  orgId: string,
  currentDate: string,
): Promise<ApiResponse<TotalOrgInteractionCountApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS[
      'getOrganizationInteractionCountForMonth'
    ](orgId, currentDate);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getUserInteractionCountForMonth = async (
  mongoUserId: string,
  orgId: string,
  currentDate: string,
): Promise<ApiResponse<TotalOrgInteractionCountApiResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getUserInteractionCountForMonth'](
      mongoUserId,
      orgId,
      currentDate,
    );
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getUserInteractionDataForSelectedDates = async (
  mongoUserId: string,
  orgId: string,
  startDate: string,
  endDate: string,
  timeZoneOffset: string,
): Promise<ApiResponse<InteractionCountByDay[]>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS[
      'getUserInteractionDataForSelectedDates'
    ](mongoUserId, orgId, startDate, endDate, timeZoneOffset);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const getOrgInteractionDataForSelectedDates = async (
  orgId: string,
  startDate: string,
  endDate: string,
  timeZoneOffset: string,
): Promise<ApiResponse<InteractionCountByDay[]>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS[
      'getOrgInteractionDataForSelectedDates'
    ](orgId, startDate, endDate, timeZoneOffset);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};

export const checkIfUserInteractedWithHanaByUserId = async (
  userId: string,
): Promise<ApiResponse<CheckIsHanaAddedApiResponse>> => {
  try {
    const apiUrl =
      API_ROUTES_FOR_AXIOS['checkIfUserInteractedWithHana'](userId);

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
