import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import PricingCard from 'src/components/pricing-card/PricingCard';
import {
  PLAN_TYPES,
  PRICING_CARD_BUTTON_TITLE,
  PRICING_CARD_CURRENCY_SYMBOL,
  PRICING_CARD_FREE_PLAN_LABEL,
} from 'src/constants/cardsEnum';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  ORGANIZATION_ID_NOT_FOUND_ERROR,
  UNHANDLED_ERROR,
  USER_DETAILS_NOT_FOUND_ERROR,
} from 'src/constants/errors';
import { getPricingPlansDetails } from 'src/services/plan';
import { PricingPlanApiResponse } from 'src/types/plan';
import CustomLoading from 'src/components/custom-loading';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import {
  DIALOG_CONTENT_FOR_CANCEL_PLAN,
  DIALOG_CONTENT_FOR_CANCEL_PLAN_IF_PAST_DUE,
  DIALOG_TITLE_FOR_CANCEL_PLAN,
} from 'src/constants/dialogMetaInfo';
import {
  cancelSubscriptionByOrgIdInLemonsqueezy,
  getSubscriptionStatusByOrgIdInLemonsqueezy,
} from 'src/services/lemonsqueezy';
import { ROLES } from 'src/constants/Role';
import {
  LEMONSQUEEZY_PLAN_INTERVALS,
  LemonsqueezySubscriptionStatus,
} from 'src/types/lemonsqueezyTypes';
import {
  CANCELLED_PLAN_NOTIFICATION_MESSAGE,
  PAID_PLAN_CANCELLED_MESSAGE,
  PAID_PLAN_CANCELLED_MESSAGE_IN_PAST_DUE,
} from 'src/constants/lemonsqueezy';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import GO_BACK_ARROW_ICON from '../../assets/back-arrow.webp';
import {
  customEventTracking,
  CustomEventTrackingPayload,
} from 'src/services/customEventTracking';

const PricingPlans = observer(() => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [userSelectedPlan, setUserSelectedPlan] = useState<
    keyof typeof PLAN_TYPES | null
  >(null);
  const [pricingDetails, setPricingDetails] = useState<
    PricingPlanApiResponse[]
  >([]);
  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] =
    useState<LemonsqueezySubscriptionStatus | null>(null);
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { notify } = useAlertStore();
  const { organizationDetails, fetchOrganization } = useOrganizationStore();
  const navigate = useNavigate();
  const {
    dialogueState,
    dialogType,
    updateDialogueState,
    updateDialogMetaData,
  } = useDialogStore();

  const handleSelectPlan = async (planDisplayName: keyof typeof PLAN_TYPES) => {
    if (planDisplayName !== PLAN_TYPES.FREE) {
      setUserSelectedPlan(null);

      if (userDetails) {
        const body: CustomEventTrackingPayload = {
          userId: userDetails?._id,
          orgId: userDetails?.organizationId,
          eventName: `${planDisplayName.toLowerCase()}_plan_attempt`,
        };

        customEventTracking(body);
      }

      navigate(`${PATHS.payment}?plan=${planDisplayName}`);

      return;
    }

    setUserSelectedPlan(planDisplayName);
    openDialogForCancellingPaidPlan();
  };

  const shouldFetchSubscriptionStatus = () => {
    if (!userDetails || !organizationDetails) return false;

    const allowedRoles: Array<keyof typeof ROLES> = [
      ROLES.ADMIN,
      ROLES.SUPER_ADMIN,
      ROLES.INDIVIDUAL_USER_ADMIN,
    ];
    const eligiblePlans: Array<keyof typeof PLAN_TYPES> = [
      PLAN_TYPES.PRO,
      PLAN_TYPES.BASIC,
    ];

    return (
      allowedRoles.includes(userDetails.role) &&
      eligiblePlans.includes(organizationDetails?.planId?.displayName)
    );
  };

  const fetchSubscriptionStatus = async () => {
    if (!userDetails || !organizationDetails) return;

    if (shouldFetchSubscriptionStatus()) {
      const {
        success: getSubscriptionStatusSuccess,
        content: subscriptionStatus,
      } = await getSubscriptionStatusByOrgIdInLemonsqueezy(
        organizationDetails._id,
      );

      if (getSubscriptionStatusSuccess && subscriptionStatus) {
        setCurrentSubscriptionStatus(subscriptionStatus);

        return subscriptionStatus;
      }
    }
  };

  const fetchAllPlanDetailsAndSubscriptionStatus = async () => {
    if (!organizationDetails || !userDetails) return;

    setIsPageLoading(true);

    await fetchSubscriptionStatus();

    const { success: getPricingPlansSuccess, content: pricingPlansDetails } =
      await getPricingPlansDetails();

    if (!getPricingPlansSuccess || !pricingPlansDetails) {
      notify(UNHANDLED_ERROR);
      setIsPageLoading(false);

      return;
    }

    setPricingDetails(pricingPlansDetails);
    setIsPageLoading(false);
  };

  const openDialogForCancellingPaidPlan = () => {
    if (currentSubscriptionStatus === 'cancelled') {
      notify(CANCELLED_PLAN_NOTIFICATION_MESSAGE);

      return;
    }

    updateDialogMetaData(
      DIALOG_TITLE_FOR_CANCEL_PLAN,
      currentSubscriptionStatus === 'past_due'
        ? DIALOG_CONTENT_FOR_CANCEL_PLAN_IF_PAST_DUE
        : DIALOG_CONTENT_FOR_CANCEL_PLAN,
      DialogFor.CANCEL_PAID_PLAN,
      [],
    );

    updateDialogueState('OPEN');
  };

  const confirmCancelPaidPlan = async () => {
    if (!userDetails || !userSelectedPlan) {
      notify(UNHANDLED_ERROR);

      return;
    }

    updateDialogueState('LOADING');

    const {
      success: cancelSubscriptionSuccess,
      content: cancelledSubscriptionResponse,
    } = await cancelSubscriptionByOrgIdInLemonsqueezy(
      userDetails.organizationId.toString(),
    );

    if (!cancelSubscriptionSuccess || !cancelledSubscriptionResponse) {
      notify(UNHANDLED_ERROR);
      updateDialogueState('CLOSED');

      return;
    }

    let isSubscriptionStatusFetchRequired = true;

    while (isSubscriptionStatusFetchRequired) {
      const subscriptionStatus = await fetchSubscriptionStatus();

      if (!subscriptionStatus) {
        notify(UNHANDLED_ERROR);
        updateDialogueState('CLOSED');
        isSubscriptionStatusFetchRequired = false;
      }

      if (subscriptionStatus === 'expired') {
        notify(PAID_PLAN_CANCELLED_MESSAGE_IN_PAST_DUE);
        updateDialogueState('CLOSED');
        isSubscriptionStatusFetchRequired = false;
      } else if (subscriptionStatus === 'cancelled') {
        notify(PAID_PLAN_CANCELLED_MESSAGE);
        updateDialogueState('CLOSED');
        isSubscriptionStatusFetchRequired = false;
      }

      if (!isSubscriptionStatusFetchRequired) {
        const fetchedOrganizationDetails = await fetchOrganization(
          userDetails.organizationId.toString(),
        );

        if (!fetchedOrganizationDetails) {
          notify(UNHANDLED_ERROR);
        }

        break;
      }
    }
  };

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.CANCEL_PAID_PLAN:
        confirmCancelPaidPlan();
        break;
      default:
    }
  }, [dialogueState]);

  useEffect(() => {
    fetchAllPlanDetailsAndSubscriptionStatus();
  }, []);

  if (isPageLoading) {
    return <CustomLoading />;
  }

  if (!userDetails) {
    return <ErrorBoundary message={USER_DETAILS_NOT_FOUND_ERROR} />;
  }

  if (!organizationDetails) {
    return <ErrorBoundary message={ORGANIZATION_ID_NOT_FOUND_ERROR} />;
  }

  return (
    <Box>
      <ModuleHeader
        title="All Plans"
        ModuleHeaderIcon={
          <img
            style={{ width: '32px', height: '32px' }}
            src={GO_BACK_ARROW_ICON}
          />
        }
        onModuleHeaderIconClick={() => {
          navigate(`${PATHS.settings}?tab=billing`);
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2}>
          {pricingDetails?.map((pricingDetail, index) => {
            const monthlyPriceDetails = pricingDetail.prices.find(
              ({ interval }) => interval === LEMONSQUEEZY_PLAN_INTERVALS.month,
            );

            return (
              <Grid item xs={12} sm={4} key={index}>
                <PricingCard
                  planType={pricingDetail.planType}
                  price={
                    monthlyPriceDetails?.price
                      ? `${PRICING_CARD_CURRENCY_SYMBOL} ${monthlyPriceDetails.price}`
                      : PRICING_CARD_FREE_PLAN_LABEL
                  }
                  features={pricingDetail.features}
                  onSelect={() => handleSelectPlan(pricingDetail.planType)}
                  disableButton={
                    organizationDetails.planId.displayName ===
                      pricingDetail.planType &&
                    pricingDetail.planType === PLAN_TYPES.FREE
                  }
                  pricingInterval={
                    pricingDetail.planType !== PLAN_TYPES.FREE ? (
                      pricingDetail.pricingInterval
                    ) : (
                      <>&nbsp;</>
                    )
                  }
                  buttonTitle={PRICING_CARD_BUTTON_TITLE}
                  isCurrentPlan={
                    organizationDetails?.planId.displayName ===
                    pricingDetail.planType
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
});

export default PricingPlans;
