export enum SLASH_COMMANDS {
  browse = 1,
  feedback = 2,
  // kudos = 3, // Will be released in v2
  anonymousMessage = 4,
  generateImage = 5,
  lookback = 6,
  memory = 7,
  tasks = 8,
  calendar = 9,
  imageIntelligence = 10,
  // about = 11, // Will be released in v2
  reportGroups = 12,
  deferMessage = 13,
  remind = 14,
  weather = 15,
  docs = 16,
  ignoreChatHistory = 17,
  pdf = 18,
  polls = 19,
  meet = 20,
}
