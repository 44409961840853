import { observer } from 'mobx-react-lite';
import { useState, useEffect, ReactNode } from 'react';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import UpgradePopup from '../upgrade-popup/UpgradePopup';
import CustomLoading from '../custom-loading';

interface CheckUpgradeRequiredProps {
  children: ReactNode;
  featureId: number;
}

const CheckUpgradeRequired: React.FC<CheckUpgradeRequiredProps> = observer(
  ({ children, featureId }) => {
    const { organizationDetails } = useOrganizationStore();
    const [loading, setLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      if (!organizationDetails) return;

      const {
        planId: { allowedActionIds },
      } = organizationDetails;

      if (allowedActionIds.includes(featureId)) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
        setShowModal(true);
      }

      setLoading(false);
    }, [organizationDetails, featureId]);

    if (loading) {
      return <CustomLoading />;
    }

    return (
      <>
        {hasAccess ? (
          children
        ) : (
          <UpgradePopup isOpen={showModal} setIsOpen={setShowModal} />
        )}
      </>
    );
  },
);

export default CheckUpgradeRequired;
