export const getCurrentVersion = () => {
  let version = process.env.npm_package_version;

  if (!version) {
    // eslint-disable-next-line
    version = require('../../package.json').version;
  }

  return version ?? null;
};
