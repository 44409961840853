import { UNHANDLED_ERROR } from 'src/constants/errors';
import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { axiosInstance } from '../constants/axiosInstance';
import { failureResponse } from 'src/utils/responseUtils';
import { ApiResponse } from 'src/types/commonResponseTypes';
import { PricingPlanApiResponse } from 'src/types/plan';

export const getPricingPlansDetails = async (
  planName?: string,
): Promise<ApiResponse<PricingPlanApiResponse[]>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getPricingPlansDetails'](planName);
    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};
