import { API_ROUTES_FOR_AXIOS } from '../constants/ApiRoutes';
import { handleError } from '../utils/commonErrorUtils';
import { axiosInstance } from '../constants/axiosInstance';
import { ApiResponse } from '../types/commonResponseTypes';
import { LogoutApiResponse } from '../types/oAuth';

export const getSessionDetails = async (): Promise<ApiResponse<boolean>> => {
  try {
    const { data } = await axiosInstance.get(
      API_ROUTES_FOR_AXIOS['getSessionDetails'](),
    );

    return data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const userLogout = async (): Promise<ApiResponse<LogoutApiResponse>> => {
  try {
    const { data } = await axiosInstance.post(API_ROUTES_FOR_AXIOS['logout']());

    return data;
  } catch (error: any) {
    return handleError(error);
  }
};
