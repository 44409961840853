/**
 * Enum representing different user roles in the system.
 * - `USER`: Regular user with basic access rights.
 * - `ADMIN`: Administrator with additional privileges, including access to "memory APIs" for CRUD operations.
 * - `SUPER_ADMIN`: Super administrator, typically the development team, with comprehensive access to all functionalities. This role also includes specific routes, such as creating plans and getting all usages, accessible only by super admins.
 */
export enum ROLES {
  USER = 'USER',
  ADMIN = 'ADMIN',
  INDIVIDUAL_USER_ADMIN = 'INDIVIDUAL_USER_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}
