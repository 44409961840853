export enum AUTH_FOR {
  FRONT_END_PROD = 'FRONT_END_PROD',
  FRONT_END_DEV = 'FRONT_END_DEV',
}

// Oauth api response
export interface LogoutApiResponse {
  success: boolean;
  content: string;
}
