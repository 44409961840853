import { makeAutoObservable } from 'mobx';
import createStoreContext from '../createStoreContext';
import { UserApiResponse } from '../../types/user';
import { getUserInfoByCookie } from 'src/services/user';

export const [AuthStoreProvider, useAuthStore] = createStoreContext(
  class AuthStore {
    private userDocument: UserApiResponse | null = null;

    public constructor() {
      makeAutoObservable(this);
    }

    logout = () => {
      this.userDocument = null;
    };

    login = (userDetails: UserApiResponse) => {
      this.userDocument = userDetails;
    };

    getUserDetails = () => {
      return this.userDocument;
    };

    fetchUserDetails = async (): Promise<UserApiResponse | null> => {
      // This method updates the current user details without requiring the user to refresh the page.
      // It is used to promote the user to admin after DNS verification.
      const { success: getUserInfoSuccess, content: userInfo } =
        await getUserInfoByCookie();

      if (getUserInfoSuccess && userInfo) {
        this.userDocument = userInfo;

        return userInfo;
      } else {
        return null;
      }
    };
  },
);

export type GlobalStore = ReturnType<typeof useAuthStore>;
