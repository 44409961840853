import React from 'react';
import { useField, ErrorMessage } from 'formik';
import { FormControlLabel, Link, Typography } from '@mui/material';
import { Checkbox } from '@mui/material';
import styleVariables from '../../scss/_variables.scss';

export interface CustomCheckBoxWithTextProps {
  name: string;
  text: string;
  labelPlacement: 'end' | 'start' | 'top' | 'bottom';
  href?: string;
  targetPage?: string;
  hrefText?: string;
}

export const CustomCheckBoxWithText: React.FC<CustomCheckBoxWithTextProps> = ({
  name,
  labelPlacement,
  text,
  href,
  targetPage,
  hrefText,
}) => {
  const [field] = useField(name);

  return (
    <>
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <Checkbox
            {...field}
            checked={field.value}
            sx={{
              '& .MuiSvgIcon-root': {
                color: styleVariables.hanabiThemeColorDarkRed,
              },
            }}
          />
        }
        label={
          <>
            {text}
            {href && hrefText && (
              <Link href={href} target={targetPage}>
                {hrefText}
              </Link>
            )}
          </>
        }
        labelPlacement={labelPlacement}
      />

      <ErrorMessage
        name={name}
        render={(errorMessage) => (
          <Typography
            color="error"
            variant="body2"
            sx={{
              fontSize: '0.75rem',
              margin: '3px 14px 0 14px',
            }}
          >
            {errorMessage}
          </Typography>
        )}
      />
    </>
  );
};
