import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/constants/navigateRoutes';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import { getSessionDetails } from 'src/services/auth';
import { getUserInfoByCookie } from 'src/services/user';
import { Box } from '@mui/material';
import CustomLoading from '../custom-loading';
import styleVariables from '../../scss/_variables.scss';
import { useOrganizationStore } from 'src/stores/OrganizationStore/OrganizationStore';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { checkLatestPoliciesApprovedByMongoUserId } from 'src/services/policy';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(
  ({ children }) => {
    const { login, logout } = useAuthStore();
    const navigate = useNavigate();
    const { fetchOrganization } = useOrganizationStore();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const { notify } = useAlertStore();

    const fetchUserData = useCallback(async () => {
      try {
        const { success: getSessionInfoSuccess, content: sessionInfo } =
          await getSessionDetails();

        if (!getSessionInfoSuccess || !sessionInfo) {
          logout();
          navigate(PATHS.unAuthorized);

          return;
        }

        const { success: getUserInfoSuccess, content: userInfo } =
          await getUserInfoByCookie();

        if (!getUserInfoSuccess || !userInfo) {
          logout();
          navigate(PATHS.unAuthorized);

          return;
        }

        login(userInfo);
        await fetchOrganization(userInfo.organizationId.toString());
        const {
          success: getLatestPoliciesNotApprovedSuccess,
          content: latestPoliciesNotApprovedByUser,
        } = await checkLatestPoliciesApprovedByMongoUserId(
          userInfo._id.toString(),
        );

        if (
          !getLatestPoliciesNotApprovedSuccess ||
          !latestPoliciesNotApprovedByUser
        ) {
          notify(UNHANDLED_ERROR);

          return;
        }

        if (!latestPoliciesNotApprovedByUser.isLatestPoliciesApprovedByUser) {
          navigate(PATHS.policyApproval);

          return;
        }
      } catch (error) {
        notify(UNHANDLED_ERROR);
      } finally {
        setIsPageLoading(false);
      }
    }, []);

    useEffect(() => {
      fetchUserData();
    }, []);

    if (isPageLoading) {
      return (
        <Box
          sx={{
            height: '100vh',
            overflow: 'hidden',
            background: styleVariables.midnightIndigo,
          }}
        >
          <CustomLoading />
        </Box>
      );
    }

    return <React.Fragment>{!isPageLoading && children}</React.Fragment>;
  },
);

export default ProtectedRoute;
