import { API_ROUTES_FOR_AXIOS } from 'src/constants/ApiRoutes';
import { axiosInstance } from 'src/constants/axiosInstance';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { ApiResponse, PaginatedResponse } from 'src/types/commonResponseTypes';
import { failureResponse } from 'src/utils/responseUtils';
import {
  GetScheduledDmAnnouncementApiResponse,
  GetScheduledMailApiResponse,
  ScheduleDmAnnouncementToAllUsersJobPayload,
  ScheduleMailToAllUsersJobPayload,
} from 'src/types/crm';

export const getScheduledMailsBySuperAdminOrgId = async (
  orgId: string,
  page: number,
  limit: number,
): Promise<ApiResponse<PaginatedResponse<GetScheduledMailApiResponse[]>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getScheduledMailsBySuperAdminOrgId'](
      orgId,
      page,
      limit,
    );

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const scheduleAMail = async (
  body: ScheduleMailToAllUsersJobPayload,
): Promise<ApiResponse<string>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['scheduleAMail']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const INVALID_SENDGRID_TEMPLATE_ID = 'INVALID_SENDGRID_TEMPLATE_ID';

export const NO_NEW_USERS_FOUND = 'NO_NEW_USERS_FOUND';

export const EMAIL_SCHEDULE_CANNOT_BE_IN_THE_PAST =
  'EMAIL_SCHEDULE_CANNOT_BE_IN_THE_PAST';

export const scheduleDmAnnouncement = async (
  body: ScheduleDmAnnouncementToAllUsersJobPayload,
): Promise<ApiResponse<string>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['scheduleDmAnnouncement']();
    const { data } = await axiosInstance.post(apiUrl, body);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const getScheduledDmAnnouncementsBySuperAdminOrgId = async (
  orgId: string,
  page: number,
  limit: number,
): Promise<
  ApiResponse<PaginatedResponse<GetScheduledDmAnnouncementApiResponse[]>>
> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS[
      'getScheduledDmAnnouncementsBySuperAdminOrgId'
    ](orgId, page, limit);

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const deleteDmAnnouncementByJobId = async (
  jobId: string,
): Promise<ApiResponse<PaginatedResponse<string>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['deleteDmAnnouncementByJobId'](jobId);

    const { data } = await axiosInstance.delete(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const abortDmAnnouncementByJobId = async (
  jobId: string,
): Promise<ApiResponse<PaginatedResponse<string>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['abortDmAnnouncementByJobId'](jobId);

    const { data } = await axiosInstance.post(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};
