import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import React from 'react';

export interface CustomLoadingProps extends CircularProgressProps {}

const CustomLoading: React.FC<CustomLoadingProps> = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        background: 'transparent',
      }}
    >
      <CircularProgress {...props} />
    </Box>
  );
};

export default CustomLoading;
