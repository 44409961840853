import { createTheme, darken } from '@mui/material/styles';
import styleVariables from '../scss/_variables.scss';

import type {} from '@mui/x-data-grid/themeAugmentation';

// Create the theme
const theme = createTheme({
  palette: {
    primary: {
      main: styleVariables.hanabiThemeColorDarkRed,
      dark: styleVariables.hanabiThemeColorDarkRed,
      contrastText: '#fff',
    },
    background: {
      default: styleVariables.darkNavyBlue,
      paper: darken(styleVariables.darkNavyBlue, 0.05),
    },
    text: {
      primary: styleVariables.colorWhite,
      secondary: styleVariables.slateGray,
      disabled: styleVariables.mutedGray,
    },
    // Add additional color settings as needed
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          cursor: 'pointer',
          color: styleVariables.colorWhite,
          backgroundColor: `${styleVariables.hanabiThemeColorDarkRed} !important`,
          '&:hover': {
            backgroundImage: styleVariables.hanabiGradientHover,
          },
          '&:active': {
            backgroundImage: styleVariables.hanabiGradientActive,
          },
        },
        containedSecondary: {
          cursor: 'pointer',
          color: styleVariables.colorWhite,
          backgroundColor: `${styleVariables.slateGray} !important`,
          '&:hover': {
            backgroundImage: styleVariables.secondaryHanabiGradientHover,
          },
          '&:active': {
            backgroundImage: styleVariables.hanabiGradientActive,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: styleVariables.mutedGray,
          },
          '&:active': {
            color: styleVariables.slateGray,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: styleVariables.colorWhite,
          backgroundImage: styleVariables.hanabiGradient,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: styleVariables.hanabiThemeColorBrightOrangeRed,
          '&.Mui-checked': {
            color: styleVariables.hanabiThemeColorBrightOrangeRed,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: 'inherit',
          '&.Mui-active': {
            color: styleVariables.hanabiThemeColorBrightOrangeRed,
          },
          '&.Mui-completed': {
            color: styleVariables.hanabiThemeColorDarkRed,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: styleVariables.mutedGray,
          },
          '& .MuiInput-underline:after': {
            borderColor: styleVariables.mutedGray,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: styleVariables.mutedGray,
            },
            '&:hover fieldset': {
              borderColor: styleVariables.hanabiThemeColorBrightOrangeRed,
            },
            '&.Mui-focused fieldset': {
              borderColor: styleVariables.hanabiThemeColorBrightOrangeRed,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: styleVariables.colorWhite,
          '.MuiSvgIcon-root': {
            color: styleVariables.mutedGray,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: styleVariables.hanabiThemeColorBrightOrangeRed,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: styleVariables.mutedGray,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid ${styleVariables.slateGray}`,
            display: 'flex',
            alignItems: 'center',
            minHeight: '100%',
            '&:focus, &:focus-within': {
              outline: 'none',
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            fontSize: '20px',
            borderBottom: `1px solid ${styleVariables.slateGray}`,
            '&:focus, &:focus-within': {
              outline: 'none',
            },
          },
          '& .MuiDataGrid-columnSeparator, & .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: `1px solid ${styleVariables.slateGray}`,
          },
          border: 'none',
        },
      },
    },
    // Add other component overrides as needed
  },
});

export default theme;
