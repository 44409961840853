import { API_ROUTES_FOR_AXIOS } from 'src/constants/ApiRoutes';
import { axiosInstance } from 'src/constants/axiosInstance';
import { UNHANDLED_ERROR } from 'src/constants/errors';
import { ApiResponse, PaginatedResponse } from 'src/types/commonResponseTypes';
import { SpacesResponse } from 'src/types/spacesTypes';
import { failureResponse } from 'src/utils/responseUtils';

export const fetchOrganizationSpaceData = async (
  orgId: string,
  page: number,
  limit: number,
): Promise<ApiResponse<PaginatedResponse<SpacesResponse[]>>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['getOrganizationSpaceData'](
      orgId,
      page,
      limit,
    );

    const { data } = await axiosInstance.get(apiUrl);

    return data;
  } catch (error) {
    return failureResponse(UNHANDLED_ERROR);
  }
};

export const updateSpaceHanaAccessById = async (
  spaceId: string,
  isActive: boolean,
): Promise<ApiResponse<SpacesResponse>> => {
  try {
    const apiUrl = API_ROUTES_FOR_AXIOS['updateSpaceHanaAccessById'](spaceId);

    const { data } = await axiosInstance.patch(apiUrl, { isActive });

    return data;
  } catch (error: any) {
    return failureResponse(UNHANDLED_ERROR, error?.response?.data?.statusCode);
  }
};
