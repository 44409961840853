import { Box, Button, Typography } from '@mui/material';
import { ModuleHeader } from 'src/components/module-header/ModuleHeader';
import { useAuthStore } from 'src/stores/AuthStore/AuthStore';
import {
  DUPLICATION_OF_SCHEDULING_MAIL,
  INVALID_SENDGRID_TEMPLATE_ID_ERROR,
  NO_NEW_USERS_FOUND_FOR_SCHEDULING_MAIL,
  NOT_AUTHORIZED_MESSAGE,
  SCHEDULING_MAIL_IN_PAST,
  UNHANDLED_ERROR,
} from 'src/constants/errors';
import { observer } from 'mobx-react-lite';
import { ROLES } from 'src/constants/Role';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundry';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DialogFor, useDialogStore } from 'src/stores/DialogStore/DialogStore';
import { FormField } from 'src/components/custom-dialog/CustomDialog';
import {
  DIALOG_CONTENT_FOR_SCHEDULE_MAIL,
  DIALOG_TITLE_FOR_SCHEDULE_MAIL,
  SCHEDULE_MAIL_BUTTON_TEXT,
  SCHEDULE_MAIL_SUCCESS,
  SMALL_DIALOG_TEXTFIELD_ROWS,
} from 'src/constants/dialogMetaInfo';
import {
  DATA_GRID_COLUMN_WIDTH,
  STYLE_FOR_LARGE_DIALOG,
} from 'src/constants/Layout';
import { useCallback, useEffect, useState } from 'react';
import {
  PAGE_SIZE_OPTIONS,
  WORDS_PER_ROW_CELL,
  truncateString,
} from 'src/constants/utils';
import TablePaginationActions from 'src/constants/TablePaginationActions';
import { useAlertStore } from 'src/stores/AlertStore/AlertStore';
import {
  EMAIL_SCHEDULE_CANNOT_BE_IN_THE_PAST,
  INVALID_SENDGRID_TEMPLATE_ID,
  NO_NEW_USERS_FOUND,
  getScheduledMailsBySuperAdminOrgId,
  scheduleAMail,
} from 'src/services/crm';
import {
  EMAIL_CAMPAIGN_NAMES,
  GetScheduledMailApiResponse,
  ScheduleMailToAllUsersJobPayload,
} from 'src/types/crm';
import { DUPLICATION_API_RESPONSE_ERROR_MESSAGE } from 'src/utils/commonErrorUtils';
import { formatDateTime } from 'src/utils/dateUtils';
import UserDeleteRequest from './UserDeleteRequest';
import DmAnnouncement from './DmAnnouncement';

const SCHEDULE_MAIL_INITIAL_FIELDS: FormField[] = [
  {
    label: 'Enter Date and Time',
    id: 'emailScheduleIsoDate',
    value: '',
    type: 'date-time',
    fieldHeading: 'Date/Time',
  },
  {
    label: 'sendGridTemplateId',
    id: 'sendGridTemplateId',
    value: '',
    type: 'text',
    fieldHeading: 'send Grid Template Id',
  },
];

const Crm = observer(() => {
  const { getUserDetails } = useAuthStore();
  const userDetails = getUserDetails();
  const { notify } = useAlertStore();
  const [scheduledMails, setScheduledMails] = useState<
    GetScheduledMailApiResponse[]
  >([]);
  const [loadingForDataGrid, setLoadingForDataGrid] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });
  const [rowCountState, setRowCountState] = useState(0);

  const {
    dialogueState,
    dialogType,
    updateDialogueState,
    dialogFormFields,
    updateDialogMetaData,
  } = useDialogStore();

  const openDialogForScheduleMail = () => {
    updateDialogMetaData(
      DIALOG_TITLE_FOR_SCHEDULE_MAIL,
      DIALOG_CONTENT_FOR_SCHEDULE_MAIL,
      DialogFor.SCHEDULE_MAIL,
      SCHEDULE_MAIL_INITIAL_FIELDS,
      SMALL_DIALOG_TEXTFIELD_ROWS,
      STYLE_FOR_LARGE_DIALOG,
    );

    updateDialogueState('OPEN');
  };

  const columns: GridColDef<GetScheduledMailApiResponse>[] = [
    {
      field: 'campaignName',
      headerName: 'Campaign name',
      width: DATA_GRID_COLUMN_WIDTH,
      renderCell: ({ row }) => {
        return (
          <Typography
            component="p"
            variant="body1"
            sx={{ maxWidth: DATA_GRID_COLUMN_WIDTH, margin: '16px 0' }}
          >
            {truncateString(row.data.campaignName, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
    {
      field: 'emailScheduledDate',
      headerName: 'Email scheduled date',
      width: DATA_GRID_COLUMN_WIDTH,
      renderCell: ({ row }) => {
        return (
          <Typography component="p" variant="body1">
            {formatDateTime(row.data.emailScheduleIsoDate)}
          </Typography>
        );
      },
    },
    {
      field: 'sendGridTemplateId',
      headerName: 'SendGrid template id',
      width: DATA_GRID_COLUMN_WIDTH,
      renderCell: ({ row }) => {
        return (
          <Typography
            component="p"
            variant="body1"
            sx={{ maxWidth: DATA_GRID_COLUMN_WIDTH, margin: '16px 0' }}
          >
            {truncateString(row.data.sendGridTemplateId, WORDS_PER_ROW_CELL)}
          </Typography>
        );
      },
    },
  ];

  const fetchScheduledMails = useCallback(async () => {
    if (!userDetails || userDetails.role !== ROLES.SUPER_ADMIN) return;
    const { organizationId } = userDetails;

    setLoadingForDataGrid(true);

    try {
      const { success: getScheduledMailsSuccess, content: scheduledMails } =
        await getScheduledMailsBySuperAdminOrgId(
          organizationId.toString(),
          paginationModel.page,
          paginationModel.pageSize,
        );

      if (!getScheduledMailsSuccess || !scheduledMails) {
        notify(UNHANDLED_ERROR);

        setLoadingForDataGrid(false);

        setScheduledMails([]);

        return;
      }

      const updatedContent = scheduledMails.data.map((scheduledMail) => {
        const updatedScheduledMail = {
          ...scheduledMail,
          id: scheduledMail._id,
        };

        return updatedScheduledMail;
      });

      setScheduledMails(updatedContent);
      setRowCountState(scheduledMails.totalCount);
    } catch (error) {
      notify(UNHANDLED_ERROR);
    } finally {
      setLoadingForDataGrid(false);
    }
  }, [paginationModel.page, paginationModel.pageSize, notify, userDetails]);

  useEffect(() => {
    fetchScheduledMails();
  }, [fetchScheduledMails]);

  useEffect(() => {
    if (dialogueState !== 'ACCEPT') {
      return;
    }

    switch (dialogType) {
      case DialogFor.SCHEDULE_MAIL:
        handleScheduleMail();
        break;
      default:
    }
  }, [dialogueState]);

  const getScheduledErrorMessage = (
    scheduleAMailErrorMessage: string | undefined,
  ) => {
    switch (true) {
      case scheduleAMailErrorMessage === INVALID_SENDGRID_TEMPLATE_ID:
        return INVALID_SENDGRID_TEMPLATE_ID_ERROR;
      case scheduleAMailErrorMessage === NO_NEW_USERS_FOUND:
        return NO_NEW_USERS_FOUND_FOR_SCHEDULING_MAIL;
      case scheduleAMailErrorMessage?.includes(
        DUPLICATION_API_RESPONSE_ERROR_MESSAGE,
      ):
        return DUPLICATION_OF_SCHEDULING_MAIL;
      case scheduleAMailErrorMessage === EMAIL_SCHEDULE_CANNOT_BE_IN_THE_PAST:
        return SCHEDULING_MAIL_IN_PAST;
      default:
        return UNHANDLED_ERROR;
    }
  };

  const handleScheduleMail = async () => {
    if (!userDetails) return;

    updateDialogueState('LOADING');

    const { organizationId } = userDetails;

    const body: ScheduleMailToAllUsersJobPayload = {
      campaignName: EMAIL_CAMPAIGN_NAMES['ANNOUNCEMENT_TO_ALL_USERS'],
      emailScheduleIsoDate: '',
      sendGridTemplateId: '',
      superAdminOrgId: organizationId,
    };

    for (const field of dialogFormFields) {
      if (field.id === 'sendGridTemplateId') {
        body.sendGridTemplateId = field.value;
      }

      if (field.id === 'emailScheduleIsoDate') {
        body.emailScheduleIsoDate = field.value;
      }
    }

    // schedule Mail
    const {
      success: scheduleAMailSuccess,
      content: scheduledMailResponse,
      message: scheduleAMailErrorMessage,
    } = await scheduleAMail(body);

    if (!scheduleAMailSuccess || !scheduledMailResponse) {
      notify(getScheduledErrorMessage(scheduleAMailErrorMessage));
      updateDialogueState('OPEN');

      return;
    }

    notify(SCHEDULE_MAIL_SUCCESS);

    updateDialogueState('CLOSED');

    fetchScheduledMails();
  };

  if (!userDetails) {
    return <ErrorBoundary message={UNHANDLED_ERROR} />;
  }

  if (userDetails.role !== ROLES.SUPER_ADMIN) {
    return (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="p" variant="h6">
          {NOT_AUTHORIZED_MESSAGE}
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="main">
      <ModuleHeader title="Customer Relationship Management" />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginBottom: '16px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialogForScheduleMail}
          id="schedule-mail"
        >
          {SCHEDULE_MAIL_BUTTON_TEXT}
        </Button>
      </Box>
      <Box>
        <DataGrid
          getRowHeight={() => 'auto'}
          autoHeight
          paginationMode="server"
          rows={scheduledMails}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          disableColumnMenu
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          loading={loadingForDataGrid}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                page: paginationModel.page - 1,
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          rowCount={rowCountState}
          slotProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
          onPaginationModelChange={(newPaginationModel) => {
            setPaginationModel((oldPaginationModel) => ({
              ...oldPaginationModel,
              page: newPaginationModel.page + 1,
              pageSize: newPaginationModel.pageSize,
            }));
          }}
        />
      </Box>
      <UserDeleteRequest />
      <DmAnnouncement />
    </Box>
  );
});

export default Crm;
