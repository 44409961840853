import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { UsageCardProps } from 'src/types/overview';
import styleVariables from '../../scss/_variables.scss';

const UsageCard = ({ count, title, subtitle }: UsageCardProps) => {
  return (
    <Card
      sx={{
        width: '100%',
        minHeight: '100%',
        background: styleVariables.midnightIndigo,
        color: styleVariables.colorWhite,
        textAlign: 'center',
        borderRadius: 4,
      }}
    >
      <CardContent>
        <Typography variant="h2" component="p" sx={{ wordBreak: 'break-word' }}>
          {count}
        </Typography>
        <Typography sx={{ marginTop: '16px' }} variant="body1" component="p">
          {title.toUpperCase()}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ marginTop: '16px' }}
          variant="body2"
          component="p"
        >
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UsageCard;
